import { ModalWrapper } from './Modal';
import { Box, Button, CircularProgress } from '@mui/material';
import alerta from '../assets/images/alerta.png';

const ModalConfirm = ({
  show,
  handleClose,
  title,
  children,
  submit,
  loading,
}) => {
  return (
    <ModalWrapper
      showModal={show}
      closeModalAction={handleClose}
      title={title}
      img={alerta}
    >
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Box>{children}</Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button variant="contained" onClick={submit}>
          {loading ? (
            <CircularProgress color={'inherit'} size={25} thickness={5} />
          ) : (
            'Confirmar'
          )}
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
      </Box>
    </ModalWrapper>
  );
};

export default ModalConfirm;
