import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';
import { IState, ReportBasicFilterRequest } from './interfaces/ListExpenses';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  expenseReports: [],
  kmReports: [],
};

export const slice = createSlice({
  name: 'listExpenses',
  initialState,
  reducers: {
    LIST_EXPENSES_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    LIST_EXPENSES_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
      console.log('RREPORT_FAILED');
      console.log(action);
    },
    LIST_EXPENSES_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.expenseReports = action.payload.result;
    },
    LIST_EXPENSES_KM_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.kmReports = action.payload.result;
    },
  },
});

export const {
  LIST_EXPENSES_REQUESTED,
  LIST_EXPENSES_FAILED,
  LIST_EXPENSES_SUCCESS,
  LIST_EXPENSES_KM_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetListExpenses = (
  km: boolean,
  request: ReportBasicFilterRequest
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Report.GetListExpenses(
        km,
        request.CompanyID,
        request.Companies,
        request.CostCenterID,
        request.StartDate,
        request.EndDate
      ),
    },
    onAction: {
      onStart: LIST_EXPENSES_REQUESTED.type,
      onError: LIST_EXPENSES_FAILED.type,
      onSuccess: km
        ? LIST_EXPENSES_KM_SUCCESS.type
        : LIST_EXPENSES_SUCCESS.type,
    },
  });
};
