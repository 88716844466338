import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/VolumeDashboard';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
    loading: false,
    error: false,
    success: false,
    volumes: [],
};

export const slice = createSlice({
    name: 'dashboardVolume',
    initialState,
    reducers: {
        DASHBOARDVOLUME_REQUESTED: (state) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        DASHBOARDVOLUME_FAILED: (state, action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            state.volumes = [];
        },
        DASHBOARDVOLUME_SUCCESS: (state, action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.volumes = action.payload.result;
        },
    }
});

export const {
    DASHBOARDVOLUME_REQUESTED,
    DASHBOARDVOLUME_FAILED,
    DASHBOARDVOLUME_SUCCESS
} = slice.actions;

export default slice.reducer;

export const GetVolumeDashboard = (companyID: number, startDate: string, endDate: string, DentroOuFora: string, ProviderID: number) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.Volume.GetVolumeDashboard(companyID, startDate, endDate, DentroOuFora, ProviderID),
        },
        onAction: {
            onStart: DASHBOARDVOLUME_REQUESTED.type,
            onSuccess: DASHBOARDVOLUME_SUCCESS.type,
            onError: DASHBOARDVOLUME_FAILED.type,
        }
    });
};
