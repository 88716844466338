import { useState, useEffect } from 'react';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import Header from '../../../components/Header';
import HeaderSelect from '../../../components/HeaderSelect';
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  Button,
  InputLabel,
  TextField,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info'; // Ícone para Justificativa
import ApprovalIcon from '@mui/icons-material/Done'; // Ícone para Acompanhar Aprovação
import { useSelector, useDispatch } from 'react-redux';
import DatePickerRange from '../../../components/DatePickerRange';
import validateDate from '../../../utils/validateDate';
import {
  CreateAdvanced,
  GetAdvancedApprovalFlow,
  GetFilterAdvanced,
} from '../../../store/_Entities/Advanced';
import { ModalWrapper } from '../../../components/Modal';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import { inputformatNumber } from '../../../utils/format';
import ApprovalStepper from '../../../components/ApprovalStepper';
import { GetCurrency } from '../../../store/_Entities/Currency';

const Advances = () => {
  const { user, advanced, currency } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());
  const [selectedAdvancedStatusID, setSelectedAdvancedStatusID] =
    useState(null);
  const [selectedAdvanced, setSelectedAdvanced] = useState(null);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [data, setData] = useState([]);
  const [advancedStatus, setAdvancedStatus] = useState([]);

  const onSubmit = (data) => {
    setShowAddModal(false);
    const transformedData = {
      ...data,
      price: parseFloat(data.price.replace(',', '.')),
      advancedStatusID: 1,
      userID: user.authData.sub,
      approve: new Date().toISOString(),
    };
    dispatch(CreateAdvanced(transformedData));
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      description: '',
      currency: '',
      price: '',
    },
  });

  useEffect(() => {
    if (validateDate(startDate) && validateDate(endDate)) {
      const transformedStartDate = startDate.toLocaleDateString('pt-BR');
      const transformedEndDate = endDate.toLocaleDateString('pt-BR');
      dispatch(
        GetFilterAdvanced(
          user.authData.sub,
          selectedAdvancedStatusID || 0,
          transformedStartDate,
          transformedEndDate
        )
      );
    }
  }, [selectedAdvancedStatusID, startDate, endDate]);

  useEffect(() => {
    if (advanced?.advanceds) {
      setData(advanced?.advanceds);
    }
  }, [advanced?.advanceds]);

  useEffect(() => {
    if (selectedAdvanced && showApprovalModal) {
      dispatch(GetAdvancedApprovalFlow(selectedAdvanced));
    }
  }, [showApprovalModal]);

  useEffect(() => {
    if (advanced.approvalFlow) {
      setAdvancedStatus(advanced.approvalFlow);
    }
  }, [advanced.approvalFlow]);

  useEffect(() => {
    if (showAddModal) {
      dispatch(GetCurrency(user.user.companyID));
    }
  }, [showAddModal]);

  const columns = [
    {
      field: 'advancedID',
      headerName: 'Código',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) =>
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: params.row.currency,
        }).format(params.row.price),
    },
    {
      field: 'currency',
      headerName: 'Moeda',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'advancedStatus',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'justification',
      headerName: 'Justificativa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (params.row.justification) {
          return (
            <Tooltip title={params.row.justification} placement="top">
              <InfoIcon />
            </Tooltip>
          );
        }
        return null;
      },
    },
    {
      field: 'aprovar',
      headerName: 'Acompanhar Aprovação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleAprovar(params.row)}>
          <ApprovalIcon />
        </IconButton>
      ),
    },
  ];

  const handleAprovar = (row) => {
    setSelectedAdvanced(row.advancedID);
    setShowApprovalModal(true);
  };

  const exportHeaders = {
    codigo: 'advancedID',
    descricao: 'description',
    valor: 'price',
    moeda: 'currency',
    status: 'advancedStatus',
    justificativa: 'justification',
    aprovar: 'Acompanhar Aprovação',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header title="Adiantamentos" onClick={() => setShowAddModal(true)}>
          <HeaderSelect
            label={'Status Relatório'}
            onChange={(e) => setSelectedAdvancedStatusID(e.target.value)}
            menuItems={[
              { value: 0, label: 'Todos' },
              { value: 3, label: 'Aprovado' },
              { value: 1, label: 'Pendente' },
              { value: 2, label: 'Aguardando aprovação' },
              { value: 5, label: 'Adiantamento confirmado' },
              { value: 4, label: 'Recusado' },
              { value: 7, label: 'Finalizado' },
            ]}
          />

          <DatePickerRange
            startDate={startDate}
            endDate={endDate}
            onChange={{ startDate: setStartDate, endDate: setEndDate }}
          />
        </Header>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.advancedID}
          exportHeaders={exportHeaders}
          loading={advanced.loading && !showApprovalModal}
        />

        <ModalWrapper
          title={'Cadastro de Adiantamento'}
          showModal={showAddModal}
          closeModalAction={() => setShowAddModal(false)}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Controller
                name="description"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <FormInput
                    field={field}
                    label="Descrição"
                    errors={errors.description}
                  />
                )}
              />
              <Controller
                name="currency"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    label={'Moeda'}
                    errors={errors.currency}
                  >
                    {currency.currencies.map((option) => (
                      <MenuItem
                        key={option.currencyID}
                        value={option.currencyCode}
                      >
                        {option.currencyCode} - {option.name}
                      </MenuItem>
                    ))}
                  </FormSelect>
                )}
              />
              <Controller
                name="price"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => {
                  const [inputValue, setInputValue] = useState(
                    field.value || ''
                  );

                  const handleChange = (e) => {
                    const formattedValue = inputformatNumber(e.target.value);

                    setInputValue(formattedValue);
                    setValue('price', formattedValue);
                  };

                  return (
                    <Box>
                      <InputLabel htmlFor="value">Valor</InputLabel>
                      <TextField
                        id="value"
                        type="text"
                        variant="outlined"
                        value={inputValue}
                        placeholder={'Digite...'}
                        fullWidth
                        error={Boolean(errors.price)}
                        onChange={handleChange}
                      />
                    </Box>
                  );
                }}
              />
              <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
                <Button variant="contained" type="submit">
                  Salvar
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setShowAddModal(false)}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          </form>
        </ModalWrapper>

        <ModalWrapper
          title={'Visualizar Fluxo de Aprovação'}
          showModal={showApprovalModal}
          closeModalAction={() => setShowApprovalModal(false)}
          maxWidth={advancedStatus.length > 3 ? 'md' : 'sm'}
        >
          {advanced.loading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Box
                width={{
                  xs: 300,
                  md: advancedStatus.length > 1 ? 'md' : 'sm',
                }}
              >
                Adiantamento:{' '}
                {
                  advanced?.advanceds?.find(
                    (a) => a.advancedID === selectedAdvanced
                  )?.description
                }
              </Box>
              <Box>Origem: Adiantamento</Box>
              <Typography variant="h6">Status da aprovação:</Typography>
              <ApprovalStepper data={advancedStatus} />
            </Box>
          )}
        </ModalWrapper>
      </Container>
    </>
  );
};

export default Advances;
