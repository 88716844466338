import { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import HeaderSearchSelect from "../../../components/HeaderSearchSelect";
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from "../../../components/Table/CustomTable";
import DatePickerRange from '../../../components/DatePickerRange';
import { GetPendingAdvancedReport } from "../../../store/_Entities/PendingAccountabilityReport";
import HeaderSearchSelectCompany from "../../../components/HeaderSearchSelectCompany";

const PendingAccountability = () => {
  const { user, company, pendingAccountabilityReport } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();

  const userID = user.authData.sub;
  
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyInputValue, setCompanyInputValue] = useState('');
  const [data, setData] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const fetchReports = () => {

    const startDateFormatted = startDate.toLocaleDateString('pt-BR');
    const endDateFormatted = endDate.toLocaleDateString('pt-BR');

    const request = {
      userID,
      companyID: selectedCompany,
      startDate: startDateFormatted,
      endDate: endDateFormatted,     
    }

    dispatch(
      GetPendingAdvancedReport(request.companyID, request.startDate, request.endDate)
    );
  };

  useEffect(() => {
    if (selectedCompany > 0 && startDate && endDate) {
      fetchReports();
    }
  }, [selectedCompany, startDate, endDate]);

  useEffect(() => {
    if (pendingAccountabilityReport?.pendingAccountabilityReports &&
        selectedCompany > 0) {
      setData(pendingAccountabilityReport.pendingAccountabilityReports);
    }
  }, [pendingAccountabilityReport.pendingAccountabilityReports, selectedCompany]);

 
  const columns = [
    { field: "reportID", headerName: "Código", headerAlign: "center", align: "center", minWidth: 150, flex: 1 },
    { field: "name", headerName: "Nome do Colaborador Solicitante", headerAlign: "center", align: "center", minWidth: 150, flex: 1 },
    { field: "currency", headerName: "Moeda", headerAlign: "center", align: "center", minWidth: 100, flex: 1 },
    { field: "value", headerName: "Valor", headerAlign: "center", align: "center", minWidth: 100, flex: 1 },
    { field: "reportStatus", headerName: "Status", headerAlign: "center", align: "center", minWidth: 100, flex: 1 },
    { field: "created", headerName: "Data da Aprovação do Adiantamento", headerAlign: "center", align: "center", minWidth: 150, flex: 1 }
  ];

  const exportHeaders = {
    reportID: 'Código',
    name: 'Nome do Colaborador Solicitante',
    currency: 'Moeda',
    value: 'Valor',
    reportStatus: 'Status',
    created: 'Data da Aprovação do Adiantamento'
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black'
            }}
          >
            <h2>Pendente de Prestação de Contas</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end'
              }}
            >
              <Box sx={{ display: 'flex', gap: 5 }}>
              <HeaderSearchSelectCompany
                  label={'Empresa'}
                  onChange={setSelectedCompany}
                  defaultValue={selectedCompany}
                />
                <DatePickerRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={{ startDate: setStartDate, endDate: setEndDate }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.reportID}
          exportHeaders={exportHeaders}
        />
      </Container>
    </>
  )
}

export default PendingAccountability;
