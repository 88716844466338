import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Container, IconButton, Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { ModalWrapper } from '../../../components/Modal';
import { useForm, Controller, set } from 'react-hook-form';
import FormInput from '../../../components/FormInput';
import ModalConfirm from '../../../components/ModalConfirm';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreateExpenseType,
  DeleteExpenseType,
  GetExpenseTypes,
  UpdateExpenseTypeIndex,
} from '../../../store/_Entities/ExpenseType';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';

const ExpenseType = () => {
  const { company, expenseType } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [showModal, setShowModal] = useState({ type: '', active: false });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [showError, setShowError] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      index: selectedRow?.index || '',
      description: selectedRow?.description || '',
    },
  });

  const onSubmit = (data) => {
    if (showModal.type === 'add') {
      const newData = {
        ...data,
        index: +data.index,
        companyID: selectedCompany,
      };
      dispatch(CreateExpenseType(newData));
    }
    if (showModal.type === 'edit') {
      dispatch(UpdateExpenseTypeIndex(+selectedRow.expenseTypeID, +data.index));
    }
    setShowModal((prev) => ({ ...prev, active: false }));
  };

  const handleDelete = () => {
    dispatch(DeleteExpenseType(selectedRow.expenseTypeID));
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (selectedCompany) {
      setShowError(false);
      dispatch(GetExpenseTypes(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setData(expenseType.expenses);
    }
  }, [expenseType.expenses]);

  const columns = [
    {
      field: 'index',
      headerName: 'Índice',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'editar',
      headerName: 'Editar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => {
            setSelectedRow(params.row);
            reset({
              index: params.row.index || '',
            });
            setShowModal({ type: 'edit', active: true });
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'excluir',
      headerName: 'Excluir',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          onClick={() => {
            setSelectedRow(params.row);
            setShowConfirmModal(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    indice: 'Índice',
    descricao: 'Descrição',
    editar: 'Editar',
    excluir: 'Excluir',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header
          title="Tipo de Despesa"
          onClick={() => {
            if (!selectedCompany) {
              setShowError(true);
              return;
            }
            reset({
              index: '',
              description: '',
            });
            setShowModal({ type: 'add', active: true });
          }}
        >
          <HeaderSearchSelectCompany
            label="Empresas"
            onChange={setSelectedCompany}
            error={showError}
            defaultValue={selectedCompany}
          />
        </Header>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.expenseTypeID}
          exportHeaders={exportHeaders}
          loading={expenseType.loading}
        />
        <ModalWrapper
          closeModalAction={() => {
            setShowModal((prev) => ({ ...prev, active: false }));
          }}
          showModal={showModal.active}
          title={
            showModal.type === 'add'
              ? 'Adicionar Tipo de Despesa'
              : 'Editar Tipo de Despesa'
          }
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              {showModal.type === 'edit' && (
                <Controller
                  name="index"
                  control={control}
                  rules={
                    showModal.type === 'edit' && {
                      required: 'Campo obrigatório',
                    }
                  }
                  render={({ field }) => (
                    <FormInput
                      label="Índice"
                      field={field}
                      errors={errors.index}
                      number={true}
                    />
                  )}
                />
              )}
              {showModal.type === 'add' && (
                <Controller
                  name="description"
                  control={control}
                  rules={
                    showModal.type === 'add' && {
                      required: 'Campo obrigatório',
                    }
                  }
                  render={({ field }) => (
                    <FormInput
                      label="Descrição"
                      field={field}
                      errors={errors.description}
                    />
                  )}
                />
              )}
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
              <Button variant="contained" type="submit">
                Salvar
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  setShowModal((prev) => ({ ...prev, active: false }))
                }
              >
                Cancelar
              </Button>
            </Box>
          </form>
        </ModalWrapper>
        <ModalConfirm
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          submit={handleDelete}
          title="Deseja Deletar o Tipo de Despesa?"
        >
          <Box>
            <Typography variant="subtitle1">
              O Tipo de Despesa será deletado permanentemente.
            </Typography>
          </Box>
        </ModalConfirm>
      </Container>
    </>
  );
};

export default ExpenseType;
