import { Box, Container, Grid, IconButton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerRange from '../../../../components/DatePickerRange';
import HeaderSearchSelectMultiple from '../../../../components/HeaderSearchSelectMultiple';
import HeaderSelect from '../../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../../components/NavBar';
import CustomTable from '../../../../components/Table/CustomTable';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { GetCostCenters } from '../../../../store/_Entities/CostCenter';
import { GetAllProviders } from '../../../../store/_Entities/Provider';
import {
  GetUnreconciledReport,
  GetUnreconciledReportByUser,
} from '../../../../store/_Entities/UnreconciledReport';
import validateDate from '../../../../utils/validateDate';
import { useNavigate } from 'react-router-dom';

const UnreconciledRaces = () => {
  const { user, company, costCenter, provider, unreconciledReport } =
    useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userID = user.authData.sub;

  const [companiesInputValue, setCompaniesInputValue] = useState([]);

  const [selectedCostCenter, setSelectedCostCenter] = useState('');
  const [selectProviderID, setSelectProviderID] = useState(0);
  const [costCenters, setCostCenters] = useState([]);

  const [data, setData] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const userRoles = user.roles;
  const dispatchUserRides =
    userRoles.includes('Driver') &&
    !userRoles.includes('systemadministrator') &&
    !userRoles.includes('costcenteradministrator') &&
    !userRoles.includes('companyadministrator');

  const fetchReports = () => {
    const startDateFormatted = startDate.toLocaleDateString('pt-BR');
    const endDateFormatted = endDate.toLocaleDateString('pt-BR');
    const providerID =
      selectProviderID === null || selectProviderID === 'null'
        ? null
        : selectProviderID.toString();
    const costCenterID = selectedCostCenter || 0;

    const request = dispatchUserRides
      ? {
          userID,
          companyID: user.user.companyID,
          costCenterID,
          startDate: startDateFormatted,
          endDate: endDateFormatted,
          providerID: providerID,
        }
      : {
          userID,
          companyID: 0,
          startDate: startDateFormatted,
          endDate: endDateFormatted,
          costCenterID,
          providerID,
          companies: companiesInputValue.length
            ? companiesInputValue.map((u) => u.companyID)
            : [0],
        };
    if (dispatchUserRides) {
      dispatch(GetUnreconciledReportByUser(request));
    } else {
      dispatch(GetUnreconciledReport(request));
    }
  };

  useEffect(() => {
    dispatch(GetAllProviders());
  }, []);

  useEffect(() => {
    if (companiesInputValue.length > 0) {
      dispatch(GetCostCenters(companiesInputValue[0].companyID));
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (
      companiesInputValue.length > 0 &&
      costCenter.costCenters &&
      costCenter.success
    ) {
      setCostCenters(costCenter.costCenters);
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (
      (companiesInputValue.length > 0 || dispatchUserRides) &&
      validateDate(startDate) &&
      validateDate(endDate)
    ) {
      fetchReports();
    }
  }, [
    companiesInputValue,
    selectedCostCenter,
    startDate,
    endDate,
    selectProviderID,
  ]);

  useEffect(() => {
    if (
      unreconciledReport?.unreconciledReports &&
      !unreconciledReport.loading &&
      (companiesInputValue.length > 0 || dispatchUserRides)
    ) {
      setData(unreconciledReport.unreconciledReports);
    }
  }, [unreconciledReport.unreconciledReports]);

  const columns = useMemo(
    () => [
      {
        field: 'conciliate',
        headerName: 'Conciliar Corrida',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <IconButton
            onClick={() => {
              navigate(`/driver/conciliar-corrida/${params.row.receiptID}`);
            }}
          >
            <ReceiptIcon />
          </IconButton>
        ),
      },
      {
        field: 'receiptID',
        headerName: 'ID receipt',
        headerAlign: 'center',
        align: 'center',
        minWidth: 120,
        flex: 1,
      },
      {
        field: 'companyID',
        headerName: 'ID da Empresa',
        headerAlign: 'center',
        align: 'center',
        minWidth: 120,
        flex: 1,
      },
      {
        field: 'company',
        headerName: 'Company',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'userID',
        headerName: 'ID do Usuário',
        headerAlign: 'center',
        align: 'center',
        minWidth: 140,
        flex: 1,
      },
      {
        field: 'registration',
        headerName: 'Matrícula',
        headerAlign: 'center',
        align: 'center',
        minWidth: 130,
        flex: 1,
      },
      {
        field: 'name',
        headerName: 'Nome',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'costCenter',
        headerName: 'Centro de Custo',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'phone',
        headerName: 'Telefone',
        headerAlign: 'center',
        align: 'center',
        minWidth: 130,
        flex: 1,
      },
      {
        field: 'create',
        headerName: 'Criado',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        flex: 1,
        valueFormatter: (params) => {
          const date = new Date(params.value);
          return date.toLocaleString('pt-BR', {
            dateStyle: 'short',
            timeStyle: 'short',
          });
        },
      },
      {
        field: 'startAddress',
        headerName: 'Origem',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'endAddress',
        headerName: 'Destino',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'provider',
        headerName: 'Fornecedor',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'product',
        headerName: 'Produto',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'price',
        headerName: 'Preço',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'transactionID',
        headerName: 'ID da Transação',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );

  const exportHeaders = useMemo(
    () => ({
      receiptID: 'ID Receipt',
      companyID: 'ID da Empresa',
      userID: 'ID do Usuário',
      registration: 'Matrícula',
      name: 'Nome',
      company: 'Empresa',
      userID: 'ID do Usuário',
      registration: 'Matrícula',
      userName: 'Nome',
      costCenter: 'Centro de Custo',
      phone: 'Telefone',
      create: 'Criado',
      startAddress: 'Origem',
      endAddress: 'Destino',
      provider: 'Fornecedor',
      product: 'Produto',
      price: 'Preço',
      transactionID: 'ID da Transação',
    }),
    []
  );

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Corridas Não Reconciliadas</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <Box sx={{ display: 'flex', gap: 5 }}>
                {!dispatchUserRides && (
                  <HeaderSearchSelectMultiple
                    label="Empresas"
                    onChange={(e) => {
                      setCompaniesInputValue(e.target.value);
                    }}
                    inputValue={companiesInputValue}
                    menuItems={company?.companies
                      ?.filter((company) => company.active == 1)
                      .sort((companyA, companyB) =>
                        companyA.giveName.localeCompare(companyB.giveName)
                      )
                      .map((company) => {
                        return { label: company.giveName, value: company };
                      })}
                    menuItemsSelectedChips={companiesInputValue?.map(
                      (company) => {
                        return { label: company.giveName, value: company };
                      }
                    )}
                  />
                )}
                <DatePickerRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={{ startDate: setStartDate, endDate: setEndDate }}
                />
                <HeaderSelect
                  label="Centro de Custo"
                  onChange={(event) => {
                    setSelectedCostCenter(event.target.value);
                  }}
                  menuItems={[
                    { label: 'TODOS', value: '0' },
                    ...(costCenters.map((costCenter) => ({
                      label: costCenter.description,
                      value: costCenter.costCenterID,
                    })) || []),
                  ]}
                  defaultValue={undefined}
                />
                <HeaderSelect
                  label="Provider ID"
                  onChange={(event) => {
                    setSelectProviderID(event.target.value);
                  }}
                  menuItems={[
                    {
                      label: 'TODOS',
                      value: 0,
                    },
                    ...(provider?.providers?.map((prov) => ({
                      label: prov.description,
                      value: prov.providerID,
                    })) || []),
                  ]}
                  defaultValue={undefined}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.receiptID}
          exportHeaders={exportHeaders}
          loading={unreconciledReport.loading}
        />
      </Container>
    </>
  );
};

export default UnreconciledRaces;
