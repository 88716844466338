// Log de Integrações
import { Box, Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerRange from '../../../components/DatePickerRange';
import { GetIntegrationLogs } from '../../../store/_Entities/IntegrationLog';
import validateDate from '../../../utils/validateDate';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';

const IntegrationLog = () => {
  const { company, integrationLog } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const [data, setData] = useState([]);

  useEffect(() => {
    if (selectedCompany && validateDate(startDate) && validateDate(endDate)) {
      const startDateFormatted = startDate.toLocaleDateString('pt-BR');
      const endDateFormatted = endDate.toLocaleDateString('pt-BR');
      dispatch(
        GetIntegrationLogs(
          selectedCompany,
          startDateFormatted,
          endDateFormatted
        )
      );
      dispatch(setLastCompanyFiltered(selectedCompany));
    }
  }, [selectedCompany, startDate, endDate]);

  useEffect(() => {
    if (integrationLog.logs && selectedCompany) {
      setData(integrationLog.logs);
    }
  }, [integrationLog.logs]);

  const columns = [
    {
      field: 'company',
      headerName: 'Empresa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'date',
      headerName: 'Data',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return new Date(params.value).toLocaleString('pt-BR');
      },
    },
    {
      field: 'action',
      headerName: 'Ação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
  ];

  const exportHeaders = {
    company: 'Empresa',
    date: 'Data',
    action: 'Ação',
    costCenter: 'Centro de Custo',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Log de Integrações</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <HeaderSearchSelectCompany
                  label="Empresas"
                  onChange={setSelectedCompany}
                  defaultValue={selectedCompany}
                />
                <DatePickerRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={{ startDate: setStartDate, endDate: setEndDate }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.expoIntegrationLogID}
          exportHeaders={exportHeaders}
          loading={integrationLog.loading}
        />
      </Container>
    </>
  );
};

export default IntegrationLog;
