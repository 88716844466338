import { TextField, InputAdornment } from '@material-ui/core'
import Lupa from '../../../assets/images/lupa.png'

const SearchBar = ({search, setSearch}) => {
    return(
        <TextField
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <img src={Lupa} alt="Lupa" style={{ width: '30px', transform:'scaleX(-1)' }} />
                </InputAdornment>
            )
        }}
        value={search}
        onChange={(s) => setSearch(s.target.value)}
        placeholder='Digite...'
        variant="outlined"
    />
    )
}

export default SearchBar