// export const AuthServiceUrl = process.env.REACT_APP_AUTH_SERVICE_URL;
export const AuthServiceUrl =
  'https://wexp-authserver.azurewebsites.net';
export const wExpoGatewayUrl =
  'https://wexp-wexpogateway.azurewebsites.net/';
export const wExpoGatewayApiUrl = wExpoGatewayUrl + 'api';
export const wExpoPublicAPI =
  'https://wexp-wexpopublicapi.azurewebsites.net/';
export const wExpoPublicAPIUrl = wExpoPublicAPI + 'api';

export const Endpoints = {
  Auth: {
    ConnectToken: `${AuthServiceUrl}/connect/token`,
  },
  SystemAdministrators: {
    SystemAdministrators: `${wExpoGatewayApiUrl}/SystemAdministrators`,
  },
  Company: {
    Company: `${wExpoGatewayApiUrl}/Company`,
    CompanyWithConfiguration: (companyID) =>
      `${wExpoGatewayApiUrl}/Company/CompanyWithConfiguration/${companyID}`,
    CompanyByUserID: (userID) =>
      `${wExpoGatewayApiUrl}/Company/Administrator?UserID=${userID}`,
  },
  CompanyConfiguration: {
    Configuration: (companyID) =>
      `${wExpoGatewayApiUrl}/Company/${companyID}/Configuration`,
  },
  User: {
    GetUser: `${wExpoGatewayApiUrl}/User`,
    GetActiveUsers: (companyID) =>
      `${wExpoGatewayApiUrl}/User/company/${companyID}?Status=1`,
    GetInactiveUsers: (companyID) =>
      `${wExpoGatewayApiUrl}/User/company/${companyID}?Status=0`,
    ActivateUser: (userID, originUserID) =>
      `${wExpoGatewayApiUrl}/User/${userID}/Activate?OriginUserID=${originUserID}`,
    UpdateUser: (userID) => `${wExpoGatewayApiUrl}/User/${userID}`,
  },
  Reason: {
    Reason: `${wExpoGatewayApiUrl}/Reason/`,
    ReasonByCompanyID: (companyID) =>
      `${wExpoGatewayApiUrl}/Reason?CompanyID=${companyID}`,
    DeleteReason: (reasonID) => `${wExpoGatewayApiUrl}/Reason/${reasonID}`,
    EditReason: (reasonID) => `${wExpoGatewayApiUrl}/Reason/${reasonID}`,
    CreateReason: `${wExpoGatewayApiUrl}/Reason`,
  },
  CostCenter: {
    GetCostCenters: (companyID) =>
      `${wExpoGatewayApiUrl}/CostCenter?CompanyID=${companyID}&active=1`,
  },
  InviteUser: {
    GetInviteUsers: (companyID) =>
      `${wExpoGatewayApiUrl}/InviteUsers?CompanyID=${companyID}`,
    GetInviteUsersByUserID: (userID) =>
      `${wExpoGatewayApiUrl}/InviteUsers/${userID}`,
    ResendInviteUser: (inviteUserID, userID) =>
      `${wExpoGatewayApiUrl}/InviteUsers/Resend?InviteUsersID=${inviteUserID}&UserID=${userID}`,
    DeleteInviteUser: (inviteUserID, userID) =>
      `${wExpoGatewayApiUrl}/InviteUsers/${inviteUserID}/Delete?UserID=${userID}`,
    CreateInviteUser: (userID) =>
      `${wExpoGatewayApiUrl}/InviteUsers?UserID=${userID}`,
  },
  Currency: {
    GetCurrency: (companyID) =>
      `${wExpoGatewayApiUrl}/Currency?companyID=${companyID}`,
    CreateCurrency: `${wExpoGatewayApiUrl}/Currency`,
    DeleteCurrency: (currencyID) =>
      `${wExpoGatewayApiUrl}/Currency/${currencyID}`,
  },
  Report: {
    GetRideEstimative: (companyID, startDate, endDate) =>
      `${wExpoGatewayApiUrl}/RideEstimative/Report?CompanyID=${companyID}&startDate=${startDate}&endDate=${endDate}`,
    GetUnreconciled: `${wExpoGatewayApiUrl}/Report/ListRideUnreconciled`,
    GetUnreconciledByUser: `${wExpoGatewayApiUrl}/Report/ListRideUnreconciledByUser`,
    GetCompletedRacesReport: `${wExpoGatewayApiUrl}/Report/ListRide`,
    GetCompletedRacesReportByUser: `${wExpoGatewayApiUrl}/Report/ListRideByUser`,
    GetProjectedReport: `${wExpoGatewayApiUrl}/Report/ListProject`,
    GetRideFeeEconomyReport: `${wExpoGatewayApiUrl}/Report/ListRideFeeEconomy`,
    GetNonConformityReport: `${wExpoGatewayApiUrl}/Report/ListNonConformity`,
    GetEconomyRideReport: `${wExpoGatewayApiUrl}/Report/ListRideEconomy`,
    GetListExpenses: (
      km,
      companyID,
      companies,
      costCenterID,
      startDate,
      endDate
    ) => {
      const baseUrl = `${wExpoGatewayApiUrl}/Report/${
        km ? 'ListExpensesKM' : 'ListExpenses'
      }?CompanyID=${companyID}`;
      const companiesParam = companies.map((id) => `Companies=${id}`).join('&');
      const costCenterParam = costCenterID
        ? `&CostCenterID=${costCenterID}`
        : '';
      return `${baseUrl}&${companiesParam}${costCenterParam}&StartDate=${startDate}&EndDate=${endDate}`;
    },
  },

  Saving: {
    GetListEconomySavings: `${wExpoGatewayApiUrl}/ReportSaving/ListEconomySavings`,
    GetDetailEconomySavings: (rideID) =>
      `${wExpoGatewayApiUrl}/ReportSaving?rideID=${rideID}`,
  },
  PolicyGroup: {
    GetPolicyGroups: (companyID) =>
      `${wExpoGatewayApiUrl}/PolicyGroup?CompanyID=${companyID}`,
    CreatePolicyGroup: `${wExpoGatewayApiUrl}/PolicyGroup`,
    DeletePolicyGroup: (policyGroupID) =>
      `${wExpoGatewayApiUrl}/PolicyGroup/${policyGroupID}`,
  },
  Policy: {
    GetPolicies: (policyGroupID) =>
      `${wExpoGatewayApiUrl}/Policy?PolicyGroupID=${policyGroupID}`,
    DeletePolicy: (policyID) => `${wExpoGatewayApiUrl}/Policy/${policyID}`,
    PostPolicy: `${wExpoGatewayApiUrl}/Policy`,
  },
  PolicyGroupUser: {
    GetPolicyGroupUsers: (policyGroupID) =>
      `${wExpoGatewayApiUrl}/PolicyGroupUsers?PolicyGroupID=${policyGroupID}`,
    CreatePolicyGroupUser: `${wExpoGatewayApiUrl}/PolicyGroupUsers`,
    DeletePolicyGroupUser: (policyGroupUsersID) =>
      `${wExpoGatewayApiUrl}/PolicyGroupUsers/${policyGroupUsersID}`,
  },
  Project: {
    Project: (projectID) => `${wExpoGatewayApiUrl}/Project/${projectID}`,
    GetProjects: (companyID) =>
      `${wExpoGatewayApiUrl}/Project?CompanyID=${companyID}&active=1`,
    CreateProject: `${wExpoGatewayApiUrl}/Project`,
  },
  Batching: {
    GetBatchings: (batchingTypeID, companyID) =>
      `${wExpoGatewayApiUrl}/Batching/company?BatchingTypeID=${batchingTypeID}&CompanyID=${companyID}`,
    GetBatchingLogs: (batchingID) =>
      `${wExpoGatewayApiUrl}/Batching/Log?BatchingID=${batchingID}`,
    GetBatchingFile: (batchingTypeID) =>
      `${wExpoGatewayApiUrl}/Batching?BatchingTypeID=${batchingTypeID}`,
    UploadBatchingFile: `${wExpoGatewayApiUrl}/Batching`,
  },
  IntegrationLog: {
    GetIntegrationLogs: (companyID, startDate, endDate) =>
      `${wExpoGatewayApiUrl}/ExpoIntegrationLog?CompanyID=${companyID}&StartDate=${startDate}&EndDate=${endDate}`,
  },
  DriverUserCategory: {
    GetDriverUserCategories: (userID) =>
      `${wExpoGatewayApiUrl}/Driver/User/Category?UserID=${userID}`,
    AddDriverUserCategory: `${wExpoGatewayApiUrl}/Driver/User/Category`,
  },
  UserConfiguration: {
    GetUserConfiguration: (userID) =>
      `${wExpoGatewayApiUrl}/User/${userID}/Configuration/Active`,
    AddUserConfiguration: (userID) =>
      `${wExpoGatewayApiUrl}/User/${userID}/Configuration/Active`,
  },
  ExpenseType: {
    ExpenseType: `${wExpoGatewayApiUrl}/ExpenseType`,
    GetExpenseTypes: (companyID) =>
      `${wExpoGatewayApiUrl}/ExpenseType?CompanyID=${companyID}`,
    DeleteExpenseType: (expenseTypeID) =>
      `${wExpoGatewayApiUrl}/ExpenseType/${expenseTypeID}`,
  },
  Provider: {
    GetAllProviders: `${wExpoGatewayApiUrl}/Provider/GetAll`,
  },
  SourceActiveUserLog: (companyID, startDate, endDate, sourceID) =>
    `${wExpoGatewayApiUrl}/SourceActiveUserLog?CompanyID=${companyID}&StartDate=${startDate}&EndDate=${endDate}&SourceID=${sourceID}`,
  Volume: {
    GetVolumeDashboard: (
      companyID,
      startDate,
      endDate,
      DentroOuFora,
      ProviderID
    ) =>
      `${wExpoPublicAPIUrl}/Receipt/VolumeDashboard?CompanyID=${companyID}&StartDate=${startDate}&EndDate=${endDate}&DentroOuFora=${DentroOuFora}&ProviderID=${ProviderID}`,
  },
  WarningType: {
    GetAllWarningTypes: `${wExpoGatewayApiUrl}/Report/GetWarningsTypes`,
  },
  Advanced: {
    GetFilter: (userID, advancedStatusID, startDate, endDate) =>
      `${wExpoGatewayApiUrl}/Advanced/GetFilter?UserID=${userID}&AdvancedStatusID=${advancedStatusID}&StartDate=${startDate}&EndDate=${endDate}`,
    GetAdvancedApprovalFlow: (advancedID) =>
      `${wExpoGatewayApiUrl}/Advanced/GetAdvancedApprovalFlow?AdvancedID=${advancedID}`,
    CreateAdvanced: `${wExpoGatewayApiUrl}/Advanced/Create`,
  },
  ExpensesReport: {
    GetReportFilter: (reportStatusID, releaseType) =>
      `${wExpoGatewayApiUrl}/ExpensesReport/GetReportFilter?ReportStatusID=${reportStatusID}&ReleaseType=${releaseType}`,
    GetReporAvailable: `${wExpoGatewayApiUrl}/ExpensesReport/Avaliable`,
    CreateReport: (description) =>
      `${wExpoGatewayApiUrl}/ExpensesReport?Description=${description}`,
    DeleteReport: (reportID) =>
      `${wExpoGatewayApiUrl}/ExpensesReport?id=${reportID}`,
    GetEdit: (reportID) =>
      `${wExpoGatewayApiUrl}/ExpensesReport/GetEdit?id=${reportID}`,
    EditReport: `${wExpoGatewayApiUrl}/ExpensesReport/Edit`,
    SendApproval: (reportID) =>
      `${wExpoGatewayApiUrl}/ExpensesReport/SendApproval?reportID=${reportID}`,
    GetExpenseReportsApproval: (reportID) =>
      `${wExpoGatewayApiUrl}/ExpensesReport/GetExpenseReportsApproval?reportID=${reportID}`,
    GetEvaluate: `${wExpoGatewayApiUrl}/ExpensesReport/Evaluate`,
    DownloadAttachments: (reportID) =>
      `${wExpoGatewayApiUrl}/ExpensesReport/DownloadAttachments?reportID=${reportID}`,
    EvaluateReport: (reportID) =>
      `${wExpoGatewayApiUrl}/ExpensesReport/EvaluateReport?reportID=${reportID}`,
    GetPendingAdvancedReport: (companyID, startDate, endDate) =>
      `${wExpoGatewayApiUrl}/ExpensesReport/GetPendingAdvancedReport?CompanyID=${companyID}&StartDate=${startDate}&EndDate=${endDate}`,
    CheckAttachments: (reportID) =>
      `${wExpoGatewayApiUrl}/ExpensesReport/CheckAttachments?reportID=${reportID}`,
  },
  Expenses: {
    GetExpenses: `${wExpoGatewayApiUrl}/Expense`,
    RemoveExpense: (expenseID) =>
      `${wExpoGatewayApiUrl}/Expenses?id=${expenseID}`,
    DeleteExpense: (expenseID) =>
      `${wExpoGatewayApiUrl}/Expenses/DeleteExpense?id=${expenseID}`,
    Expense: `${wExpoGatewayApiUrl}/Expense`,
    EditExpense: (expenseID) => `${wExpoGatewayApiUrl}/Expense/${expenseID}`,
    KmExpense: `${wExpoGatewayApiUrl}/Expense/Km`,
    EditKm: (expenseID) => `${wExpoGatewayApiUrl}/Expense/Km/${expenseID}`,
    GetEdit: (expenseID) =>
      `${wExpoGatewayApiUrl}/Expenses/GetEdit?Id=${expenseID}`,
    GetField: `${wExpoGatewayApiUrl}/Expense/Field`,
    GetPending: (userID) => `${wExpoGatewayApiUrl}/Expense/Pending/${userID}`,
    GetFileByExpenseID: (expenseID) =>
      `${wExpoGatewayApiUrl}/Expenses/GetFileByExpenseID?expenseID=${expenseID}`,
  },
  PaymentType: {
    GetPaymentType: (companyID) =>
      `${wExpoGatewayApiUrl}/PaymentType?companyID=${companyID}`,
  },
  Approval: {
    GetFilter: (startDate, endDate, statusReport, ReleaseType) =>
      `${wExpoGatewayApiUrl}/Approval/GetFilter?startDate=${startDate}&endDate=${endDate}&statusReport=${statusReport}&ReleaseType=${ReleaseType}`,
    GetApprovalStatus: (id) =>
      `${wExpoGatewayApiUrl}/Approval/GetApprovalStatus?ID=${id}`,
    GetEvaluate: (reportID, userID, releaseType) =>
      `${wExpoGatewayApiUrl}/Approval/GetEvaluate?reportID=${reportID}&userID=${userID}&ReleaseType=${releaseType}`,
    Refuse: (userID, description, reportID, expensesID) =>
      `${wExpoGatewayApiUrl}/Approval/Refuse?UserID=${userID}&Description=${description}&ReportID=${reportID}${
        expensesID ? `&ExpensesID=${expensesID}` : ''
      }`,
    Approve: (userID, reportID) =>
      `${wExpoGatewayApiUrl}/Approval/Approve?UserID=${userID}&ReportID=${reportID}`,
    AdvancedApprove: (advancedID, price) =>
      `${wExpoGatewayApiUrl}/Approval/AdvancedApprove?AdvancedID=${advancedID}&Price=${price}`,
    RefuseApprove: (justificationAdvanced, advancedID, price) =>
      `${wExpoGatewayApiUrl}/Approval/AdvancedRefuse?JustificationAdvanced=${justificationAdvanced}&AdvancedID=${advancedID}&Price=${price}`,
  },
  FinancialAdm: {
    GetFilter: (companyID, startDate, endDate, statusReport, ReleaseType) => `
    ${wExpoGatewayApiUrl}/FinancialAdm/GetFilter?CompanyID=${companyID}&StartDate=${startDate}&EndDate=${endDate}&StatusReport=${statusReport}&ReleaseType=${ReleaseType}`,
    GetApprovalStatus: (reportID) =>
      `${wExpoGatewayApiUrl}/FinancialAdm/GetApprovalStatus?ID=${reportID}`,
    GetEvaluate: (reportID, userID, releaseType) =>
      `${wExpoGatewayApiUrl}/FinancialAdm/GetEvaluate?reportID=${reportID}&userID=${userID}${
        releaseType ? `&releaseType=${releaseType}` : ''
      }`,
    ConfirmPayment: (reportID, releaseType) =>
      `${wExpoGatewayApiUrl}/FinancialAdm/ConfirmPayment?reportID=${reportID}&releaseType=${releaseType}`,
    RefusePayment: (reportID, releaseType, justification) =>
      `${wExpoGatewayApiUrl}/FinancialAdm/RefusePayment?reportID=${reportID}&releaseType=${releaseType}&justification=${justification}`,
  },
  Receipt: {
    PostReceipt: `${wExpoGatewayApiUrl}/Receipt`,
  },
  ExpenseEstimative: {
    GetExpenseEstimative: (expenseID) =>
      `${wExpoGatewayApiUrl}/ExpenseEstimative?ExpenseID=${expenseID}`,
  },
  ApprovalFlow: {
    ApprovalFlow: (approvalFlowID) =>
      `${wExpoGatewayApiUrl}/ApprovalFlow/${approvalFlowID}`,
    GetFilter: (
      companyID,
      userID,
      approver,
      approvalFlowUser,
      costCenterID
    ) => {
      let url = `${wExpoGatewayApiUrl}/ApprovalFlow/Filter?CompanyID=${companyID}&Approver=${approver}&ApprovalFlowUser=${approvalFlowUser}`;

      if (userID != null) {
        url += `&UserID=${userID}`;
      }
      if (costCenterID != null) {
        url += `&CostCenterID=${costCenterID}`;
      }

      return url;
    },
    ApprovalFlowFile: (companyID) =>
      `${wExpoGatewayApiUrl}/ApprovalFlow/ApprovalFlowFile?CompanyID=${companyID}`,
    AddConfigurationSingle: `${wExpoGatewayApiUrl}/ApprovalFlow/AddConfigurationSingle`,
    PostApprovalFlow: `${wExpoGatewayApiUrl}/ApprovalFlow`,
  },
  ApprovalFlowUsers: {
    ApprovalFlowUsers: (approvalFlowID) =>
      `${wExpoGatewayApiUrl}/ApprovalFlowUsers/${approvalFlowID}`,
    PostApprovalFlowUsers: `${wExpoGatewayApiUrl}/ApprovalFlowUsers`,
  },
  ExpenConfiguration: {
    Activate: (typeID) =>
      `${wExpoGatewayApiUrl}/ExpenConfiguration/Activate/${typeID}`,
    Inactivate: (typeID) =>
      `${wExpoGatewayApiUrl}/ExpenConfiguration/Inactivate/${typeID}`,
  },
};
