import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Header = ({ title, addHref, onClick, children }) => {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          py={1}
          sx={{ marginBottom: '10px', borderBottom: '1px solid black' }}
        >
          <h2>{title}</h2>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              {children}
            </Box>
            {(addHref || onClick) && (
              <Button
                variant="contained"
                onClick={onClick}
                href={addHref}
                sx={{ mt: { xs: 2, md: 0 } }}
              >
                <AddIcon />
                Adicionar
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
