import { createSlice } from '@reduxjs/toolkit';
import {IState} from './interfaces/Provider'
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
    loading: false,
    error: false,
    success: false,
    providers:[],
};

export const slice = createSlice({
    name: 'provider',
    initialState,
    reducers: {
        PROVIDER_REQUESTED: (state,action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        PROVIDER_FAILED: (state,action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        },
        PROVIDER_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.providers = action.payload.result;
        },
}
})

export const { PROVIDER_REQUESTED, PROVIDER_FAILED, PROVIDER_SUCCESS} = slice.actions;

export default slice.reducer;

export const GetAllProviders = () => {
    return apiCallBegan(
        {
            axiosConfig: {
                method: 'GET',
                url: Endpoints.Provider.GetAllProviders,
            },
            onAction: {
                onStart: PROVIDER_REQUESTED.type,
                onError: PROVIDER_FAILED.type,
                onSuccess: PROVIDER_SUCCESS.type,
            }
        }
    )
}

