import { Box, InputLabel, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const DatePickerRange = ({ startDate, endDate, onChange, error }) => {
  return (
    <Box>
      {error && (
        <Typography variant="caption" color="error">
          Campo obrigatório
        </Typography>
      )}
      <InputLabel id="period-select-label">Período</InputLabel>
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <DatePicker
            value={startDate}
            renderInput={(props) => <TextField {...props} size="small" />}
            onChange={(value) => onChange.startDate(value)}
            InputProps={{
              style: { height: '40px' },
            }}
          />
          <Typography variant="body1">até</Typography>
          <DatePicker
            value={endDate}
            renderInput={(props) => <TextField {...props} />}
            onChange={(value) => onChange.endDate(value)}
            InputProps={{
              style: { height: '40px' },
            }}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DatePickerRange;
