import { useEffect, useState } from 'react';
import { Box, Container, Button, Typography, IconButton } from '@mui/material';
import ResponsiveAppBar from '../../../NavBar';
import Footer from '../../../Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DeletePolicy, GetPolicies } from '../../../../store/_Entities/Policy';
import CustomDataGrid from '../../../grids/components/CustomDataGrid';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalConfirm from '../../../ModalConfirm';

const Policies = () => {
  const { companyID, policyGroupID } = useParams();
  const { policy } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    dispatch(GetPolicies(policyGroupID));
  }, []);

  const handleDeletePolicy = () => {
    dispatch(DeletePolicy(selectedID));
    setShowDeleteModal(false);
  };

  const policyColumns = [
    {
      field: 'sourceID',
      headerName: 'Vertical',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        switch (params.row.sourceID) {
          case 1:
            return 'Driver';
          case 2:
            return 'Expen';
          case 3:
            return 'Km';
          default:
            return '';
        }
      },
    },
    {
      field: 'type_Description',
      headerName: 'Tipo',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'value',
      headerName: 'Condição',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => {
        if (params.row.policyTypeID === 3) {
          switch (params.row.value) {
            case '0':
              return 'Dom';
            case '1':
              return 'Seg';
            case '2':
              return 'Ter';
            case '3':
              return 'Qua';
            case '4':
              return 'Qui';
            case '5':
              return 'Sex';
            case '6':
              return 'Sab';
            default:
              return '';
          }
        }
        if (params.row.policyTypeID === 4) {
          return `${params.row.value_Start} às ${params.row.value_End}`;
        }
      },
    },
    {
      field: 'expenseType',
      headerName: 'Tipo de despesa',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'blocker',
      headerName: 'Bloquear cadastro',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (params.row.blocker === 1 ? 'Sim' : 'Não'),
    },
    {
      field: 'actions',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setShowDeleteModal(true);
            setSelectedID(params.row.policyID);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Box xs={12} py={1} mb={'10px'}>
          <h2>Configurações Políticas</h2>
        </Box>

        <Box>
          <Box>
            <Box
              mt={5}
              minHeight={200}
              boxShadow={'0px 0px 4px 0px #00000040'}
              p={5}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/adm/politicas')}
                >
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(
                      `/adm/politicas/add/${companyID}/${policyGroupID}/policies/add`
                    )
                  }
                >
                  Adicionar
                </Button>
              </Box>

              <CustomDataGrid
                columns={policyColumns}
                rows={policy.policies}
                props={{
                  getRowId: (row) => row.policyID,
                  disableSelectionOnClick: true,
                  loading: policy.loading && !showDeleteModal,
                }}
              />
            </Box>
            <Box
              display={'flex'}
              justifyContent={'flex-end'}
              mt={2}
              gap={2}
            ></Box>
          </Box>
        </Box>
        <Footer />

        <ModalConfirm
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          title={'Deseja Excluir a Política?'}
          submit={handleDeletePolicy}
          loading={policy.loading}
        >
          <Box textAlign={'center'}>
            <Typography variant="subtitle">
              A Política será excluida permanentemente.
            </Typography>
          </Box>
        </ModalConfirm>
      </Container>
    </>
  );
};

export default Policies;
