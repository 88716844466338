import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';
import { IState } from './interfaces/RideEstimativeReport';

const initialState : IState = {
    loading: false,
    error: false,
    success: false,
    rideEstimativeReports: []
};

export const slice = createSlice({
    name: 'rideEstimativeReport',
    initialState,
    reducers: {
        REPORT_REQUESTED: (state,action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        REPORT_FAILED: (state,action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        },
        REPORT_ESTIMATIVE_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.rideEstimativeReports = action.payload.result;
        },
    }
})

export const { REPORT_REQUESTED, REPORT_FAILED, REPORT_ESTIMATIVE_SUCCESS } = slice.actions;

export default slice.reducer;

export const GetRideEstimativeReport = (CompanyID: number, startDate:string, endDate:string) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.Report.GetRideEstimative(CompanyID, startDate, endDate),
        },
        onAction: {
            onStart: REPORT_REQUESTED.type,
            onError: REPORT_FAILED.type,
            onSuccess: REPORT_ESTIMATIVE_SUCCESS.type,
        }
    })
}

