import { Box, Select, Typography, InputLabel } from '@mui/material';

const FormSelect = ({ label, field, errors, children }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
      <InputLabel htmlFor={field.name}>{label}</InputLabel>
      <Select
        id={field.name}
        size="small"
        value={field.value}
        onChange={(e) => field.onChange(e.target.value)}
        displayEmpty
        fullWidth
        renderValue={field.value !== '' ? undefined : () => 'Selecione...'}
        error={errors}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
      >
        {children}
      </Select>
      {errors && (
        <Typography variant="caption" color="error">
          {errors.message}
        </Typography>
      )}
    </Box>
  );
};

export default FormSelect;
