import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Unauthorized from '../../containers/Unauthorized';

// const unauthorizedPage = () => (

//     <div>You must be logged in to view this page</div>
// );

export const defaultRole = 'companyadministrator';

export const PageContainer = (props) => {
  const { user } = useSelector((state) => state.entities);
  const allowedRoles = props?.roles || [];
  if (props?.protected) {
    if (!user || !user.isLoggedIn) {
      return Unauthorized();
    }

    if (allowedRoles.length === 0) {
      return props.children;
    }

    if (user.roles.includes('systemAdministrator')) {
      return props.children;
    }

    const hasAccess =
      user.roles && user.roles.some((role) => allowedRoles.includes(role));
    if (!hasAccess) {
      return Unauthorized();
    }
  }
  return props.children;
};

export const PageContainerDashboard = (props) => {
  const { user } = useSelector((state) => state.entities);
  const allowedRole = 'companyadministrator';

  if (props?.protected) {
    if (!user || (!user.isLoggedIn && !user.roles.includes(allowedRole))) {
      return Unauthorized();
    }
  }

  return props.children;
};
