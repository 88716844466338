import { useEffect, useState, useMemo } from 'react';
import { Controller, get, useForm } from 'react-hook-form';
import {
  Container,
  Grid,
  Typography,
  MenuItem,
  Button,
  Select,
  IconButton,
  Box,
  Stepper,
  Step,
  StepLabel,
  Divider,
  TextField,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import ResponsiveAppBar from '../../NavBar';

import CustomDataGrid from '../../grids/components/CustomDataGrid';
import FormInput from '../../FormInput';
import { useNavigate, useParams } from 'react-router-dom';
import { Add, Delete } from '@mui/icons-material';
import HeaderSelect from '../../HeaderSelect';
import Footer from '../../Footer';
import ModalConfirm from '../../ModalConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { GetCostCenters } from '../../../store/_Entities/CostCenter';
import { GetActiveUsers } from '../../../store/_Entities/User';
import ModalErrorSuccess from '../../ModalErrorSuccess';
import {
  CreateApprovalFlow,
  GetApprovalFlow,
} from '../../../store/_Entities/ApprovalFlow';
import { inputformatNumber } from '../../../utils/format';

const ApprovalFlowForm = () => {
  const { companyID, approvalFlowID } = useParams();
  const { approvalFlow, costCenter, user } = useSelector(
    (state) => state.entities
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [steps, setSteps] = useState(1);
  const [actualStep, setActualStep] = useState(0);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [mandatorySteps, setMandatorySteps] = useState([]);
  const [mandatoryRules, setMandatoryRules] = useState([]);
  const [mandatoryValue, setMandatoryValue] = useState('');
  const [mandatoryCheckboxValue, setMandatoryCheckboxValue] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showUserExistsModal, setShowUserExistsModal] = useState(false);
  const [showNoUserModal, setShowNoUserModal] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      approvalFlowID: approvalFlowID || 0,
      companyID: +companyID,
      approvalFlowTypeID: 1,
      stepApproverRemoved: false,
      description: '',
      flowSteps: [],
      approvalFlowUsers: [],
    },
  });

  //Agrupar os usuários por etapa
  const groupUsersByStep = (steps, data) => {
    return Array.from({ length: steps }, (_, i) => ({
      step: i + 1,
      users: data.filter((user) => user.step === i),
    }));
  };

  //Mapear os dados para enviar para a API
  const mapFlowSteps = (usersByStep, mandatoryRules) => {
    return usersByStep.map((step) => ({
      stepCondition: step.users[0].condition,
      order: step.step,
      stepRules: mandatoryRules
        .filter((rule) => rule.step + 1 === step.step)
        .flatMap((rule) =>
          rule.rules.map((r) => ({
            rulesTypeID: r.rulesTypeID,
            value: r.value,
          }))
        ),
      steps: [
        {
          description: 'Grupo',
          approversCondition: step.users[0].condition,
          stepApprovers: step.users.map((user) => ({
            userID: user.userID,
            costCenterID: user.costCenterID,
          })),
        },
      ],
    }));
  };

  const onSubmit = (body) => {
    const usersByStep = groupUsersByStep(steps, data);

    if (usersByStep.some((step) => step.users.length === 0)) {
      setShowNoUserModal(true);
      return;
    }

    const flowSteps = mapFlowSteps(usersByStep, mandatoryRules);
    const req = { ...body, flowSteps };
    dispatch(CreateApprovalFlow(req));
    setSubmitted(true);
  };

  //Adicionar um novo usuário ao fluxo de aprovação
  const handleAddUser = () => {
    if (selectedUser && selectedCostCenter) {
      const userExists = data.find(
        (user) => user.userID === selectedUser.userID
      );
      if (userExists) {
        setShowUserExistsModal(true);
        return;
      }
      setData((prev) => [
        ...prev,
        {
          step: actualStep,
          userID: selectedUser.userID,
          name: selectedUser.name,
          costCenter: selectedCostCenter.description,
          costCenterID: selectedCostCenter.costCenterID,
          condition:
            data.findLast((user) => user.step === actualStep)?.condition || 'e',
        },
      ]);
    }
  };

  //Alterar a condição do usuário (E ou OU)
  const handleChangeCondition = (row, condition) => {
    setData((prev) =>
      prev.map((user) =>
        user.step === row.step ? { ...user, condition } : user
      )
    );
  };

  const handleDeleteUser = (userID) => {
    setData((prev) => prev.filter((user) => user.userID !== userID));
  };

  //Adicionar ou atualizar as regras obrigatórias
  const handleChangeMandatoryRules = (rule, value) => {
    setMandatoryRules((prev) => {
      const existingStepIndex = prev.findIndex(
        (item) => item.step === actualStep
      );

      if (existingStepIndex !== -1) {
        const updatedRules = [...prev];
        const existingRuleIndex = updatedRules[
          existingStepIndex
        ].rules.findIndex((r) => r.rulesTypeID === rule);

        if (existingRuleIndex !== -1) {
          updatedRules[existingStepIndex].rules[existingRuleIndex] = {
            rulesTypeID: rule,
            value: value.toString(),
          };
        } else {
          updatedRules[existingStepIndex].rules.push({
            rulesTypeID: rule,
            value: value.toString(),
          });
        }

        return updatedRules;
      } else {
        return [
          ...prev,
          {
            step: actualStep,
            rules: [{ rulesTypeID: rule, value: value.toString() }],
          },
        ];
      }
    });
  };

  useEffect(() => {
    dispatch(GetCostCenters(companyID));
    dispatch(GetActiveUsers(companyID));
    if (approvalFlowID) {
      dispatch(GetApprovalFlow(approvalFlowID));
    }
  }, [companyID, approvalFlowID]);

  // Handle initial edit values
  useEffect(() => {
    if (approvalFlowID && approvalFlow.success && approvalFlow.approvalFlow) {
      setValue('description', approvalFlow.approvalFlow.description);

      const { flowSteps } = approvalFlow.approvalFlow;

      if (flowSteps?.length > 0) {
        setSteps(flowSteps.length);

        const data = flowSteps.flatMap((step) =>
          step.steps.flatMap((s) =>
            s.stepApprovers.map((user) => ({
              step: step.order - 1,
              userID: user.userID,
              name: user.name,
              costCenter: user.costCenter,
              costCenterID: user.costCenterID,
              condition: s.approversCondition,
            }))
          )
        );
        setData(data);

        const mandatorySteps = flowSteps
          .filter((step) => step.order !== 1)
          .map((step) => ({
            step: step.order,
            show: step.stepRules.length <= 0,
            value: {
              1: step.stepRules.find((rule) => rule.rulesTypeID === 1)?.value,
              2: step.stepRules.find((rule) => rule.rulesTypeID === 2)?.value,
            },
          }));
        setMandatorySteps(mandatorySteps);

        const flowRules = flowSteps.reduce((acc, step) => {
          if (step.stepRules.length > 0) {
            acc.push({
              step: step.order - 1,
              rules: step.stepRules.map((rule) => ({
                rulesTypeID: rule.rulesTypeID.toString(),
                value: rule.value,
              })),
            });
          }
          return acc;
        }, []);
        setMandatoryRules(flowRules);
      }
    }
  }, [approvalFlowID, approvalFlow]);

  // Update users based on selected cost center
  const filteredUsers = useMemo(
    () =>
      user.users?.filter(
        (user) => user.costCenterID === selectedCostCenter?.costCenterID
      ) || [],
    [user.users, selectedCostCenter]
  );

  useEffect(() => {
    setUsers(filteredUsers);
  }, [filteredUsers]);

  // Show success modal
  useEffect(() => {
    if (submitted && !approvalFlow.loading && approvalFlow.success) {
      setShowSuccessModal(true);
    }
  }, [submitted, approvalFlow.loading, approvalFlow.success]);

  // Add new mandatory steps dynamically
  useEffect(() => {
    if (steps > 1) {
      setMandatorySteps((prev) => {
        const newSteps = Array.from({ length: steps - 1 }, (_, i) => ({
          step: i + 2,
          show: true,
        })).filter(
          (newStep) => !prev.some((step) => step.step === newStep.step)
        );
        return [...prev, ...newSteps];
      });
    }
  }, [steps]);

  // Update mandatory step values
  useEffect(() => {
    if (mandatorySteps.length > 0) {
      const stepValueChanged = mandatoryRules.find(
        (rule) => rule.step === actualStep
      );
      const stepValue = mandatorySteps.find(
        (step) => step.step - 1 === actualStep
      )?.value;

      setMandatoryValue(
        stepValueChanged?.rules.find((rule) => rule.rulesTypeID === '1')
          ?.value ||
          stepValue?.[1] ||
          ''
      );

      setMandatoryCheckboxValue(
        stepValueChanged?.rules.find((rule) => rule.rulesTypeID === '2')
          ?.value === 'true' || stepValue?.[2] === 'true'
      );
    }
  }, [actualStep, mandatorySteps, mandatoryRules]);

  const CustomStepIcon = () => {
    return (
      <Box
        onClick={() => {
          setSteps((prev) => prev + 1);
          setActualStep(steps);
        }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 32,
          height: 32,
          backgroundColor: 'grey.500',
          color: '#fff',
          borderRadius: '50%',
          cursor: 'pointer',
          zIndex: 10,
        }}
      >
        <Add />
      </Box>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Usuário',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'condition',
      headerName: 'Condição',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Select
            displayEmpty
            value={params.row.condition}
            sx={{ width: '70px', height: '30px' }}
            onChange={(event) =>
              handleChangeCondition(params.row, event.target.value)
            }
          >
            <MenuItem value={'e'}>E</MenuItem>
            <MenuItem value={'ou'}>OU</MenuItem>
          </Select>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleDeleteUser(params.row.userID)}>
            <Delete />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container sx={{ mt: 6 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            alignItems={'center'}
            sx={{
              boxShadow: '0px 0px 4px 0px #00000040',
              borderRadius: '10px',
              padding: 2,
              display: 'flex',
              alignItems: 'end',
            }}
          >
            {approvalFlow.loading ? (
              <Box
                display={'flex'}
                justifyContent={'center'}
                width={'100%'}
                mt={4}
                mb={4}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Fluxo de Aprovação</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: 'Campo obrigatório' }}
                    render={({ field }) => (
                      <FormInput
                        label="Descrição"
                        field={field}
                        errors={errors?.description}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stepper
                    activeStep={actualStep}
                    alternativeLabel
                    sx={{
                      '& .MuiStepIcon-root': {
                        fontSize: '2rem',
                        zIndex: 1,
                      },
                    }}
                  >
                    {Array.from({ length: steps }).map((_, index) => (
                      <Step key={index}>
                        <StepLabel
                          onClick={() => setActualStep(index)}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          {index > 0 && (
                            <IconButton
                              onClick={() => setShowDeleteModal(true)}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </StepLabel>
                      </Step>
                    ))}
                    <Step key="add-step">
                      <StepLabel StepIconComponent={CustomStepIcon}>
                        Adicionar Etapa
                      </StepLabel>
                    </Step>
                  </Stepper>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    O relatório precisa ser aprovado por:
                  </Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <HeaderSelect
                    label="Centro de Custo"
                    onChange={(e) => setSelectedCostCenter(e.target.value)}
                    menuItems={costCenter.costCenters.map((costCenter) => {
                      return {
                        label: costCenter.description,
                        value: costCenter,
                      };
                    })}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <HeaderSelect
                    label="Usuários"
                    onChange={(e) => setSelectedUser(e.target.value)}
                    menuItems={[
                      ...users.map((user) => {
                        return { label: user.name, value: user };
                      }),
                    ]}
                  />
                </Grid>
                {actualStep !== 0 && (
                  <Grid item xs={2.5}>
                    <HeaderSelect
                      label="Etapa obrigatória"
                      defaultValue={true}
                      value={
                        mandatorySteps.find(
                          (step) => step.step === actualStep + 1
                        )?.show ?? true
                      }
                      onChange={(e) => {
                        setMandatorySteps((prev) =>
                          prev.map((step) =>
                            step.step === actualStep + 1
                              ? { ...step, show: e.target.value }
                              : step
                          )
                        );

                        setMandatoryRules((prev) =>
                          prev.filter((rule) => rule.step !== actualStep)
                        );
                        setMandatoryValue('');
                        setMandatoryCheckboxValue(false);
                      }}
                      menuItems={[
                        { value: true, label: 'SIM' },
                        { value: false, label: 'NÃO' },
                      ]}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <Button variant="contained" onClick={handleAddUser}>
                    <Add />
                    Adicionar
                  </Button>
                </Grid>

                <Divider sx={{ width: '100%', marginTop: '6px' }} />
                {mandatorySteps.find(
                  (step) => step.step === actualStep + 1 && !step.show
                ) && (
                  <Grid item xs={12} display={'flex'} alignItems={'flex-start'}>
                    <Box>
                      <Typography variant="subtitle1">
                        Solicitar a aprovação quando relatórios estiverem com
                        valores superior a:
                      </Typography>
                      <TextField
                        value={mandatoryValue}
                        onChange={(e) => {
                          const formattedValue = inputformatNumber(
                            e.target.value
                          );
                          setMandatoryValue(formattedValue);
                          handleChangeMandatoryRules('1', formattedValue);
                        }}
                      />
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                      <Checkbox
                        color="primary"
                        checked={mandatoryCheckboxValue}
                        onChange={(e) => {
                          setMandatoryCheckboxValue(e.target.checked);
                          handleChangeMandatoryRules('2', e.target.checked);
                        }}
                      />
                      <Typography variant="subtitle1">
                        Quebra de politica: Solicitar a aprovação quando alguma
                        despesas do relatório estiver fora das politicas da
                        empresa.
                      </Typography>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <CustomDataGrid
                    columns={columns}
                    rows={data.filter((user) => user.step === actualStep)}
                    pageSize={5}
                    props={{
                      disableSelectionOnClick: true,
                      getRowId: (row) => row.userID,
                      loading: approvalFlow.loading,
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Box display={'flex'} gap={2} mt={2} justifyContent={'end'}>
            <Button
              variant="outlined"
              onClick={() => navigate('/km/fluxo-de-aprovacao')}
            >
              Cancelar
            </Button>
            <Button variant="contained" type="submit">
              Salvar
            </Button>
          </Box>
        </form>
        <Footer />
        <ModalConfirm
          title={'Deseja Excluir a Etapa?'}
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          submit={() => {
            setSteps((prev) => prev - 1);
            setActualStep((prev) => prev - 1);
            setShowDeleteModal(false);
          }}
        >
          <Typography variant="subtitle1">
            A Etapa será excluida permanentemente.
          </Typography>
        </ModalConfirm>
        <ModalErrorSuccess
          show={showSuccessModal || showUserExistsModal || showNoUserModal}
          handleClose={() =>
            showSuccessModal
              ? navigate('/km/fluxo-de-aprovacao')
              : showNoUserModal
              ? setShowNoUserModal(false)
              : setShowUserExistsModal(false)
          }
          title={showSuccessModal ? 'Salvar' : 'Erro'}
          error={showUserExistsModal || showNoUserModal}
        >
          <Typography variant="subtitle1">
            {showSuccessModal && 'Fluxo de aprovação salvo com sucesso.'}
            {showUserExistsModal &&
              'O usuário não pode ser aprovador duas vezes no mesmo fluxo de aprovação'}
            {showNoUserModal &&
              'Selecione pelo menos um usuário para cada etapa.'}
          </Typography>
        </ModalErrorSuccess>
      </Container>
    </>
  );
};

export default ApprovalFlowForm;
