import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/Batching';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  batchings: [],
  batchingLogs: [],
  batchingFile: {
    fileContents: '',
    contentType: '',
    fileDownloadName: '',
    lastModified: '',
    entityTag: '',
    enableRangeProcessing: false,
  },
};

export const slice = createSlice({
  name: 'batching',
  initialState,
  reducers: {
    BATCHING_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    BATCHING_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    BATCHING_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.batchings = action.payload.result;
    },
    BATCHING_LOGS_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.batchingLogs = action.payload.result;
    },
    BATCHING_FILE_EXAMPLE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.batchingFile = action.payload.result;
    },
    BATCHING_FILE_UPLOAD_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.batchings = [...state.batchings, action.payload.result];
    },
  },
});

export const {
  BATCHING_REQUESTED,
  BATCHING_FAILED,
  BATCHING_SUCCESS,
  BATCHING_LOGS_SUCCESS,
  BATCHING_FILE_EXAMPLE_SUCCESS,
  BATCHING_FILE_UPLOAD_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetBatchings = (batchingTypeID: number, companyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Batching.GetBatchings(batchingTypeID, companyID),
    },
    onAction: {
      onStart: BATCHING_REQUESTED.type,
      onError: BATCHING_FAILED.type,
      onSuccess: BATCHING_SUCCESS.type,
    },
  });
};

export const getBatchingLogs = (batchingID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Batching.GetBatchingLogs(batchingID),
    },
    onAction: {
      onStart: BATCHING_REQUESTED.type,
      onError: BATCHING_FAILED.type,
      onSuccess: BATCHING_LOGS_SUCCESS.type,
    },
  });
};

export const getBatchingFile = (batchingTypeID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Batching.GetBatchingFile(batchingTypeID),
    },
    onAction: {
      onStart: BATCHING_REQUESTED.type,
      onError: BATCHING_FAILED.type,
      onSuccess: BATCHING_FILE_EXAMPLE_SUCCESS.type,
    },
  });
};

export const uploadBatchingFile = (formData: any) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Batching.UploadBatchingFile,
      data: formData,
    },
    onAction: {
      onStart: BATCHING_REQUESTED.type,
      onError: BATCHING_FAILED.type,
      onSuccess: BATCHING_FILE_UPLOAD_SUCCESS.type,
    },
  });
};
