import { writeFile, utils } from 'xlsx';

export function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ',';

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

export function selectProps(props) {
    return function (obj) {
        const newObj = {};
        props.forEach((name) => {
            newObj[name] = obj[name];
        });

        return newObj;
    };
}

export const exportFile = (
    headers,
    originalItems,
    format = 'csv',
    fileTitle
) => {
    let items = originalItems.map(selectProps(Object.keys(headers)));

    if (headers) {
        items.unshift(headers);
    }

    var exportedFilename = fileTitle + '.' + format;

    if (format === 'csv') {
        var csv = convertToCSV(items);
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        downloadBlob(blob, exportedFilename);
    } else if (format === 'xls') {
        const ws = utils.json_to_sheet(items, { skipHeader: true });
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Sheet1');
        writeFile(wb, exportedFilename);
    } else {
        throw new Error('Unsupported format: ' + format);
    }
};

function downloadBlob(blob, filename) {
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement('a');
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
