import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/ExpenConfiguration';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
};

export const slice = createSlice({
  name: 'expenConfiguration',
  initialState,
  reducers: {
    EXPEN_CONFIGURATION_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    EXPEN_CONFIGURATION_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    EXPEN_CONFIGURATION_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
  },
});

export const {
  EXPEN_CONFIGURATION_REQUESTED,
  EXPEN_CONFIGURATION_FAILED,
  EXPEN_CONFIGURATION_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const ExpenConfigurationActivate = (
  expenConfigurationTypeID: string
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.ExpenConfiguration.Activate(expenConfigurationTypeID),
    },
    onAction: {
      onStart: EXPEN_CONFIGURATION_REQUESTED.type,
      onError: EXPEN_CONFIGURATION_FAILED.type,
      onSuccess: EXPEN_CONFIGURATION_SUCCESS.type,
    },
  });
};

export const ExpenConfigurationInactivate = (
  expenConfigurationTypeID: string
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.ExpenConfiguration.Inactivate(expenConfigurationTypeID),
    },
    onAction: {
      onStart: EXPEN_CONFIGURATION_REQUESTED.type,
      onError: EXPEN_CONFIGURATION_FAILED.type,
      onSuccess: EXPEN_CONFIGURATION_SUCCESS.type,
    },
  });
};
