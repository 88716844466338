// Usuários Inativos
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Container, Grid, Button, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { ModalWrapper } from '../../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { GetInactiveUsers } from '../../../store/_Entities/User';
import HeaderSearchSelect from '../../../components/HeaderSearchSelect';
import { ActivateUser } from '../../../store/_Entities/User';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';


const InactiveUsers = () => {
  const { user, company } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(GetInactiveUsers(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setData(user.users);
    }
  }, [user.users]);

  useEffect(() => {
    if (user.success) {
      setShowReactivateModal(false);
    }
  }, [user.success]);

  const reactivateHandler = () => {
    dispatch(ActivateUser(selectedUser.userID, user.authData.sub));
  };

  const columns = [
    {
      field: 'companyID',
      headerName: 'Empresa ID',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'registration',
      headerName: 'Matrícula',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'updated',
      headerName: 'Data',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return new Date(params.row.updated).toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'Reativar',
      headerName: 'Reativar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <RefreshIcon
          onClick={() => {
            setShowReactivateModal(true);
            setSelectedUser(params.row);
          }}
          sx={{ cursor: 'pointer' }}
        />
      ),
    },
  ];

  const exportHeaders = {
    CompanyID: 'Empresa ID',
    Matrícula: 'Matrícula',
    Nome: 'Nome',
    'Centro de Custo': 'Centro de Custo',
    'E-mail': 'E-mail',
    Telefone: 'Telefone',
    Data: 'Data',
    Reativar: 'Reativar',
    Saldo: 'Saldo',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Usuários Inativos</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <Box sx={{ display: 'flex', gap: 5 }}>
                <HeaderSearchSelectCompany
                  label={'Empresas'}
                  onChange={setSelectedCompany}
                  defaultValue={selectedCompany}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.userID}
          exportHeaders={exportHeaders}
          loading={user.loading}
        />

        <ModalWrapper
          showModal={showReactivateModal}
          closeModalAction={() => setShowReactivateModal(false)}
          title="Deseja Reativar o usuário?"
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box textAlign={'center'}>
              <Typography variant="subtitle1">{selectedUser?.name}</Typography>
              <br />
              <Typography variant="subtitle1">
                O usuário receberá um novo convite, e será ativado assim que
                cadastrar uma nova senha.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" onClick={reactivateHandler}>
              Confirmar
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowReactivateModal(false)}
            >
              Cancelar
            </Button>
          </Box>
        </ModalWrapper>
      </Container>
    </>
  );
};

export default InactiveUsers;
