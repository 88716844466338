// Motivo
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Container, IconButton, Box, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Header from '../../../components/Header';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteReason,
  EditReason,
  GetReasonsByCompanyId,
} from '../../../store/_Entities/Reason';
import { ModalWrapper } from '../../../components/Modal';
import alerta from '../../../assets/images/alerta.png';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';

const Reason = () => {
  const { company, reason } = useSelector((state) => state.entities);

  const [reasons, setReasons] = useState([]);

  const dispatch = useDispatch();

  const companies = company.companies;
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [showError, setShowError] = useState(false);

  /*   const handleChange = (event) => {
    setCompanySelected(event.target.value);
    if (event.target.value != 'all') {
      dispatch(GetReasonsByCompanyId(event.target.value));
      dispatch(setLastCompanyFiltered(event.target.value));
    } else setReasons([]);
  }; */

  const handleDeleteOnClick = (reasonToDelete) => {
    setSelectedReason(reasonToDelete);
    setShowDeleteModal(true);
  };

  const handleConfirmedDelete = () => {
    dispatch(DeleteReason(parseInt(selectedReason.reasonID)));
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (selectedCompany != 'all') setReasons(reason.reasons);
  }, [reason]);

  useEffect(() => {
    if (selectedCompany != 'all') {
      dispatch(GetReasonsByCompanyId(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
      setShowError(false);
    }
  }, [selectedCompany]);

  const columns = [
    {
      field: 'reasonID',
      headerName: 'Motivo ID',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Motivo Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'Editar',
      headerName: 'Editar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton href={`/adm/motivo/${params.row.reasonID}`}>
          {' '}
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'Deletar',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteOnClick(params.row)}>
          {' '}
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    reasonID: 'Motivo ID',
    Descrição: 'Descrição',
    Editar: 'Editar',
    Deletar: 'Deletar',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header
          title="Motivo"
          addHref={selectedCompany ? '/adm/motivo/add' : undefined}
          onClick={!selectedCompany ? () => setShowError(true) : undefined}
        >
          <HeaderSearchSelectCompany
            label={'Empresas'}
            onChange={setSelectedCompany}
            error={showError}
            defaultValue={selectedCompany}
          />
        </Header>
        <CustomTable
          columns={columns}
          data={reasons}
          getRowId={(row) => row.reasonID}
          exportHeaders={exportHeaders}
          loading={reason.loading}
        />

        <ModalWrapper
          showModal={showDeleteModal}
          closeModalAction={() => setShowDeleteModal(false)}
          title="Deseja Deletar o motivo?"
          img={alerta}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box>{selectedReason.description}</Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" onClick={handleConfirmedDelete}>
              Confirmar
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancelar
            </Button>
          </Box>
        </ModalWrapper>
      </Container>
    </>
  );
};

export default Reason;
