import { Box, Stepper, Step, StepLabel } from '@mui/material';

const ApprovalStepper = ({ data }) => {
  const getActiveStep = (approvalFlow) => {
    const reversedFlow = [...approvalFlow].reverse();

    for (let i = 0; i < reversedFlow.length; i++) {
      const approver = reversedFlow[i];

      if (approver.orderStatusID === 2 || approver.orderStatusID === 3) {
        return approvalFlow.length - i;
      }
    }

    return (
      approvalFlow.findIndex((approver) => approver.orderStatusID === 1) - 1
    );
  };
  return (
    <Stepper
      activeStep={getActiveStep(data)}
      alternativeLabel
      sx={{
        paddingY: 5,
        '& .MuiStepIcon-root': {
          fontSize: '2rem',
          zIndex: 1,
        },
        width: '600px',
      }}
    >
      {data.map((approver, index) => (
        <Step key={index}>
          <StepLabel>{approver?.orderStatus}</StepLabel>
          <Box display={'flex'} flexDirection="column" alignItems={'center'}>
            {approver?.orderStatusID === 1
              ? approver?.waiting.map((u) => <Box key={u.name}>{u.name}</Box>)
              : approver?.orderStatusID === 2
              ? approver?.approved.map((u) => (
                  <Box key={u.name}>
                    <p>{u.name}</p>
                    <p>
                      {new Date(u.updated).toLocaleString('pt-BR', {
                        dateStyle: 'short',
                        timeStyle: 'short',
                      })}
                    </p>
                  </Box>
                ))
              : approver?.orderStatusID === 3
              ? approver?.refused.map((u) => (
                  <Box key={u.name}>
                    <p>{u.name}</p>
                    <p>
                      {new Date(u.updated).toLocaleString('pt-BR', {
                        dateStyle: 'short',
                        timeStyle: 'short',
                      })}
                    </p>
                  </Box>
                ))
              : null}
          </Box>
        </Step>
      ))}
    </Stepper>
  );
};

export default ApprovalStepper;
