import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState, IReceiptRequest } from './interfaces/Receipt';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
};

export const slice = createSlice({
  name: 'receipt',
  initialState,
  reducers: {
    RECEIPT_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    RECEIPT_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    RECEIPT_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
  },
});

export const { RECEIPT_REQUESTED, RECEIPT_FAILED, RECEIPT_SUCCESS } =
  slice.actions;

export default slice.reducer;

export const PostReceipt = (data: IReceiptRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Receipt.PostReceipt,
      data,
    },
    onAction: {
      onStart: RECEIPT_REQUESTED.type,
      onError: RECEIPT_FAILED.type,
      onSuccess: RECEIPT_SUCCESS.type,
      showToast: true,
    },
  });
};
