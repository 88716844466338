import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Container, Grid, Box, Typography } from '@mui/material';

import ResponsiveAppBar from '../../../NavBar';
import FormMenu from '../../../FormMenu';
import Footer from '../../../Footer';

import ExpenKmForm from './ExpenKmForm';
import CategoriesFrom from './CategoriesForm';
import PersonalData from './PersonalDataForm';
import { useDispatch, useSelector } from 'react-redux';
import ModalErrorSuccess from '../../../ModalErrorSuccess';

const ActiveUsersForm = () => {
  const { user } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  const query = new URLSearchParams(location.search);
  const formParam = query.get('form');

  const [activeForm, setActiveForm] = useState(formParam || 'Dados Pessoais');
  const [showSuccessModal, setShowSuccessModal] = useState({
    show: false,
    type: null,
  });

  const formProps = {
    navigateBack: () => navigate('/adm/usuarios-ativos'),
    setShowSuccessModal,
  };

  useEffect(() => {
    query.set('form', activeForm);
    navigate(`${location.pathname}?${query.toString()}`, { replace: true });
  }, [activeForm]);

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <h2>Configuração</h2>
        <Grid container rowSpacing={4}>
          <Grid item xs={12} md={3}>
            <FormMenu
              height={190}
              menuItems={[
                'Dados Pessoais',
                'Configuração Expen / KM',
                'Categorias',
              ]}
              activeItem={activeForm}
              setActiveForm={setActiveForm}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Box>
              {activeForm === 'Dados Pessoais' && (
                <PersonalData {...formProps} userData={data} />
              )}
              {activeForm === 'Configuração Expen / KM' && (
                <ExpenKmForm {...formProps} />
              )}
              {activeForm === 'Categorias' && <CategoriesFrom {...formProps} />}
            </Box>
          </Grid>
        </Grid>
        <Footer />
        <ModalErrorSuccess
          show={showSuccessModal.show}
          handleClose={() =>
            setShowSuccessModal((prev) => ({
              ...prev,
              show: false,
            }))
          }
          title={'Cadastro'}
        >
          <Box>
            <Typography variant="subtitle1">
              Usuário atualizado com sucesso.
            </Typography>
          </Box>
        </ModalErrorSuccess>
      </Container>
    </>
  );
};

export default ActiveUsersForm;
