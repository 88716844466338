import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';
import { IState } from './interfaces/UnreconciledReport';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  unreconciledReports: [],
};

export const slice = createSlice({
  name: 'unreconciledReport',
  initialState,
  reducers: {
    REPORT_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    REPORT_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
      console.log('RREPORT_FAILED');
      console.log(action);
    },
    REPORT_ESTIMATIVE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      console.log('REPORT_ESTIMATIVE_SUCCESS');
      console.log(action);
      state.unreconciledReports = action.payload.result;
    },
  },
});

export const { REPORT_REQUESTED, REPORT_FAILED, REPORT_ESTIMATIVE_SUCCESS } =
  slice.actions;

export default slice.reducer;

export const GetUnreconciledReport = (request: any) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Report.GetUnreconciled,
      data: request,
    },
    onAction: {
      onStart: REPORT_REQUESTED.type,
      onError: REPORT_FAILED.type,
      onSuccess: REPORT_ESTIMATIVE_SUCCESS.type,
    },
  });
};

export const GetUnreconciledReportByUser = (request: any) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Report.GetUnreconciledByUser,
      data: request,
    },
    onAction: {
      onStart: REPORT_REQUESTED.type,
      onError: REPORT_FAILED.type,
      onSuccess: REPORT_ESTIMATIVE_SUCCESS.type,
    },
  });
};
