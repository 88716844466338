import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/Approval';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  reports: [],
  approvalFlow: [],
  evaluate: {
    mapKey: '',
    totalCurrency: [],
    ticketCurrency: [],
    registeredCurrency: [],
    expenseCurrency: [],
    kmCurrency: [],
    kmTypes: [],
    reportDescription: '',
    userName: '',
    reportID: 0,
    userID: '',
    companyID: 0,
    currentKmValue: 0,
    currentKmDate: '',
    expensesList: [],
    kmsList: [],
  },
  evaluateExpenses: {
    report: '',
    reportID: 0,
    expenses: [],
    currency: [],
    balance: '',
    reportStatus: 0,
    reportValue: '',
    reportTotalValue: '',
    advancedTotalValue: '',
    releaseType: '',
    advancedValue: '',
    advancedCurrency: '',
  },
};

export const slice = createSlice({
  name: 'approval',
  initialState,
  reducers: {
    APPROVAL_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    APPROVAL_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    APPROVAL_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reports = action.payload.result;
    },
    APPROVAL_FLOW_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.approvalFlow = action.payload.result;
    },
    APPROVAL_EVALUATE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.evaluate = action.payload.result;
    },
    APPROVAL_EVALUATE_EXPENSES_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.evaluateExpenses = action.payload.result;
    },
    APPROVAL_ADVANCED_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reports = state.reports.filter(
        (report) => report.advancedID !== action.payload.result.advancedID
      );
    },
    APPROVAL_REFUSE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
  },
});

export const {
  APPROVAL_REQUESTED,
  APPROVAL_FAILED,
  APPROVAL_SUCCESS,
  APPROVAL_FLOW_SUCCESS,
  APPROVAL_EVALUATE_SUCCESS,
  APPROVAL_ADVANCED_SUCCESS,
  APPROVAL_REFUSE_SUCCESS,
  APPROVAL_EVALUATE_EXPENSES_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetApprovalFilter = (
  startDate: string,
  endDate: string,
  statusReport: number,
  releaseType: number
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Approval.GetFilter(
        startDate,
        endDate,
        statusReport,
        releaseType
      ),
    },
    onAction: {
      onStart: APPROVAL_REQUESTED.type,
      onError: APPROVAL_FAILED.type,
      onSuccess: APPROVAL_SUCCESS.type,
    },
  });
};

export const GetApprovalFlow = (reportID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Approval.GetApprovalStatus(reportID),
    },
    onAction: {
      onStart: APPROVAL_REQUESTED.type,
      onError: APPROVAL_FAILED.type,
      onSuccess: APPROVAL_FLOW_SUCCESS.type,
    },
  });
};

export const AdvancedApprove = (advancedID: number, price: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.Approval.AdvancedApprove(advancedID, price),
    },
    onAction: {
      onStart: APPROVAL_REQUESTED.type,
      onError: APPROVAL_FAILED.type,
      onSuccess: APPROVAL_ADVANCED_SUCCESS.type,
      showToast: true,
    },
  });
};

export const RefuseApprove = (
  justificationAdvanced: string,
  advancedID: number,
  price: string
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.Approval.RefuseApprove(
        justificationAdvanced,
        advancedID,
        price
      ),
    },
    onAction: {
      onStart: APPROVAL_REQUESTED.type,
      onError: APPROVAL_FAILED.type,
      onSuccess: APPROVAL_ADVANCED_SUCCESS.type,
      showToast: true,
    },
  });
};

export const GetEvaluateReport = (reportID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ExpensesReport.EvaluateReport(reportID),
    },
    onAction: {
      onStart: APPROVAL_REQUESTED.type,
      onError: APPROVAL_FAILED.type,
      onSuccess: APPROVAL_EVALUATE_SUCCESS.type,
    },
  });
};

export const GetEvaluate = (
  reportID: number,
  userID: string,
  releaseType: number
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Approval.GetEvaluate(reportID, userID, releaseType),
    },
    onAction: {
      onStart: APPROVAL_REQUESTED.type,
      onError: APPROVAL_FAILED.type,
      onSuccess: APPROVAL_EVALUATE_EXPENSES_SUCCESS.type,
    },
  });
};

export const ApprovalRefuse = (
  userID: string,
  description: string,
  reportID: number,
  expensesID: string[]
) => {
  console.log(userID, description, reportID, expensesID);
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.Approval.Refuse(userID, description, reportID, expensesID),
    },
    onAction: {
      onStart: APPROVAL_REQUESTED.type,
      onError: APPROVAL_FAILED.type,
      onSuccess: APPROVAL_REFUSE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const ApprovalApprove = (userID: string, reportID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.Approval.Approve(userID, reportID),
    },
    onAction: {
      onStart: APPROVAL_REQUESTED.type,
      onError: APPROVAL_FAILED.type,
      onSuccess: APPROVAL_REFUSE_SUCCESS.type,
      showToast: true,
    },
  });
};
