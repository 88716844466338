import { createSlice } from '@reduxjs/toolkit';
import {
  IState,
  IPolicyGroupCreate,
  IPolicyGroupUserCreate,
  ICreatePolicyRequest,
} from './interfaces/Policy';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  policyGroups: [],
  policies: [],
  policyGroupUsers: [],
};

export const slice = createSlice({
  name: 'inviteUser',
  initialState,
  reducers: {
    POLICY_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    POLICY_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    POLICY_GROUP_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.policyGroups = action.payload.result;
    },
    POLICY_GROUP_CREATE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.policyGroups = [...state.policyGroups, action.payload.result];
    },
    POLICY_GROUP_DELETE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.policyGroups = state.policyGroups.filter(
        (policyGroup) =>
          policyGroup.policyGroupID !== action.payload.result.policyGroupID
      );
    },
    POLICY_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.policies = action.payload.result;
    },
    POLICY_DELETE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.policies = state.policies.filter(
        (policy) => policy.policyID !== action.payload.result.policyID
      );
    },
    POLICY_GROUP_USERS_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.policyGroupUsers = action.payload.result;
    },
    POLICY_CREATE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    POLICY_GROUP_USERS_DELETE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.policyGroupUsers = state.policyGroupUsers.filter(
        (policyGroupUser) =>
          policyGroupUser.policyGroupUsersID !==
          action.payload.result.policyGroupUsersID
      );
    },
  },
});

export const {
  POLICY_REQUESTED,
  POLICY_FAILED,
  POLICY_GROUP_SUCCESS,
  POLICY_GROUP_CREATE_SUCCESS,
  POLICY_GROUP_DELETE_SUCCESS,
  POLICY_SUCCESS,
  POLICY_DELETE_SUCCESS,
  POLICY_GROUP_USERS_SUCCESS,
  POLICY_CREATE_SUCCESS,
  POLICY_GROUP_USERS_DELETE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetPolicyGroups = (CompanyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.PolicyGroup.GetPolicyGroups(CompanyID),
    },
    onAction: {
      onStart: POLICY_REQUESTED.type,
      onError: POLICY_FAILED.type,
      onSuccess: POLICY_GROUP_SUCCESS.type,
    },
  });
};

export const CreatePolicyGroup = (data: IPolicyGroupCreate) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.PolicyGroup.CreatePolicyGroup,
      data: data,
    },
    onAction: {
      onStart: POLICY_REQUESTED.type,
      onError: POLICY_FAILED.type,
      onSuccess: POLICY_GROUP_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeletePolicyGroup = (policyGroupID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.PolicyGroup.DeletePolicyGroup(policyGroupID),
    },
    onAction: {
      onStart: POLICY_REQUESTED.type,
      onError: POLICY_FAILED.type,
      onSuccess: POLICY_GROUP_DELETE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const GetPolicies = (policyGroupID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Policy.GetPolicies(policyGroupID),
    },
    onAction: {
      onStart: POLICY_REQUESTED.type,
      onError: POLICY_FAILED.type,
      onSuccess: POLICY_SUCCESS.type,
    },
  });
};

export const DeletePolicy = (policyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.Policy.DeletePolicy(policyID),
    },
    onAction: {
      onStart: POLICY_REQUESTED.type,
      onError: POLICY_FAILED.type,
      onSuccess: POLICY_DELETE_SUCCESS.type,
    },
  });
};

export const GetPolicyGroupUsers = (policyGroupID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.PolicyGroupUser.GetPolicyGroupUsers(policyGroupID),
    },
    onAction: {
      onStart: POLICY_REQUESTED.type,
      onError: POLICY_FAILED.type,
      onSuccess: POLICY_GROUP_USERS_SUCCESS.type,
    },
  });
};

export const CreatePolicyGroupUser = (data: IPolicyGroupUserCreate) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.PolicyGroupUser.CreatePolicyGroupUser,
      data: data,
    },
    onAction: {
      onStart: POLICY_REQUESTED.type,
      onError: POLICY_FAILED.type,
      onSuccess: POLICY_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeletePolicyGroupUser = (policyGroupUsersID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.PolicyGroupUser.DeletePolicyGroupUser(policyGroupUsersID),
    },
    onAction: {
      onStart: POLICY_REQUESTED.type,
      onError: POLICY_FAILED.type,
      onSuccess: POLICY_GROUP_USERS_DELETE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const CreatePolicy = (data: ICreatePolicyRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Policy.PostPolicy,
      data: data,
    },
    onAction: {
      onStart: POLICY_REQUESTED.type,
      onError: POLICY_FAILED.type,
      onSuccess: POLICY_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};
