// Redux
import { combineReducers } from 'redux';

// TopLevel slices
import EntitiesReducer from './_Entities/index'
import UiReducer from './_UI/index';
import FormsReducer from './_Forms/index';

const rootReducer = combineReducers({
    entities: EntitiesReducer,
    ui: UiReducer,
    forms: FormsReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
