import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState, ICompany } from './interfaces/Company';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';
import { ICostCenterAdminCompanies } from './interfaces/CostCenter';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  companies: [],
  selectedCompany: {
    companyID: 0,
    name: '',
    giveName: '',
    logoImageFile: '',
    document: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    active: 0,
    companyConfigurations: [],
  },
  lastCompanyFiltered: 0,
};

export const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    COMPANY_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    COMPANY_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    COMPANY_SUCCESS: (state, action: { payload: { result: ICompany[] } }) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.companies = action.payload.result.map((company) => ({
        giveName: company.giveName,
        companyID: company.companyID,
        active: company.active,
      }));
    },
    COMPANY_CONFIGURATION_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.selectedCompany = action.payload.result;
    },
    COMPANY_FILTER_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.selectedCompany = action.payload.result;
    },
    setLastCompanyFiltered: (state, action) => {
      state.lastCompanyFiltered = action.payload;
    },
    SAVE_USER_COMPANY_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.companies = [action.payload];
    },
  },
});

export const {
  COMPANY_REQUESTED,
  COMPANY_FAILED,
  COMPANY_SUCCESS,
  COMPANY_CONFIGURATION_SUCCESS,
  setLastCompanyFiltered,
  SAVE_USER_COMPANY_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetCompanies = () => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Company.Company,
    },
    onAction: {
      onStart: COMPANY_REQUESTED.type,
      onError: COMPANY_FAILED.type,
      onSuccess: COMPANY_SUCCESS.type,
    },
  });
};

export const GetCompanyWithConfiguration = (companyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Company.CompanyWithConfiguration(companyID),
    },
    onAction: {
      onStart: COMPANY_REQUESTED.type,
      onError: COMPANY_FAILED.type,
      onSuccess: COMPANY_CONFIGURATION_SUCCESS.type,
    },
  });
};

export const GetCompaniesByUserID = (userID: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Company.CompanyByUserID(userID),
    },
    onAction: {
      onStart: COMPANY_REQUESTED.type,
      onError: COMPANY_FAILED.type,
      onSuccess: COMPANY_SUCCESS.type,
    },
  });
};
