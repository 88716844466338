import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ResponsiveAppBar from '../../NavBar';
import FormMenu from '../../FormMenu';
import FormInput from '../../FormInput';
import FormCheckbox from '../../FormCheckbox';
import Footer from '../../Footer';
import { useSelector, useDispatch } from 'react-redux';
import ModalConfirm from '../../ModalConfirm';
import ModalErrorSuccess from '../../ModalErrorSuccess';
import {
  CreateProject,
  GetProject,
  UpdateProject,
} from '../../../store/_Entities/Project';

const ProjectOsForm = () => {
  const { companyID, projectID } = useParams();
  const { project } = useSelector((state) => state.entities);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editMode = projectID ? true : false;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (projectID) {
      dispatch(GetProject(projectID));
    }
  }, []);

  useEffect(() => {
    if (editMode && project.selectedProject) {
      reset({
        ...project.selectedProject,
        companyID: companyID,
        productive: project.selectedProject.productive === 1,
        refundable: project.selectedProject.refundable === 1,
      });
    }
  }, [project.selectedProject]);

  const {
    setValue,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      companyID: companyID,
      number: '',
      description: '',
      productive: false,
      refundable: false,
    },
  });

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      productive: data.productive ? 1 : 0,
      refundable: data.refundable ? 1 : 0,
    };
    if (!editMode) {
      dispatch(CreateProject(formattedData));
    }
    if (editMode) {
      dispatch(UpdateProject(projectID, formattedData));
    }
    setShowSuccessModal(true);
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <h2>Configuração</h2>
        <Grid container rowSpacing={4}>
          <Grid item xs={12} md={3}>
            <FormMenu
              menuItems={['Projeto']}
              activeItem={'Projeto'}
              height={100}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Container>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    boxShadow: '0px 0px 4px 0px #00000040',
                    borderRadius: '10px',
                    padding: 2,
                  }}
                >
                  {project.loading && !showSuccessModal ? (
                    <Box
                      display={'flex'}
                      width={'100%'}
                      justifyContent={'center'}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6">Cadastrar Projeto</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name="number"
                          control={control}
                          rules={{ required: 'Campo obrigatório' }}
                          render={({ field }) => (
                            <FormInput
                              field={field}
                              label="Número de controle"
                              errors={errors.number}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name="description"
                          control={control}
                          rules={{ required: 'Campo obrigatório' }}
                          render={({ field }) => (
                            <FormInput
                              field={field}
                              label="Descrição"
                              errors={errors.description}
                            />
                          )}
                        />
                      </Grid>
                      <Grid container spacing={2} p={2}>
                        <Grid item xs={2}>
                          <Controller
                            name="productive"
                            control={control}
                            render={({ field }) => (
                              <FormCheckbox field={field} label="Produtivo" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Controller
                            name="refundable"
                            control={control}
                            render={({ field }) => (
                              <FormCheckbox
                                field={field}
                                label="Reembolsável"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  mt={2}
                  gap={2}
                >
                  <Button variant="contained" type="submit">
                    Salvar
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setShowConfirmModal(true)}
                  >
                    Cancelar
                  </Button>
                </Box>
              </form>
            </Container>
          </Grid>
        </Grid>
        <Footer />
        <ModalConfirm
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          submit={() => navigate('/adm/projetos-os')}
          title="Cancelar"
        >
          <Box>
            <Typography variant="subtitle1">
              Deseja realmente cancelar?
            </Typography>
          </Box>
        </ModalConfirm>
        <ModalErrorSuccess
          show={showSuccessModal && project.success}
          handleClose={() => navigate('/adm/projetos-os')}
          title={'Sucesso!'}
        >
          <Box>
            <Typography variant="subtitle1">
              Projeto cadastrado com sucesso.
            </Typography>
          </Box>
        </ModalErrorSuccess>
      </Container>
    </>
  );
};

export default ProjectOsForm;
