import { useForm, Controller, useWatch } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Container,
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import FormInput from '../../../FormInput';
import FormCheckbox from '../../../FormCheckbox';
import FormSelect from '../../../FormSelect';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  AddUserConfiguration,
  GetUserConfigurations,
} from '../../../../store/_Entities/UserConfiguration';
import { inputformatNumber } from '../../../../utils/format';

const ExpenKmForm = ({ navigateBack, setShowSuccessModal }) => {
  const { userID } = useParams();
  const { userConfiguration } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const maxDate = new Date(new Date().setDate(new Date().getDate() - 1));

  useEffect(() => {
    dispatch(GetUserConfigurations(userID));
  }, []);

  useEffect(() => {
    if (userConfiguration.userConfigurations) {
      setData(userConfiguration.userConfigurations);
    }
  }, [userConfiguration.userConfigurations]);

  useEffect(() => {
    if (data) {
      setValue('tipo', data.tipo || '');
      setValue('cartaoCombustivel', data.cartaoCombustivel || false);
      setValue('frotaPropria', data.frotaPropria || false);
      setValue('oldKmValue', data.oldKmValue || '');
      setValue('kmValue', data.kmValue || '');
    }
  }, [data]);

  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tipo: data?.tipo || '',
      cartaoCombustivel: data?.cartaoCombustivel || false,
      frotaPropria: data?.frotaPropria || false,
      oldKmValue: data?.oldKmValue || '',
      kmValue: data?.kmValue || '',
      kmValueDateString: data?.kmValueDateString || '',
    },
  });

  const selectedKmValue = useWatch({ control, name: 'kmValue' });

  const onSubmit = (data) => {
    const formattedDate =
      data.kmValueDateString &&
      !isNaN(new Date(data.kmValueDateString).getTime())
        ? Intl.DateTimeFormat('pt-BR').format(data.kmValueDateString)
        : '';

    const reqData = {
      userID: userID,
      ...data,
      kmValueDateString: formattedDate,
    };
    dispatch(AddUserConfiguration(userID, reqData));
    setHasSubmitted(true);
  };

  useEffect(() => {
    if (
      hasSubmitted &&
      userConfiguration.success &&
      !userConfiguration.loading
    ) {
      setShowSuccessModal({ show: true, type: 'ExpenKm' });
    }
  }, [hasSubmitted, userConfiguration.success]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          sx={{
            boxShadow: '0px 0px 4px 0px #00000040',
            borderRadius: '10px',
            padding: 2,
          }}
        >
          {userConfiguration.loading ? (
            <Box width={'100%'} textAlign={'center'}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Configurações</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="tipo"
                  control={control}
                  rules={{ required: 'Campo obrigatório' }}
                  render={({ field }) => (
                    <FormSelect field={field} errors={errors.tipo}>
                      <MenuItem value="MenorCaminho">Menor caminho</MenuItem>
                      <MenuItem value="MaisRapido">Mais rápido</MenuItem>
                      <MenuItem value="PontoaPonto">Ponto a Ponto</MenuItem>
                      <MenuItem value="Comportamento">Comportamento</MenuItem>
                      <MenuItem value="MaiorComDesvio">
                        Mais Rapido com Desvio
                      </MenuItem>
                    </FormSelect>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="cartaoCombustivel"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox field={field} label="Cartão Combustível" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="frotaPropria"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox field={field} label="Frota Própria" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Km</Typography>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="oldKmValue"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      label="Valor KM Atual"
                      field={field}
                      disabled={true}
                      placeholder={' '}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="kmValue"
                  control={control}
                  render={({ field }) => {
                    const [inputValue, setInputValue] = useState(
                      field.value || ''
                    );

                    const handleChange = (e) => {
                      const formattedValue = inputformatNumber(e.target.value);

                      setInputValue(formattedValue);
                      setValue('kmValue', formattedValue);
                    };

                    return (
                      <Box>
                        <InputLabel htmlFor="newKmValue">
                          Novo Valor KM
                        </InputLabel>
                        <TextField
                          id="newKmValue"
                          type="text"
                          variant="outlined"
                          value={inputValue}
                          placeholder={'Digite...'}
                          fullWidth
                          error={Boolean(errors.kmValue)}
                          onChange={handleChange}
                        />
                      </Box>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={3.5}>
                <Controller
                  name="kmValueDateString"
                  control={control}
                  rules={{
                    required: selectedKmValue ? 'Campo obrigatório' : false,
                  }}
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ptBR}
                    >
                      <Box>
                        <InputLabel htmlFor="newKmValue">
                          Data para o novo Valor KM
                        </InputLabel>
                        <DatePicker
                          value={field.value || null}
                          maxDate={maxDate}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              error={Boolean(errors.kmValueDateString)}
                            />
                          )}
                          onChange={(value) => {
                            setValue('kmValueDateString', value ? value : null);
                          }}
                        />
                        {errors.kmValueDateString && (
                          <Typography variant="caption" color="error">
                            {errors.kmValueDateString.message}
                          </Typography>
                        )}
                      </Box>
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
          <Button variant="contained" type="submit">
            Salvar
          </Button>
          <Button variant="outlined" onClick={navigateBack}>
            Voltar
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default ExpenKmForm;
