// Formatação de um número para moeda brasileira sem o símbolo de moeda
export const inputformatNumber = (value) => {
  const rawValue = value.replace(/[^\d]/g, '');
  const numericValue = parseFloat(rawValue) / 100;

  if (!isNaN(numericValue)) {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numericValue);
  }

  return '';
};

export const numberToCurrency = (params) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(params);
};

export const stringNumberToCurrency = (value) => {
  const integerPart = value.split('.')[0].replace(',', '.');
  const decimalPart = value.split('.')[1];
  return `${integerPart},${decimalPart}`
}