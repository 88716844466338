// Moeda
import { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Container,
  IconButton,
  MenuItem,
  Button,
  Typography,
} from '@mui/material';
import Header from '../../../components/Header';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { ModalWrapper } from '../../../components/Modal';
import FormSelect from '../../../components/FormSelect';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteCurrency,
  GetCurrency,
  PostCurrency,
} from '../../../store/_Entities/Currency';
import ModalConfirm from '../../../components/ModalConfirm';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';

const Currency = () => {
  const { user, company, currency } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [selectedCurrency, setSelectedCurrency] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (selectedCompany) {
      setShowError(false);
      dispatch(GetCurrency(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setData(currency.currencies);
    }
  }, [currency.currencies]);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = (req) => {
    const currencyRequest = {
      currencyID: 1,
      company: selectedCompany,
      currencyCode: '',
      name: '',
    };

    if (req.currency === 'BRL') {
      currencyRequest.currencyCode = 'BRL';
      currencyRequest.name = 'Brazilian Real';
    }
    if (req.currency === 'USD') {
      currencyRequest.currencyCode = 'USD';
      currencyRequest.name = 'US Dollar';
    }
    if (req.currency === 'EUR') {
      currencyRequest.currencyCode = 'EUR';
      currencyRequest.name = 'Euro';
    }

    dispatch(PostCurrency(currencyRequest));
    setShowAddModal(false);
    reset();
  };

  const handleDelete = () => {
    dispatch(DeleteCurrency(selectedCurrency));
    setShowConfirmModal(false);
  };

  const currencyData = [
    {
      id: 'BRL',
      value: 'BRL - Brazilian Real',
    },
    {
      id: 'USD',
      value: 'USD - US Dollar',
    },
    {
      id: 'EUR',
      value: 'EUR - Euro',
    },
  ];

  const columns = [
    {
      field: 'currencyID',
      headerName: 'Código',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'deletar',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          onClick={() => {
            setSelectedCurrency(params.row.currencyID);
            setShowConfirmModal(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    currencyID: 'Código',
    name: 'Nome',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header
          title="Moeda"
          onClick={() =>
            selectedCompany ? setShowAddModal(true) : setShowError(true)
          }
        >
          <HeaderSearchSelectCompany
            label="Empresas"
            onChange={setSelectedCompany}
            error={showError}
            defaultValue={selectedCompany}
          />
        </Header>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.currencyID}
          exportHeaders={exportHeaders}
          loading={currency.loading}
        />

        <ModalWrapper
          showModal={showAddModal}
          closeModalAction={() => setShowAddModal(false)}
          title="Adicionar Moeda"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Controller
                name="currency"
                control={control}
                defaultValue=""
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <FormSelect
                    label="Moeda"
                    field={field}
                    errors={errors.currency}
                  >
                    {currencyData.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </FormSelect>
                )}
              />
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
              <Button variant="contained" type="submit">
                Salvar
              </Button>
              <Button variant="outlined" onClick={() => setShowAddModal(false)}>
                Cancelar
              </Button>
            </Box>
          </form>
        </ModalWrapper>
        <ModalConfirm
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          submit={handleDelete}
          title="Deseja Excluir a Moeda?"
        >
          <Box>
            <Typography variant="subtitle1">
              A Moeda será excluida permanentemente.
            </Typography>
          </Box>
        </ModalConfirm>
      </Container>
    </>
  );
};

export default Currency;
