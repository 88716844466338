import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState, AdvancedRequest } from './interfaces/Advanced';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  advanceds: [],
  approvalFlow: [],
};

export const slice = createSlice({
  name: 'advanced',
  initialState,
  reducers: {
    ADVANCED_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    ADVANCED_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    ADVANCED_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.advanceds = action.payload.result;
    },
    ADVANCED_APPROVAL_FLOW_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.approvalFlow = action.payload.result.approversGroup;
    },
    ADVANCED_CREATE_SUCCESS: (state, action) => {
      console.log(action.payload.result);
      state.loading = false;
      state.error = false;
      state.success = true;
      state.advanceds = [...state.advanceds, action.payload.result];
    },
  },
});

export const {
  ADVANCED_REQUESTED,
  ADVANCED_FAILED,
  ADVANCED_SUCCESS,
  ADVANCED_APPROVAL_FLOW_SUCCESS,
  ADVANCED_CREATE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetFilterAdvanced = (
  userID: string,
  advancedStatusID: number,
  startDate: string,
  endDate: string
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Advanced.GetFilter(
        userID,
        advancedStatusID,
        startDate,
        endDate
      ),
    },
    onAction: {
      onStart: ADVANCED_REQUESTED.type,
      onError: ADVANCED_FAILED.type,
      onSuccess: ADVANCED_SUCCESS.type,
    },
  });
};

export const GetAdvancedApprovalFlow = (advancedID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Advanced.GetAdvancedApprovalFlow(advancedID),
    },
    onAction: {
      onStart: ADVANCED_REQUESTED.type,
      onError: ADVANCED_FAILED.type,
      onSuccess: ADVANCED_APPROVAL_FLOW_SUCCESS.type,
    },
  });
};

export const CreateAdvanced = (data: AdvancedRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Advanced.CreateAdvanced,
      data,
    },
    onAction: {
      onStart: ADVANCED_REQUESTED.type,
      onError: ADVANCED_FAILED.type,
      onSuccess: ADVANCED_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};
