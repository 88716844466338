// Políticas e Preferências
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Container, Box, Button, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreatePolicyGroup,
  DeletePolicyGroup,
  GetPolicyGroups,
} from '../../../store/_Entities/Policy';
import FormInput from '../../../components/FormInput';
import { ModalWrapper } from '../../../components/Modal';
import { useForm, Controller } from 'react-hook-form';
import ModalConfirm from '../../../components/ModalConfirm';
import { Person } from '@mui/icons-material';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';

const PolicyPreferences = () => {
  const { company, policy } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState({
    show: false,
    value: null,
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (req) => {
    const data = {
      description: req.description,
      companyID: selectedCompany,
    };
    dispatch(CreatePolicyGroup(data));
    setShowAddModal(false);
    reset();
  };

  const handleDelete = () => {
    dispatch(DeletePolicyGroup(showConfirmModal.value));
    setShowConfirmModal((prev) => ({ ...prev, show: false }));
  };

  useEffect(() => {
    if (selectedCompany) {
      setShowError(false);
      dispatch(GetPolicyGroups(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany))
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setData(policy.policyGroups);
    }
  }, [policy.policyGroups]);

  const columns = [
    {
      field: 'policyGroupID',
      headerName: 'Código',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Nome do grupo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'Deletar',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() =>
            setShowConfirmModal((prev) => ({
              show: true,
              value: params.row.policyGroupID,
            }))
          }
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      field: 'Políticas',
      headerName: 'Políticas',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          href={`politicas/add/${params.row.companyID}/${params.row.policyGroupID}/policies`}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'Usuários',
      headerName: 'Usuários',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          href={`politicas/${params.row.companyID}/${params.row.policyGroupID}/users/add`}
        >
          <Person />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    policyGroupID: 'Código',
    description: 'Nome do grupo',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header
          title="Políticas e Preferências"
          onClick={() =>
            selectedCompany ? setShowAddModal(true) : setShowError(true)
          }
        >
          <HeaderSearchSelectCompany
            label={'Empresas'}
            onChange={setSelectedCompany}
            error={showError}
            defaultValue={selectedCompany}
          />
        </Header>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.policyGroupID}
          exportHeaders={exportHeaders}
          loading={policy.loading}
        />

        <ModalWrapper
          showModal={showAddModal}
          closeModalAction={() => setShowAddModal(false)}
          title="Cadastro de Grupo"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <FormInput
                    field={field}
                    label={'Nome do grupo'}
                    errors={errors.description}
                  />
                )}
              />
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
              <Button variant="contained" type="submit">
                Salvar
              </Button>
              <Button variant="outlined" onClick={() => setShowAddModal(false)}>
                Cancelar
              </Button>
            </Box>
          </form>
        </ModalWrapper>

        <ModalConfirm
          show={showConfirmModal.show}
          handleClose={() =>
            setShowConfirmModal((prev) => ({ ...prev, show: false }))
          }
          title="Deseja Excluir o Grupo?"
          submit={handleDelete}
        >
          <Typography variant="subtitle">
            O Grupo será excluido permanentemente.
          </Typography>
        </ModalConfirm>
      </Container>
    </>
  );
};

export default PolicyPreferences;
