import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Button,
  IconButton,
  CircularProgress,
  Typography,
  ListItem,
  List,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import HeaderSelect from '../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBatchingFile,
  getBatchingLogs,
  GetBatchings,
  uploadBatchingFile,
} from '../../../store/_Entities/Batching';
import { ModalWrapper } from '../../../components/Modal';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company'
import Checked from "../../../assets/images/checked.png";
import { exportFile } from '../../../utils/download';

const BatchRegistration = () => {
  const { user, batching, company } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [batchingType, setBatchingType] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState({
    title: '',
    show: false,
  });
  const [fileExampleDownload, setFileExampleDownload] = useState(false);

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('File', file);
    formData.append('CompanyID', selectedCompany);
    formData.append('BatchingTypeID', batchingType);
    formData.append('UserID', user.authData.sub);
    dispatch(uploadBatchingFile(formData));
  };

  const showExportModalHandler = (title) => {
    setShowExportModal({ title, show: true });
  };

  const exportHeadersLog = {
    batchingLogID: "batchingLogID",
    batchingID: "batchingID",
    email: "email",
    successRecords: "successRecords",
    errorRecords: "errorRecords",
    error: "error",
    warningRecords: "warningRecords",
    warning: "warning"
  };

  const handleExportLog = (data, currBatchingID) => {
    exportFile(exportHeadersLog, data, 'xls', `log_${currBatchingID}`);
    showExportModalHandler('Excel exportado com sucesso');
  }

  const handleFileSampleDownload = async () => {
    if (batchingType) {
      dispatch(getBatchingFile(batchingType));
      setFileExampleDownload(true);
    }
  };

  useEffect(() => {
    if (selectedCompany && batchingType) {
      setShowError(false);
      dispatch(GetBatchings(batchingType, selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
    }
  }, [selectedCompany, batchingType]);

  useEffect(() => {
    if (selectedCompany && batchingType) {
      setData(batching.batchings);
    }
  }, [batching.batchings]);

  useEffect(() => {
    if (batching.batchingFile && fileExampleDownload) {
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${batching.batchingFile.fileContents}`;
      const downloadLink = document.createElement('a');
      const fileName = `${batching.batchingFile.fileDownloadName}.xlsx`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }, [batching.batchingFile]);

  const columns = [
    {
      field: 'batchingID',
      headerName: 'Wexp Id',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'company',
      headerName: 'Empresa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'user',
      headerName: 'Usuário',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Data de Criação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) =>
        new Date(params.row.created).toLocaleString('pt-BR'),
    },
    {
      field: 'Log',
      headerName: 'Log',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            dispatch(getBatchingLogs(params.row.batchingID));
            handleExportLog(batching.batchingLogs, params.row.batchingID)
          }}
        >
          <OpenInNewIcon />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    WexpId: 'WexpId',
    Empresa: 'Empresa',
    Usuario: 'Usuário',
    'Data de Criação': 'Data de Criação',
    Log: 'Log',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Carga em Lote</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <HeaderSearchSelectCompany
                  label={'Empresas'}
                  onChange={setSelectedCompany}
                  error={showError}
                  defaultValue={selectedCompany}
                />
                <HeaderSelect
                  label={'Tipo de Carga'}
                  onChange={(e) => setBatchingType(e.target.value)}
                  error={showError}
                  menuItems={[
                    { value: '1', label: 'Usuário' },
                    { value: '2', label: 'Centro de Custo' },
                    { value: '8', label: 'Administradores Centro de Custo' },
                    { value: '4', label: 'Orçamento Centro de Custo' },
                    { value: '3', label: 'Projeto' },
                    { value: '17', label: 'Atualizar Usuário' },
                    { value: '10', label: 'Beneflex (Crédito/Débito)' },
                    { value: '5', label: 'Excluir Usuário' },
                    { value: '6', label: 'Excluir Centro de Custo' },
                    { value: '7', label: 'Excluir Projeto' },
                    { value: '14', label: 'Grupo de Supervisores' },
                    { value: '15', label: 'Supervisores' },
                    { value: '11', label: 'Valor do Km' },
                    { value: '12', label: 'Integração Valor Expen' },
                  ]}
                />
              </Box>
              <Box display={'flex'} gap={2} sx={{ mt: { xs: 2, md: 0 } }}>
                <Button variant="contained" onClick={handleFileSampleDownload}>
                  <DownloadIcon />
                  Download modelo
                </Button>
                <Button
                  variant="contained"
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                >
                  <FileUploadIcon />
                  Carregar Arquivo
                  <input
                    onChange={handleUploadFile}
                    onClick={() =>
                      (!selectedCompany || !batchingType) && setShowError(true)
                    }
                    type={
                      !selectedCompany || !setBatchingType ? 'button' : 'file'
                    }
                    style={{
                      clip: 'rect(0 0 0 0)',
                      clipPath: 'inset(50%)',
                      height: 1,
                      overflow: 'hidden',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      whiteSpace: 'nowrap',
                      width: 1,
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.batchingID}
          exportHeaders={exportHeaders}
          loading={batching.loading && !showLogModal}
          dataGridInitialState={{
            sorting: {
              sortModel: [{ field: 'created', sort: 'desc' }],
            },
          }}
        />
        <ModalWrapper
          showModal={showExportModal.show}
          closeModalAction={() =>
            setShowExportModal((prev) => ({ ...prev, show: false }))
          }
          title={showExportModal.title}
          img={Checked}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={() =>
                setShowExportModal((prev) => ({ ...prev, show: false }))
              }
            >
              Ok
            </Button>
          </Box>
        </ModalWrapper>
      </Container>
    </>
  );
};

export default BatchRegistration;
