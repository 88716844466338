import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/ExpensesReport';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  reports: [],
  reportExpenses: {
    total: 0,
    advanced: false,
    value: 0,
    expenses: [],
  },
  approvalFlow: [],
  errorMessage: '',
  evaluate: {
    mapKey: '',
    totalCurrency: [],
    ticketCurrency: [],
    registeredCurrency: [],
    expenseCurrency: [],
    kmCurrency: [],
    kmTypes: [],
    reportDescription: '',
    userName: '',
    reportID: 0,
    userID: '',
    companyID: 0,
    currentKmValue: 0,
    currentKmDate: '',
  },
  checkAttachments: false,
  downloadFile: null,
};

export const slice = createSlice({
  name: 'expensesReport',
  initialState,
  reducers: {
    EXPENSES_REPORT_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    EXPENSES_REPORT_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
      state.errorMessage = action.payload.data;
    },
    EXPENSES_REPORT_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reports = action.payload.result;
    },
    EXPENSES_REPORT_SEND_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    EXPENSES_REPORT_GET_EDIT_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reportExpenses = action.payload.result;
    },
    EXPENSES_REPORT_EDIT_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reports = state.reports.map((report) =>
        report.reportID === action.payload.result.reportID
          ? action.payload.result
          : report
      );
    },
    EXPENSE_REPORT_APPROVAL_FLOW_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.approvalFlow = action.payload.result;
    },
    EXPENSES_REPORT_CREATE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reports = [...state.reports, action.payload.result];
    },
    EXPENSES_REPORT_DELETE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reports = state.reports.filter(
        (report) => report.reportID !== action.payload.reportID
      );
    },
    EXPENSES_REPORT_DELETE_EXPENSE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    EXPENSES_EVALUATE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.evaluate = action.payload.result;
},
    EXPENSES_CHECK_ATTACHMENTS_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.checkAttachments = action.payload.result;
    },
    EXPENSES_DOWNLOAD_ATTACHMENTS_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.downloadFile = action.payload;
    },
    EXPENSES_CLEAR_DOWNLOAD_FILE: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.downloadFile = null;
    },
  },
});

export const {
  EXPENSES_REPORT_REQUESTED,
  EXPENSES_REPORT_FAILED,
  EXPENSES_REPORT_SUCCESS,
  EXPENSES_REPORT_GET_EDIT_SUCCESS,
  EXPENSES_REPORT_EDIT_SUCCESS,
  EXPENSES_REPORT_CREATE_SUCCESS,
  EXPENSES_REPORT_DELETE_SUCCESS,
  EXPENSES_REPORT_DELETE_EXPENSE_SUCCESS,
  EXPENSES_REPORT_SEND_SUCCESS,
  EXPENSE_REPORT_APPROVAL_FLOW_SUCCESS,
  EXPENSES_EVALUATE_SUCCESS,
  EXPENSES_CHECK_ATTACHMENTS_SUCCESS,
  EXPENSES_DOWNLOAD_ATTACHMENTS_SUCCESS,
  EXPENSES_CLEAR_DOWNLOAD_FILE,
} = slice.actions;

export default slice.reducer;

export const GetExpensesReport = (
  reportStatusID: number,
  releaseType: number
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ExpensesReport.GetReportFilter(
        reportStatusID,
        releaseType
      ),
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_REPORT_SUCCESS.type,
    },
  });
};

export const GetReportsAvailable = () => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ExpensesReport.GetReporAvailable,
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_REPORT_SUCCESS.type,
    },
  });
};

export const GetExpensesReportEdit = (reportID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ExpensesReport.GetEdit(reportID),
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_REPORT_GET_EDIT_SUCCESS.type,
    },
  });
};

export const EditExpensesReport = (data: {
  reportID: number;
  description: string;
}) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.ExpensesReport.EditReport,
      data,
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_REPORT_EDIT_SUCCESS.type,
      showToast: true,
    },
  });
};

export const GetExpensesReportApprovalFlow = (reportID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ExpensesReport.GetExpenseReportsApproval(reportID),
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSE_REPORT_APPROVAL_FLOW_SUCCESS.type,
    },
  });
};

export const CreateReport = (description: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.ExpensesReport.CreateReport(description),
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_REPORT_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteReport = (reportID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.ExpensesReport.DeleteReport(reportID),
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_REPORT_DELETE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteExpense = (expenseID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.Expenses.RemoveExpense(expenseID),
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_REPORT_DELETE_EXPENSE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const SendApproval = (reportID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.ExpensesReport.SendApproval(reportID),
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_REPORT_SEND_SUCCESS.type,
      showToast: true,
    },
  });
};

export const GetEvaluate = () => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ExpensesReport.GetEvaluate,
       },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_EVALUATE_SUCCESS.type,
    },
  });
}
export const CheckAttachments = (reportID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ExpensesReport.CheckAttachments(reportID),
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_CHECK_ATTACHMENTS_SUCCESS.type,
    },
  });
};

export const DownloadAttachments = (reportID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ExpensesReport.DownloadAttachments(reportID),
      responseType: 'blob',
    },
    onAction: {
      onStart: EXPENSES_REPORT_REQUESTED.type,
      onError: EXPENSES_REPORT_FAILED.type,
      onSuccess: EXPENSES_DOWNLOAD_ATTACHMENTS_SUCCESS.type,
    },
  });
};
