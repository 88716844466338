import { createSlice } from '@reduxjs/toolkit';
import {
  IState,
  IAddSingleConfigurationRequest,
  IPostApprovalFlowUsersRequest,
  IApprovalFlowResult,
} from './interfaces/ApprovalFlow';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  approvalFlows: [],
  approvalFlow: {
    approvalFlowID: 0,
    CompanyID: 0,
    Company: '',
    Description: '',
    ApprovalFlowTypeID: 0,
    StepApproverRemoved: false,
    FlowSteps: [],
    ApprovalFlowUsers: [],
  },
  users: [],
  file: null,
};

export const slice = createSlice({
  name: 'approvalFlow',
  initialState,
  reducers: {
    APPROVAL_FLOW_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    APPROVAL_FLOW_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    APPROVAL_FLOW_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    APPROVAL_FLOW_REPORTS_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.approvalFlows = action.payload.result;
    },
    APPROVAL_FLOW_DOWNLOAD_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.file = action.payload;
    },
    APPROVAL_FLOW_CLEAR_DOWNLOAD_FILE: (state) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.file = null;
    },
    APPROVAL_FLOW_USERS_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.users = action.payload.result;
    },
    APPROVAL_FLOW_DELETE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.approvalFlows = state.approvalFlows.filter(
        (approvalFlow) =>
          approvalFlow.approvalFlowID !== action.payload.result.approvalFlowID
      );
    },
    APPROVAL_FLOW_EDIT_REPORT_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.approvalFlow = action.payload.result;
    },
  },
});

export const {
  APPROVAL_FLOW_REQUESTED,
  APPROVAL_FLOW_FAILED,
  APPROVAL_FLOW_SUCCESS,
  APPROVAL_FLOW_REPORTS_SUCCESS,
  APPROVAL_FLOW_DOWNLOAD_SUCCESS,
  APPROVAL_FLOW_CLEAR_DOWNLOAD_FILE,
  APPROVAL_FLOW_USERS_SUCCESS,
  APPROVAL_FLOW_DELETE_SUCCESS,
  APPROVAL_FLOW_EDIT_REPORT_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetApprovalFlows = (
  companyID: number,
  userID: string,
  approver: boolean,
  approvalFlowUser: boolean,
  costCenterID: number
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ApprovalFlow.GetFilter(
        companyID,
        userID,
        approver,
        approvalFlowUser,
        costCenterID
      ),
    },
    onAction: {
      onStart: APPROVAL_FLOW_REQUESTED.type,
      onError: APPROVAL_FLOW_FAILED.type,
      onSuccess: APPROVAL_FLOW_REPORTS_SUCCESS.type,
    },
  });
};

export const GetApprovalFlow = (approvalFlowID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ApprovalFlow.ApprovalFlow(approvalFlowID),
    },
    onAction: {
      onStart: APPROVAL_FLOW_REQUESTED.type,
      onError: APPROVAL_FLOW_FAILED.type,
      onSuccess: APPROVAL_FLOW_EDIT_REPORT_SUCCESS.type,
    },
  });
};

export const GetApprovalFlowFile = (companyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ApprovalFlow.ApprovalFlowFile(companyID),
      responseType: 'blob',
    },
    onAction: {
      onStart: APPROVAL_FLOW_REQUESTED.type,
      onError: APPROVAL_FLOW_FAILED.type,
      onSuccess: APPROVAL_FLOW_DOWNLOAD_SUCCESS.type,
    },
  });
};

export const ApprovalFlowAddSingleConfiguration = (
  req: IAddSingleConfigurationRequest
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.ApprovalFlow.AddConfigurationSingle,
      data: req,
    },
    onAction: {
      onStart: APPROVAL_FLOW_REQUESTED.type,
      onError: APPROVAL_FLOW_FAILED.type,
      onSuccess: APPROVAL_FLOW_SUCCESS.type,
      showToast: true,
    },
  });
};

export const GetApprovalFlowUsers = (approvalFlowID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ApprovalFlowUsers.ApprovalFlowUsers(approvalFlowID),
    },
    onAction: {
      onStart: APPROVAL_FLOW_REQUESTED.type,
      onError: APPROVAL_FLOW_FAILED.type,
      onSuccess: APPROVAL_FLOW_USERS_SUCCESS.type,
    },
  });
};

export const PostApprovalFlowUsers = (req: IPostApprovalFlowUsersRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.ApprovalFlowUsers.PostApprovalFlowUsers,
      data: req,
    },
    onAction: {
      onStart: APPROVAL_FLOW_REQUESTED.type,
      onError: APPROVAL_FLOW_FAILED.type,
      onSuccess: APPROVAL_FLOW_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteApprovalFlowUsers = (approvalFlowUserID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.ApprovalFlowUsers.ApprovalFlowUsers(approvalFlowUserID),
    },
    onAction: {
      onStart: APPROVAL_FLOW_REQUESTED.type,
      onError: APPROVAL_FLOW_FAILED.type,
      onSuccess: APPROVAL_FLOW_SUCCESS.type,
      showToast: true,
    },
  });
};

export const CreateApprovalFlow = (req: any) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.ApprovalFlow.PostApprovalFlow,
      data: req,
    },
    onAction: {
      onStart: APPROVAL_FLOW_REQUESTED.type,
      onError: APPROVAL_FLOW_FAILED.type,
      onSuccess: APPROVAL_FLOW_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteApprovalFlow = (approvalFlowID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.ApprovalFlow.ApprovalFlow(approvalFlowID),
    },
    onAction: {
      onStart: APPROVAL_FLOW_REQUESTED.type,
      onError: APPROVAL_FLOW_FAILED.type,
      onSuccess: APPROVAL_FLOW_DELETE_SUCCESS.type,
      showToast: true,
    },
  });
};
