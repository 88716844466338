import { ModalWrapper } from './Modal';
import checked from '../assets/images/checked.png';
import cancel from '../assets/images/cancel.png';
import { Box, Button } from '@mui/material';

const ModalErrorSuccess = ({
  show,
  error = false,
  handleClose,
  title,
  children,
}) => {
  return (
    <ModalWrapper
      showModal={show}
      closeModalAction={handleClose}
      title={title}
      img={!error ? checked : cancel}
    >
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{children}</Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button variant="contained" onClick={handleClose}>
          Ok
        </Button>
      </Box>
    </ModalWrapper>
  );
};

export default ModalErrorSuccess;
