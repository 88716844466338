import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import ResponsiveAppBar from '../../../../components/NavBar';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { GetFields } from '../../../../store/_Entities/Expenses';
import { GetProjects } from '../../../../store/_Entities/Project';
import { GetReasonsByCompanyId } from '../../../../store/_Entities/Reason';
import FormInput from '../../../../components/FormInput';
import FormSelect from '../../../../components/FormSelect';
import ModalErrorSuccess from '../../../../components/ModalErrorSuccess';
import { PostReceipt } from '../../../../store/_Entities/Receipt';

const ConciliateRace = () => {
  const { receiptID } = useParams();
  const { user, expenses, project, reason, receipt } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fields, setFields] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const isLoading = [
    user.loading,
    expenses.loading,
    reason.loading,
    project.loading,
    receipt.loading,
  ].some(Boolean);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      receiptID,
      totalUsers: 1,
      projectID: '',
      reasonID: '',
      justification: '',
    },
  });

  const onSubmit = (data) => {
    const customFieldsItems = fields.map((field) => {
      let text = '';
      if (field.fieldID === 1) {
        text = data.projectID; // valor de 'Projeto'
      } else if (field.fieldID === 2) {
        text = data.reasonID; // valor de 'Motivo'
      } else if (field.fieldID === 3) {
        text = data.justification; // valor de 'Justificativa'
      }

      return {
        customFieldID: field.fieldID.toString(),
        typeID: +field.fieldID,
        text: text.toString(),
      };
    });

    const sendData = {
      ReceiptID: +data.receiptID,
      TotalUsers: +data.totalUsers,
      CustomFieldsItems: customFieldsItems,
    };
    console.log(sendData);
    dispatch(PostReceipt(sendData));
    setHasSubmitted(true);
  };

  useEffect(() => {
    dispatch(GetFields());
    setHasFetched(true);
  }, []);

  useEffect(() => {
    const companyID = user.user.companyID;

    if (expenses.fields.length > 0 && hasFetched) {
      const updatedFields = expenses.fields
        .map((field) => {
          if (field.fieldID === 1) {
            dispatch(GetProjects(companyID));
            return { fieldID: field.fieldID, label: 'Projeto' };
          }
          if (field.fieldID === 2) {
            dispatch(GetReasonsByCompanyId(companyID));
            return { fieldID: field.fieldID, label: 'Motivo' };
          }
          if (field.fieldID === 3) {
            return { fieldID: field.fieldID, label: 'Justificativa' };
          }
          return null;
        })
        .filter(Boolean);

      setFields(updatedFields);
    }
  }, [expenses.fields]);

  useEffect(() => {
    if (hasSubmitted && !receipt.loading && receipt.success) {
      setShowModal(true);
    }
  }, [receipt]);

  const hasFieldID = (id) => fields.some((field) => field.fieldID === id);

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth="md" sx={{ mt: 6 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            sx={{
              boxShadow: '0px 0px 4px 0px #00000040',
              borderRadius: '10px',
              padding: 2,
            }}
          >
            {isLoading ? (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Conciliar Corrida</Typography>
                </Grid>
                {hasFieldID(1) && (
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="projectID"
                      control={control}
                      rules={{ required: 'Campo obrigatório' }}
                      render={({ field }) => (
                        <FormSelect
                          field={field}
                          errors={errors.projectID}
                          label={'Projeto'}
                        >
                          {project.projects.map((project) => (
                            <MenuItem
                              key={project.projectID}
                              value={project.projectID}
                            >
                              {project.number} - {project.description}
                            </MenuItem>
                          ))}
                        </FormSelect>
                      )}
                    />
                  </Grid>
                )}
                {hasFieldID(2) && (
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="reasonID"
                      control={control}
                      rules={{ required: 'Campo obrigatório' }}
                      render={({ field }) => (
                        <FormSelect
                          field={field}
                          errors={errors.reasonID}
                          label={'Motivo'}
                        >
                          {reason.reasons.map((reason) => (
                            <MenuItem
                              key={reason.reasonID}
                              value={reason.reasonID}
                            >
                              {reason.description}
                            </MenuItem>
                          ))}
                        </FormSelect>
                      )}
                    />
                  </Grid>
                )}

                {hasFieldID(3) && (
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="justification"
                      control={control}
                      rules={{ required: 'Campo obrigatório' }}
                      render={({ field }) => (
                        <FormInput
                          field={field}
                          errors={errors.justification}
                          label={'Justificativa'}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Controller
                    name="totalUsers"
                    control={control}
                    rules={{
                      required: 'Campo obrigatório',
                      validate: {
                        positiveNumber: (value) => {
                          return value > 0 || 'Deve ser maior que 0';
                        },
                      },
                    }}
                    render={({ field }) => (
                      <FormInput
                        field={field}
                        errors={errors.totalUsers}
                        label={'Número de passageiros'}
                        number
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
            <Button variant="contained" type="submit">
              Salvar
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate('/driver/corridas-nao-reconciliadas')}
            >
              Cancelar
            </Button>
          </Box>
        </form>
        <ModalErrorSuccess
          show={showModal}
          title={'Sucesso'}
          handleClose={() => navigate('/driver/corridas-nao-reconciliadas')}
        >
          <Box>
            <Typography variant="subtitle1">
              Corrida conciliada com sucesso!
            </Typography>
          </Box>
        </ModalErrorSuccess>
      </Container>
    </>
  );
};

export default ConciliateRace;
