import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ResponsiveAppBar from '../../../components/NavBar';
import { GetCompanyConfiguration } from '../../../store/_Entities/CompanyConfiguration';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExpenConfigurationActivate,
  ExpenConfigurationInactivate,
} from '../../../store/_Entities/ExpenConfiguration';
import ModalErrorSuccess from '../../../components/ModalErrorSuccess';

const CompanyParameters = () => {
  const { user, companyConfiguration, expenConfiguration } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const [checked, setChecked] = useState({ daily: true, event: false });
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState({
    show: false,
    text: '',
  });

  const handleChange = (type, value) => {
    if (value === true) {
      dispatch(ExpenConfigurationActivate(type));
      setShowModal((prev) => ({ ...prev, text: 'Email ativado com sucesso.' }));
    } else {
      dispatch(ExpenConfigurationInactivate(type));
      setShowModal((prev) => ({
        ...prev,
        text: 'Email desativado com sucesso.',
      }));
    }
    setSubmitted(true);
  };

  useEffect(() => {
    dispatch(GetCompanyConfiguration(user.user.companyID));
  }, []);

  useEffect(() => {
    if (
      expenConfiguration.success &&
      !expenConfiguration.loading &&
      submitted
    ) {
      dispatch(GetCompanyConfiguration(user.user.companyID));
      setShowModal((prev) => ({ ...prev, show: true }));
      setSubmitted(false);
    }
    if (expenConfiguration.error && submitted) {
      setShowModal({
        show: true,
        text: 'Erro ao ativar/desativar email.',
      });
      setSubmitted(false);
    }
  }, [submitted, expenConfiguration]);

  useEffect(() => {
    if (
      companyConfiguration.success &&
      !companyConfiguration.loading &&
      companyConfiguration.companyConfigurations.length > 0
    ) {
      const getConfigValue = (typeID) => {
        const config = companyConfiguration.companyConfigurations.find(
          (x) => x.companyConfigurationTypeID === typeID
        );
        return config?.value === 'true';
      };

      setChecked({
        daily: getConfigValue(42),
        event: getConfigValue(43),
      });
    }
  }, [companyConfiguration.companyConfigurations]);

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Parâmetros Empresa</h2>
          </Grid>
        </Grid>
        <Box sx={{ border: '1px solid #ddd', padding: 2, borderRadius: 2 }}>
          {expenConfiguration.loading ||
          (expenConfiguration.loading && companyConfiguration.loading) ? (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}
            >
              <CircularProgress size={30} thickness={5} />
            </Box>
          ) : (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.daily}
                    onChange={(e) => handleChange(42, e.target.checked)}
                    name="daily"
                    color="primary"
                  />
                }
                label={
                  <Box>
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Diário:
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ marginLeft: 1 }}
                    >
                      E-mail enviado diariamente (07h00) informando a existência
                      de relatórios pendentes para aprovação.
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.event}
                    onChange={(e) => handleChange(43, e.target.checked)}
                    name="event"
                    color="primary"
                  />
                }
                label={
                  <Box>
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Por evento:
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ marginLeft: 1 }}
                    >
                      E-mail enviado a cada evento, informando a existência de
                      um relatório para aprovar.
                    </Typography>
                  </Box>
                }
              />
            </>
          )}
        </Box>
      </Container>
      <ModalErrorSuccess
        show={showModal.show}
        handleClose={() => setShowModal({ show: false, text: '' })}
        title={'Sucesso!'}
        error={expenConfiguration.error}
      >
        <Typography variant="subtitle1">{showModal.text}</Typography>
      </ModalErrorSuccess>
    </>
  );
};

export default CompanyParameters;
