import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './global.css';

// MUI
import { ThemeProvider } from '@mui/material/styles';
import mainTheme from './assets/theme/index.tsx';
import { StyledEngineProvider } from '@mui/material/styles';

// React-router
import { BrowserRouter } from 'react-router-dom';

// Redux
import { store, persistor } from './store/index.ts';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import SnackbarProvider from './components/SnackbarProvider';
import LoadingProvider from './components/LoadingProvider';

// MSAL
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './store/auth/authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={mainTheme}>
              <App instance={msalInstance} />
              <LoadingProvider />
              <SnackbarProvider />
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
