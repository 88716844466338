import { Container, Grid, Box, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import Header from '../../../components/Header';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetInviteUsers,
  ResendInviteUser,
  DeleteInviteUser,
} from '../../../store/_Entities/InviteUser';
import ModalConfirm from '../../../components/ModalConfirm';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';

const InviteUsers = () => {
  const { company, user, inviteUser } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [inviteUserID, setInviteUserID] = useState(null);
  const [showModal, setShowModal] = useState({ open: false, type: null });
  const [showError, setShowError] = useState(false);

  const handleRequest = async (type) => {
    const userID = user.authData.sub;
    if (type === 'delete') {
      dispatch(DeleteInviteUser(inviteUserID, userID));
    }
    if (type === 'resend') {
      dispatch(ResendInviteUser(inviteUserID, userID));
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      setShowError(false);
      dispatch(GetInviteUsers(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setData(inviteUser.inviteUsers);
    }
  }, [inviteUser.inviteUsers]);

  useEffect(() => {
    if (inviteUser.success) {
      setShowModal((prev) => ({ ...prev, open: false }));
    }
  }, [inviteUser.success]);

  const columns = [
    {
      field: 'inviteUsersID',
      headerName: 'InviteUserID',
      headerAlign: 'center',
      align: 'center',
      minWidth: 280,
      flex: 1,
      hide: true
    },
    {
      field: 'registration',
      headerName: 'Matrícula',
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Data de Criação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.created).toLocaleString('pt-BR'),
    },
    {
      field: 'updated',
      headerName: 'Última modificação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) =>
        new Date(params.row.updated).toLocaleString('pt-BR'),
    },
    {
      field: 'Deletar',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          onClick={() => {
            setInviteUserID(params.row.inviteUsersID);
            setShowModal({ open: true, type: 'delete' });
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      field: 'Reenviar',
      headerName: 'Reenviar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => {
            setInviteUserID(params.row.inviteUsersID);
            setShowModal({ open: true, type: 'resend' });
          }}
        >
          <RefreshIcon />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    inviteUsersID: 'InvateUserID',
    registration: 'Matrícula',
    name: 'Nome',
    costCenter: 'Centro de Custo',
    email: 'E-mail',
    created: 'Data de Criação',
    updated: 'Última modificação',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header
          title="Usuários Convidados"
          addHref={
            selectedCompany
              ? `/adm/usuarios-convidados/add/${selectedCompany}`
              : undefined
          }
          onClick={!selectedCompany ? () => setShowError(true) : undefined}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <HeaderSearchSelectCompany
                label={'Empresas'}
                onChange={setSelectedCompany}
                error={showError}
                defaultValue={selectedCompany}
              />
            </Grid>
          </Grid>
        </Header>
        <Box sx={{ width: '100%', height: '100%' }}>
          <CustomTable
            columns={columns}
            data={data}
            getRowId={(row) => row.inviteUsersID}
            exportHeaders={exportHeaders}
            loading={inviteUser.loading}
          />
        </Box>
        <ModalConfirm
          show={showModal.open}
          handleClose={() => setShowModal((prev) => ({ ...prev, open: false }))}
          title={
            showModal.type === 'delete'
              ? 'Deseja Excluir o Convite?'
              : 'Deseja Reenviar o Convite?'
          }
          submit={() =>
            showModal.type === 'delete'
              ? handleRequest('delete')
              : handleRequest('resend')
          }
          loading={inviteUser.loading}
        >
          <Box>
            {showModal.type === 'delete'
              ? 'O convite para o usuário será excluido.'
              : 'Um convite para o usuário será Reenviado.'}
          </Box>
        </ModalConfirm>
      </Container>
    </>
  );
};

export default InviteUsers;
