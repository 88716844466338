import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReason, IState } from './interfaces/Reason';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  reasons: [],
};

export const slice = createSlice({
  name: 'reason',
  initialState,
  reducers: {
    REASON_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    REASON_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    REASON_RECEIVED: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reasons = action.payload.result;
    },
    REASON_DELETED: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reasons = state.reasons.filter(
        (reason) => parseInt(reason.reasonID) !== action.payload.result.reasonID
      );
    },
    REASON_EDITED: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reasons = state.reasons.map((reason) =>
        reason.reasonID === action.payload.reasonID
          ? action.payload.updatedReason
          : reason
      );
    },
    REASON_CREATED: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reasons = [...state.reasons, action.payload.result];
    },
  },
});

export const {
  REASON_REQUESTED,
  REASON_FAILED,
  REASON_RECEIVED,
  REASON_DELETED,
  REASON_EDITED,
  REASON_CREATED,
} = slice.actions;

export default slice.reducer;

export const GetReasonsByCompanyId = (companyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Reason.ReasonByCompanyID(companyID),
    },
    onAction: {
      onStart: REASON_REQUESTED.type,
      onError: REASON_FAILED.type,
      onSuccess: REASON_RECEIVED.type,
    },
  });
};

export const DeleteReason = (reasonID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.Reason.DeleteReason(reasonID),
    },
    onAction: {
      onStart: REASON_REQUESTED.type,
      onError: REASON_FAILED.type,
      onSuccess: REASON_DELETED.type,
      showToast: true,
    },
  });
};

export const EditReason = (reasonID: number, updatedReason: IReason) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.Reason.EditReason(reasonID),
      data: updatedReason,
    },
    onAction: {
      onStart: REASON_REQUESTED.type,
      onError: REASON_FAILED.type,
      onSuccess: REASON_EDITED.type,
      showToast: true,
    },
  });
};

export const CreateReason = (newReason: any) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Reason.CreateReason,
      data: newReason,
    },
    onAction: {
      onStart: REASON_REQUESTED.type,
      onError: REASON_FAILED.type,
      onSuccess: REASON_CREATED.type,
      showToast: true,
    },
  });
};
