import { createSlice } from '@reduxjs/toolkit';
import { ICurrency, IState } from './interfaces/Currency';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  currencies: [],
};

export const slice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    CURRENCY_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    CURRENCY_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    CURRENCY_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.currencies = action.payload.result;
    },
    CURRENCY_CREATE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.currencies = [...state.currencies, action.payload.result];
    },
    CURRENCY_DELETE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.currencies = state.currencies.filter(
        (currency) => currency.currencyID !== action.payload.result.currencyID
      );
    },
  },
});

export const {
  CURRENCY_REQUESTED,
  CURRENCY_FAILED,
  CURRENCY_SUCCESS,
  CURRENCY_CREATE_SUCCESS,
  CURRENCY_DELETE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetCurrency = (CompanyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Currency.GetCurrency(CompanyID),
    },
    onAction: {
      onStart: CURRENCY_REQUESTED.type,
      onError: CURRENCY_FAILED.type,
      onSuccess: CURRENCY_SUCCESS.type,
    },
  });
};

export const PostCurrency = (data: ICurrency) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Currency.CreateCurrency,
      data,
    },
    onAction: {
      onStart: CURRENCY_REQUESTED.type,
      onError: CURRENCY_FAILED.type,
      onSuccess: CURRENCY_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteCurrency = (currencyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.Currency.DeleteCurrency(currencyID),
    },
    onAction: {
      onStart: CURRENCY_REQUESTED.type,
      onError: CURRENCY_FAILED.type,
      onSuccess: CURRENCY_DELETE_SUCCESS.type,
      showToast: true,
    },
  });
};
