import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ModalWrapper } from '../../Modal';
import ResponsiveAppBar from '../../NavBar';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import alerta from '../../../assets/images/alerta.png';

import { CreateReason, EditReason } from '../../../store/_Entities/Reason';
import ModalConfirm from '../../ModalConfirm';
import ModalErrorSuccess from '../../ModalErrorSuccess';

const ReasonForm = () => {
  const { reasonID } = useParams();
  const { company, reason } = useSelector((state) => state.entities);
  const navigate = useNavigate();

  const reasonEdit =
    reasonID != null
      ? reason.reasons.find((r) => r.reasonID === parseInt(reasonID))
      : null;

  const {
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      companySelected: company.lastCompanyFiltered,
      description: reasonEdit?.description || '',
    },
  });

  const dispatch = useDispatch();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [selectedReason, setSelectedReason] = useState('');

  const onClickSubmit = (event) => {
    event.preventDefault();
    const newReason = {
      companyID: +company.lastCompanyFiltered,
      description: description?.value,
    };

    if (reasonID != null) dispatch(EditReason(reasonID, newReason));
    else dispatch(CreateReason(newReason));

    reset();
    setHasSubmitted(true);
  };

  useEffect(() => {
    if (hasSubmitted && !reason.loading) {
      setShowSuccessModal(true);
    }
  }, [reason, hasSubmitted]);

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container sx={{ mt: 6 }}>
        <form onSubmit={onClickSubmit}>
          <Grid
            container
            spacing={2}
            sx={{
              boxShadow: '0px 0px 4px 0px #00000040',
              borderRadius: '10px',
              padding: 2,
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h6">Novo Motivo</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="description"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <Box>
                    <label htmlFor="description">Descrição</label>
                    <TextField
                      id="description"
                      variant="outlined"
                      {...field}
                      placeholder="Digite..."
                      fullWidth
                      error={Boolean(errors?.description)}
                    />
                    {errors?.description && (
                      <Typography variant="caption" color="error">
                        {errors?.description.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </Grid>
          </Grid>
          <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
            <Button variant="contained" type="submit">
              {reason.loading ? (
                <CircularProgress color={'inherit'} size={25} thickness={5} />
              ) : (
                'Salvar'
              )}
            </Button>
            <Button variant="outlined" onClick={() => setShowCancelModal(true)}>
              Cancelar
            </Button>
          </Box>
        </form>

        <ModalWrapper
          showModal={showCancelModal}
          closeModalAction={() => setShowCancelModal(false)}
          title="Deseja cancelar o cadastro?"
          img={alerta}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box>{selectedReason.description}</Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" href=".">
              Confirmar
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowCancelModal(false)}
            >
              Cancelar
            </Button>
          </Box>
        </ModalWrapper>

        <ModalErrorSuccess
          show={showSuccessModal}
          handleClose={() => {
            navigate('/adm/motivo');
          }}
          title={
            reasonEdit
              ? 'Motivo editado com sucesso.'
              : 'Motivo cadastrado com sucesso.'
          }
        />
      </Container>
    </>
  );
};

export default ReasonForm;
