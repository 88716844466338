import { useState } from 'react';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Box, CircularProgress } from '@mui/material';

export const CustomDataGrid = ({
  rows,
  columns,
  height = 400,
  width,
  props,
  noRowsText,
  dataGridInitialState,
  miniature
}) => {
  const [pageSize, setPageSize] = useState(25);
  const isTiny = rows.length < 7;

  return (
    <Box 
      height={miniature? ('400px') : (isTiny ? height : '800px')} 
      width={width}
      sx={{
        '& .color-recusado': {
        backgroundColor: '#EE7330',
          '&:hover' : {
            backgroundColor: '#D46021',
          },
        
      }}}
      >
      <DataGrid
        initialState={dataGridInitialState}
        // autoHeight={!isTiny}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        columnBuffer={50}
        getRowClassName={(params) => {
          if (params.row.total) {
            return 'total-row';
          }
          return '';
        }}
        components={{
          NoRowsOverlay: () => (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              {noRowsText
                ? 'Nenhuma Linha'
                : 'Selecione filtro para exibir resultados.'}
            </Box>
          ),
        }}
        {...props}
        sx={{
          '& .MuiSvgIcon-root': { color: '#EE7330' },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#D9D9D9',
          },
          '& .MuiDataGrid-main': {
            borderRadius: '5px',
            boxShadow: '0px 4px 4px 0px #00000040',
          },
          '& .MuiDataGrid-iconSeparator': { display: 'none' },
          '.total-row': {
            backgroundColor: '#EE7330',
            color: 'white',
          },
        }}
      />
    </Box>
  );
};

export default CustomDataGrid;
