import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/PaymentType';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  paymentTypes: [],
};

export const slice = createSlice({
  name: 'paymentType',
  initialState,
  reducers: {
    PAYMENT_TYPE_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    PAYMENT_TYPE_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    PAYMENT_TYPE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.paymentTypes = action.payload.result;
    },
  },
});

export const {
  PAYMENT_TYPE_REQUESTED,
  PAYMENT_TYPE_FAILED,
  PAYMENT_TYPE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetPaymentTypes = (CompanyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.PaymentType.GetPaymentType(CompanyID),
    },
    onAction: {
      onStart: PAYMENT_TYPE_REQUESTED.type,
      onError: PAYMENT_TYPE_FAILED.type,
      onSuccess: PAYMENT_TYPE_SUCCESS.type,
    },
  });
};
