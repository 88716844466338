import { Box, Container, Grid } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DatePickerRange from '../../../components/DatePickerRange';
import HeaderSearchSelectMultiple from '../../../components/HeaderSearchSelectMultiple';
import InfoCards from '../../../components/InfoCards';
import ResponsiveAppBar from '../../../components/NavBar';

import { GetEconomyRideReport } from '../../../store/_Entities/EconomyRideReport';
import validateDate from '../../../utils/validateDate';

import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import Footer from '../../../components/Footer';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const EconoTransacional = () => {
  const { user, company, economyRideReport } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();

  const userID = user.authData.sub;

  const [companiesInputValue, setCompaniesInputValue] = useState([]);

  const [data, setData] = useState([]);
  const [reports, setReports] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); // Data de 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const [diagnosticTotal, setDiagnosticTotal] = useState(0.0);
  const [baseDiagnosticTotal, setBaseDiagnosticTotal] = useState(0.0);
  const [savingTotal, setSavingTotal] = useState(0.0);
  const [savingPercentage, setSavingPercentage] = useState(0.0);
  const [estimativeLossTotal, setEstimativeLossTotal] = useState(0.0);
  const [reestimateLossPercentage, setReestimateLossPercentage] = useState(0.0);
  const [differentEstimativeLossTotal, setDifferentEstimativeLossTotal] =
    useState(0.0);
  const [differentEstimativePercentage, setDifferentEstimativePercentage] =
    useState(0.0);
  const [totalEconomyWexp, setTotalEconomyWexp] = useState(0.0);
  const [totalEconomyWexpPercentage, setTotalEconomyWexpPercentage] =
    useState(0.0);

  const [monthlyData, setMonthlyData] = useState([]);

  const fetchReports = () => {
    const startDateFormatted = startDate.toLocaleDateString('pt-BR');
    const endDateFormatted = endDate.toLocaleDateString('pt-BR');

    const request = {
      userID,
      companyID: 0,
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      companies: companiesInputValue.length
        ? companiesInputValue.map((u) => u.companyID)
        : [0],
    };

    dispatch(GetEconomyRideReport(request));
  };

  useEffect(() => {
    if (
      companiesInputValue.length > 0 &&
      validateDate(startDate) &&
      validateDate(endDate)
    ) {
      fetchReports();
    }
  }, [companiesInputValue, startDate, endDate]);

  useEffect(() => {
    if (
      economyRideReport?.economyRideReports &&
      companiesInputValue.length > 0
    ) {
      setData(economyRideReport.economyRideReports);
      if (economyRideReport.economyRideReports?.reportResults) {
        setReports(economyRideReport.economyRideReports.reportResults);
      }
    }
  }, [companiesInputValue, economyRideReport.economyRideReports]);

  useEffect(() => {
    if (economyRideReport?.economyRideReports) {
      setDiagnosticTotal(economyRideReport.economyRideReports.diagnosticTotal);
      setBaseDiagnosticTotal(
        economyRideReport.economyRideReports.baseDiagnosticTotal
      );
      setSavingTotal(economyRideReport.economyRideReports.savingTotal);
      setSavingPercentage(
        economyRideReport.economyRideReports.savingPercentage
      );
      setEstimativeLossTotal(
        economyRideReport.economyRideReports.estimativeLossTotal
      );
      setReestimateLossPercentage(
        economyRideReport.economyRideReports.reestimateLossPercentage
      );
      setDifferentEstimativeLossTotal(
        economyRideReport.economyRideReports.differentEstimativeLossTotal
      );
      setDifferentEstimativePercentage(
        economyRideReport.economyRideReports.differentEstimativePercentage
      );

      setTotalEconomyWexp(
        economyRideReport.economyRideReports.totalEconomyWexp
      );
      setTotalEconomyWexpPercentage(
        economyRideReport.economyRideReports.totalEconomyWexpPercentage
      );

      if (economyRideReport.economyRideReports.monthlyData) {
        setMonthlyData(economyRideReport.economyRideReports.monthlyData);
      }
    }
  }, [economyRideReport.economyRideReports]);

  const cardsData = [
    {
      title: 'Total Corrida Diagnóstico (REF)',
      value: `R$ ${baseDiagnosticTotal || 0}`,
    },
    {
      title: 'Total Corrida Cobrada (CC)',
      value: `R$ ${diagnosticTotal || 0.0} `,
    },
    {
      title: 'Economia vs REF',
      value: `R$ ${savingTotal || 0}`,
      percentual: `${savingPercentage || 0}%`,
    },
    {
      title:
        'Economia Desperdiçada por Uso de Produto Não Recomendado (Recotação)',
      value: `R$ ${estimativeLossTotal || 0}`,
      percentual: `${reestimateLossPercentage || 0}%`,
    },
    {
      title:
        'Economia Desperdiçada por Uso de Produto Não Recomendado (Seleção)',
      value: `R$ ${differentEstimativeLossTotal || 0}`,
      percentual: `${differentEstimativePercentage || 0}%`,
    },
    {
      title: 'Total de Economias WEXP % Economias WEXP',
      value: `R$ ${totalEconomyWexp || 0}`,
      percentual: `${totalEconomyWexpPercentage || 0}%`,
    },
  ];

  // Dados do gráfico
  const lineChartData = useMemo(() => {
    if (!monthlyData.length) return { labels: [], datasets: [] };

    const labels = monthlyData.map(
      (item) => `${String(item.month).padStart(2, '0')}/${item.year}`
    );

    const savingPercentageData = monthlyData.map((item) =>
      item.totalPriceDiagnostic > 0
        ? (item.totalSaving / item.totalPriceDiagnostic) * 100
        : 0
    );

    const reestimateLossPercentageData = monthlyData.map((item) =>
      item.totalPriceDiagnostic > 0
        ? (-item.totalEstimativeLoss / item.totalPriceDiagnostic) * 100
        : 0
    );

    const differentEstimativePercentageData = monthlyData.map((item) =>
      item.totalPriceDiagnostic > 0
        ? (-item.totalDifferentEstimativeLoss / item.totalPriceDiagnostic) * 100
        : 0
    );

    const totalEconomyWexpPercentageData = monthlyData.map((item) =>
      item.totalPriceDiagnostic > 0
        ? ((item.totalSaving + item.totalEstimativeLoss + item.totalDifferentEstimativeLoss) /
            item.totalPriceDiagnostic) *
          100
        : 0
    );

    return {
      labels,
      datasets: [
        {
          label: 'Economia vs REF',
          data: savingPercentageData,
          borderColor: '#36A2EB',
          backgroundColor: 'rgba(54,162,235,0.5)',
          fill: false,
          tension: 0.1,
        },
        {
          label:
            'Economia Desperdiçada por Uso de Produto Não Recomendado (Recotação)',
          data: reestimateLossPercentageData,
          borderColor: '#FF6384',
          backgroundColor: 'rgba(255,99,132,0.5)',
          fill: false,
          tension: 0.1,
        },
        {
          label:
            'Economia Desperdiçada por Uso de Produto Não Recomendado (Seleção)',
          data: differentEstimativePercentageData,
          borderColor: '#FFCE56',
          backgroundColor: 'rgba(255,206,86,0.5)',
          fill: false,
          tension: 0.1,
        },
        {
          label: 'Total de Economias WEXP % Economias WEXP',
          data: totalEconomyWexpPercentageData,
          borderColor: '#4BC0C0',
          backgroundColor: 'rgba(75,192,192,0.5)',
          fill: false,
          tension: 0.1,
        },
      ],
    };
  }, [monthlyData]);

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value.toFixed(2) + '%';
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(2) + '%';
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Economia por Corrida</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <Box sx={{ display: 'flex', gap: 5 }}>
                <HeaderSearchSelectMultiple
                  label="Empresas"
                  onChange={(e) => {
                    setCompaniesInputValue(e.target.value);
                  }}
                  inputValue={companiesInputValue}
                  menuItems={company?.companies
                    ?.filter((company) => company.active == 1)
                    .sort((companyA, companyB) =>
                      companyA.giveName.localeCompare(companyB.giveName)
                    )
                    .map((company) => {
                      return { label: company.giveName, value: company };
                    })}
                  menuItemsSelectedChips={companiesInputValue?.map(
                    (company) => {
                      return { label: company.giveName, value: company };
                    }
                  )}
                />
                <DatePickerRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={{ startDate: setStartDate, endDate: setEndDate }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          mb={6}
          sx={{ display: `flex`, justifyContent: 'space-between' }}
        ></Box>
        <InfoCards cardsData={cardsData} />

        <Box mt={4}>
          <h3>Gráfico de Economia Mensal (%)</h3>
          <div style={{ width: '100%', height: '400px' }}>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        </Box>
        <Footer />
      </Container>
    </>
  );
};

export default EconoTransacional;
