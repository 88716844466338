import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';
import { IState } from './interfaces/SavingsMemoryCalculationReport';

const initialState : IState = {
    loading: false,
    error: false,
    success: false,
    savingsMemoryCalculationReports: [],
    selectedRideID: 0,
    detailSavingsResultReport: null,
};

export const slice = createSlice({
    name: 'savingsMemoryCalculationReport',
    initialState,
    reducers: {
        REPORT_REQUESTED: (state,action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        REPORT_FAILED: (state,action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            console.log('REPORT_FAILED');
            console.log(action);
        },
        REPORT_ESTIMATIVE_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            console.log('REPORT_ESTIMATIVE_SUCCESS');
            console.log(action);
            state.savingsMemoryCalculationReports = action.payload.result;
        },
        DETAIL_REPORT_REQUESTED: (state, action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        DETAIL_REPORT_FAILED: (state,action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            console.log('DETAIL_REPORT_FAILED');
            console.log(action);
        },
        DETAIL_REPORT_ESTIMATIVE_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            console.log('DETAIL_REPORT_ESTIMATIVE_SUCCESS');
            console.log(action);
            state.detailSavingsResultReport = action.payload.result;
        },
    }
})

export const { REPORT_REQUESTED, REPORT_FAILED, REPORT_ESTIMATIVE_SUCCESS, 
                DETAIL_REPORT_ESTIMATIVE_SUCCESS, DETAIL_REPORT_FAILED, DETAIL_REPORT_REQUESTED } = slice.actions;

export default slice.reducer;

export const GetListEconomySavings = (request: any) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'POST',
            url: Endpoints.Saving.GetListEconomySavings,
            data: request
        },
        onAction: {
            onStart: REPORT_REQUESTED.type,
            onError: REPORT_FAILED.type,
            onSuccess: REPORT_ESTIMATIVE_SUCCESS.type,
        }
    })
}

export const GetDetailEconomySavings = (rideID: any) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.Saving.GetDetailEconomySavings(rideID),
        },
        onAction: {
            onStart: DETAIL_REPORT_REQUESTED.type,
            onError: DETAIL_REPORT_FAILED.type,
            onSuccess: DETAIL_REPORT_ESTIMATIVE_SUCCESS.type,
        }
    })
}

