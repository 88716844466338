import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useForm } from 'react-hook-form';

import ColorButton from '../components/Buttons';
import logo from '../assets/images/logow.png';
import { Box, Button, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { loginUser } from '../store/_Entities/User.ts';
import { useNavigate } from 'react-router-dom';
import { ModalWrapper } from '../components/Modal';
import { loginUserSso } from '../store/_Entities/User.ts';

import HslLogo from '../assets/images/hsl-logo.png';

//MSAL
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../store/auth/authConfig';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { user } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [firstLogin, setFirstLogin] = useState(true);
  const [showSsoModal, setShowSsoModal] = useState(false);

  const onSubmit = async (data) => {
    dispatch(loginUser(data));
    setFirstLogin(false);
  };

  const handleLoginRedirect = async () => {
    const account = instance.getActiveAccount();
    if (account) {
      dispatch(loginUserSso(account.username, account.localAccountId));
      return;
    }
    instance
      .loginRedirect(loginRequest)
      .catch((error) => console.log('Login error:', error));
  };

  useEffect(() => {
    if (user.isLoggedIn) {
      navigate('/home');
    }
  }, [user.isLoggedIn]);

  return (
    <div className="login">
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid
          xm={12}
          maxWidth={600}
          sx={{
            background: 'white',
            padding: '25px 100px',
            borderRadius: '10px',
          }}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              maxWidth: '400px',
            }}
          >
            <img className="img-login" src={logo} alt="" />
            {instance.getActiveAccount() && user.loading ? (
              <CircularProgress
                sx={{
                  marginTop: 7,
                }}
                color={'primary'}
                size={35}
                thickness={5}
              />
            ) : (
              <>
                <TextField
                  label="Email"
                  className="login-input"
                  variant="standard"
                  fullWidth
                  {...register('email')}
                />
                <TextField
                  label="Senha"
                  className="login-input"
                  type="password"
                  variant="standard"
                  {...register('password')}
                  fullWidth
                  margin="normal"
                />
                {user.error && !firstLogin && (
                  <p style={{ color: 'red' }}>Usuário e/ou senha inválidos</p>
                )}

                <Link
                  className="link-password-reset"
                  href="https://auth.wexp.com.br/account/ForgotPassword"
                >
                  Esqueceu sua senha?
                </Link>

                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={2}
                  alignItems={'center'}
                >
                  <ColorButton
                    text={
                      user.loading ? (
                        <CircularProgress
                          color={'inherit'}
                          size={25}
                          thickness={5}
                        />
                      ) : (
                        'ENTRAR'
                      )
                    }
                    type="submit"
                  />
                  <Button
                    variant="contained"
                    onClick={() => setShowSsoModal(true)}
                  >
                    Entrar com SSO
                  </Button>
                </Box>
              </>
            )}
          </form>
        </Grid>
      </Grid>
      <ModalWrapper
        showModal={showSsoModal}
        closeModalAction={() => setShowSsoModal(false)}
        title="
        Selecione uma opção de login"
      >
        <Box display={'flex'} flexDirection={'column'} gap={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleLoginRedirect}
          >
            <img
              style={{
                width: '150px',
              }}
              alt="Logo Hospital Sírio-Libanês"
              src={HslLogo}
            />
          </Button>
        </Box>
      </ModalWrapper>
    </div>
  );
};

export default Login;
