import { useEffect, useRef, useState, useCallback } from 'react';
import { Alert, Autocomplete, Box, Snackbar, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useFormContext, Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';

const HereMap = ({ formType, editMode }) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const router = useRef(null);

  const [originCoords, setOriginCoords] = useState(null);
  const [destinationCoords, setDestinationCoords] = useState(null);
  const [originSuggestions, setOriginSuggestions] = useState([]);
  const [destinationSuggestions, setDestinationSuggestions] = useState([]);

  const [error, setError] = useState({
    show: false,
    message: '',
  });

  // Initialize map and routing service
  useEffect(() => {
    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAP_KEY,
      useCIT: false,
      useHTTPS: true,
    });

    const defaultLayers = platform.createDefaultLayers();

    mapInstance.current = new H.Map(
      mapRef.current,
      defaultLayers.vector.normal.map,
      {
        center: {
          lat: originCoords?.lat || -18.0581231,
          lng: originCoords?.lng || -39.4837541,
        },
        zoom: originCoords ? 13 : 4,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance.current));

    router.current = platform.getRoutingService(null, 8);

    window.addEventListener('resize', () =>
      mapInstance.current.getViewPort().resize()
    );

    return () => {
      if (mapInstance.current) {
        mapInstance.current = null;
      }
      window.removeEventListener('resize', () =>
        mapInstance.current.getViewPort().resize()
      );
    };
  }, []);

  // Route calculation effect
  useEffect(() => {
    if (router.current && originCoords && destinationCoords) {
      mapInstance.current.removeObjects(mapInstance.current.getObjects());
      calculateRoute();
    } else if (router.current && originCoords && !destinationCoords) {
      const marker = new H.map.Marker(originCoords);

      mapInstance.current.addObject(marker);
    }
  }, [originCoords, destinationCoords]);

  // Route calculation function
  const calculateRoute = useCallback(() => {
    if (originCoords && destinationCoords) {
      const routingParameters = {
        routingMode: 'fast',
        transportMode: 'car',
        origin: `${originCoords.lat},${originCoords.lng}`,
        destination: `${destinationCoords.lat},${destinationCoords.lng}`,
        return: ['polyline', 'summary'],
      };
      router.current.calculateRoute(routingParameters, onResult, onError);
    }
  }, [originCoords, destinationCoords]);

  // Route calculation success handler
  const onResult = useCallback(
    (result) => {
      if (result.routes.length) {
        const route = result.routes[0];
        const totalDistance = route.sections.reduce(
          (total, section) => total + section.summary.length,
          0
        );

        // setValue('distance', (totalDistance / 1000).toFixed(2));
        setValue('distance', totalDistance);

        route.sections.forEach((section) => {
          const linestring = H.geo.LineString.fromFlexiblePolyline(
            section.polyline
          );
          const routeLine = new H.map.Polyline(linestring, {
            style: { strokeColor: 'blue', lineWidth: 6 },
          });
          const startMarker = new H.map.Marker(
            section.departure.place.location
          );
          const endMarker = new H.map.Marker(section.arrival.place.location);

          mapInstance.current.addObjects([routeLine, startMarker, endMarker]);
          mapInstance.current
            .getViewModel()
            .setLookAtData({ bounds: routeLine.getBoundingBox() });
        });
      }
    },
    [setValue]
  );

  const onError = (error) => {
    setError({ show: true, message: 'Erro ao calcular rota' });
    console.error('Error calculating route:', error.message);
  };

  // Fetch coordinates based on address
  const fetchCoordinates = useCallback(async (address) => {
    const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(
      address
    )}&apiKey=${process.env.REACT_APP_HERE_MAP_KEY}&country=BR`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.items && data.items.length > 0) {
        return data.items[0].position;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      setError({ show: true, message: 'Erro ao buscar coordenadas' });
      return null;
    }
  }, []);

  // Fetch autocomplete suggestions
  const fetchSuggestions = useCallback(async (query) => {
    if (!query) return [];

    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAP_KEY,
      useCIT: false,
      useHTTPS: true,
    });

    const service = platform.getSearchService();

    try {
      return new Promise((resolve, reject) => {
        service.autocomplete(
          { q: query, country: 'BR', maxresults: 5 },
          (result) => {
            resolve(result.items || []);
          },
          (error) => {
            reject(error);
          }
        );
      });
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setError({ show: true, message: 'Erro ao buscar sugestões' });
      return [];
    }
  }, []);

  // Handle origin input change and debounce
  const handleOriginChange = async (event, value) => {
    if (value) {
      const suggestions = await fetchSuggestions(value);
      setOriginSuggestions(suggestions);
    } else {
      setOriginSuggestions([]);
    }
  };

  const debouncedOriginChange = useRef(
    debounce(handleOriginChange, 500)
  ).current;

  // Handle destination input change and debounce
  const handleDestinationChange = async (event, value) => {
    if (value) {
      const suggestions = await fetchSuggestions(value);
      setDestinationSuggestions(suggestions);
    } else {
      setDestinationSuggestions([]);
    }
  };

  const debouncedDestinationChange = useRef(
    debounce(handleDestinationChange, 500)
  ).current;

  // Handle origin selection
  const handleOriginSelection = async (event, newValue) => {
    if (newValue) {
      const coords = await fetchCoordinates(newValue.address.label);
      setValue('origin', { ...newValue.address, coords });

      if (coords) {
        setOriginCoords(coords);
      }
      setOriginSuggestions([]);
    } else {
      setOriginCoords(null);
    }
  };

  // Handle destination selection
  const handleDestinationSelection = async (event, newValue) => {
    if (newValue) {
      const coords = await fetchCoordinates(newValue.address.label);
      setValue('destination', { ...newValue.address, coords });

      if (coords) {
        setDestinationCoords(coords);
      }
      setDestinationSuggestions([]);
    } else {
      setDestinationCoords(null);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <div
        ref={mapRef}
        style={{ width: '100%', height: '500px', position: 'relative' }}
      />
      <Box>
        <Controller
          control={control}
          name="origin"
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) =>
            editMode ? (
              <TextField
                disabled={true}
                id={field.name}
                variant="outlined"
                {...field}
                value={field.value?.address || ''}
                fullWidth
                error={Boolean(errors)}
                defaultValue={field.value?.label}
                InputProps={{
                  style: {
                    backgroundColor: editMode ? '#f0f0f0' : 'inherit',
                  },
                }}
              />
            ) : (
              <Autocomplete
                options={originSuggestions}
                getOptionLabel={(option) => option.address.label || ''}
                onChange={handleOriginSelection}
                onInputChange={debouncedOriginChange}
                renderInput={(params) => (
                  <TextField {...params} label="Origem" variant="outlined" />
                )}
                freeSolo
                filterOptions={(options, { inputValue }) => {
                  const filtered = options.filter((option) =>
                    option.address.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  );
                  return filtered.length === 0 ? options : filtered;
                }}
                isOptionEqualToValue={(option, value) =>
                  option.address.label === value?.address?.label
                }
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-disabled': {
                      backgroundColor: '#f0f0f0',
                    },
                  },
                }}
              />
            )
          }
        />
        {errors.origin && (
          <Typography variant="caption" color="error">
            Campo obrigatório
          </Typography>
        )}
      </Box>
      {formType === 'km-manual' && (
        <Box>
          <Controller
            control={control}
            name="destination"
            rules={{ required: 'Campo obrigatório' }}
            render={({ field }) =>
              editMode ? (
                <TextField
                  disabled={true}
                  id={field.name}
                  variant="outlined"
                  {...field}
                  value={field.value?.address || ''}
                  fullWidth
                  error={Boolean(errors)}
                  defaultValue={field.value?.label}
                  InputProps={{
                    style: {
                      backgroundColor: editMode ? '#f0f0f0' : 'inherit',
                    },
                  }}
                />
              ) : (
                <Autocomplete
                  options={destinationSuggestions}
                  getOptionLabel={(option) => option.address.label || ''}
                  onChange={handleDestinationSelection}
                  onInputChange={debouncedDestinationChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Destino" variant="outlined" />
                  )}
                  freeSolo
                  filterOptions={(options, { inputValue }) => {
                    const filtered = options.filter((option) =>
                      option.address.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    );
                    return filtered.length === 0 ? options : filtered;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.address.label === value?.address?.label
                  }
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-disabled': {
                        backgroundColor: '#f0f0f0',
                      },
                    },
                  }}
                />
              )
            }
          />
          {errors.destination && (
            <Typography variant="caption" color="error">
              Campo obrigatório
            </Typography>
          )}
        </Box>
      )}
      <Snackbar
        open={error.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setError({ show: false })}>
          {error.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default HereMap;
