import { createSlice } from '@reduxjs/toolkit';
import {
  IState,
  IUserConfigurationRequest,
} from './interfaces/UserConfiguration';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  userConfigurations: [],
};

export const slice = createSlice({
  name: 'userConfiguration',
  initialState,
  reducers: {
    USER_CONFIGURATION_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    USER_CONFIGURATION_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    USER_CONFIGURATION_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.userConfigurations = action.payload.result;
    },
    ADD_USER_CONFIGURATION_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
  },
});

export const {
  USER_CONFIGURATION_REQUESTED,
  USER_CONFIGURATION_FAILED,
  USER_CONFIGURATION_SUCCESS,
  ADD_USER_CONFIGURATION_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetUserConfigurations = (UserID: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.UserConfiguration.GetUserConfiguration(UserID),
    },
    onAction: {
      onStart: USER_CONFIGURATION_REQUESTED.type,
      onError: USER_CONFIGURATION_FAILED.type,
      onSuccess: USER_CONFIGURATION_SUCCESS.type,
    },
  });
};

export const AddUserConfiguration = (
  UserID: string,
  data: IUserConfigurationRequest
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.UserConfiguration.AddUserConfiguration(UserID),
      data,
    },
    onAction: {
      onSuccess: ADD_USER_CONFIGURATION_SUCCESS.type,
    },
  });
};
