import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/ExpenseEstimative';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  estimative: [],
};

export const slice = createSlice({
  name: 'expenseEstimative',
  initialState,
  reducers: {
    EXPENSE_ESTIMATIVE_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    EXPENSE_ESTIMATIVE_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    EXPENSE_ESTIMATIVE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.estimative = action.payload.result;
    },
  },
});

export const {
  EXPENSE_ESTIMATIVE_REQUESTED,
  EXPENSE_ESTIMATIVE_FAILED,
  EXPENSE_ESTIMATIVE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetExpenseEstimative = (expenseID: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ExpenseEstimative.GetExpenseEstimative(expenseID),
    },
    onAction: {
      onStart: EXPENSE_ESTIMATIVE_REQUESTED.type,
      onError: EXPENSE_ESTIMATIVE_FAILED.type,
      onSuccess: EXPENSE_ESTIMATIVE_SUCCESS.type,
    },
  });
};
