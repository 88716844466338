// Redux
import { combineReducers } from 'redux';

// Slices
import UserReducer from './User';
import CompanyReducer from './Company';
import SystemAdminReducer from './SystemAdmin';
import ReasonReducer from './Reason';
import CostCenterReducer from './CostCenter';
import InviteUserReducer from './InviteUser';
import CurrencyReducer from './Currency';
import RideEstimativeReportReducer from './RideEstimativeReport';
import PolicyReducer from './Policy';
import ProjectReducer from './Project';
import BatchingReducer from './Batching';
import IntegrationLogReducer from './IntegrationLog';
import DriverUserCategoryReducer from './DriverUserCategory';
import UserConfigurationReducer from './UserConfiguration';
import ExpenseTypeReducer from './ExpenseType';
import UnreconciledReport from './UnreconciledReport';
import Provider from './Provider';
import CompletedRacesReport from './CompletedRacesReport';
import ProjectedRacesReport from './ProjectedRacesReport';
import SourceActiveUserLogReducer from './SourceActiveUserLog';
import EconomyRideReport from './EconomyRideReport';
import dashboardVolumeReducer from './VolumeDashboard';
import RideFeeEconomyReport from './RideFeeEconomyReport';
import NonConformityReport from './NonConformityReport';
import WarningType from './WarningType';
import AdvancedReducer from './Advanced';
import ExpensesReportReducer from './ExpensesReport';
import ExpensesReducer from './Expenses';
import PaymentTypeReducer from './PaymentType';
import CompanyConfigurationReducer from './CompanyConfiguration';
import ApprovalReducer from './Approval';
import FinancialAdmReducer from './FinancialAdm';
import SavingsMemoryCalculationReport from './SavingsMemoryCalculationReport';
import PendingAccountabilityReport from './PendingAccountabilityReport';
import ReceiptReducer from './Receipt';
import ExpenseEstimativeReducer from './ExpenseEstimative';
import ListExpensesReducer from './ListExpenses';
import ApprovalFlowReducer from './ApprovalFlow';
import ExpenConfigurationReducer from './ExpenConfiguration';

export default combineReducers({
  user: UserReducer,
  company: CompanyReducer,
  systemAdmin: SystemAdminReducer,
  reason: ReasonReducer,
  costCenter: CostCenterReducer,
  inviteUser: InviteUserReducer,
  currency: CurrencyReducer,
  policy: PolicyReducer,
  rideEstimativeReport: RideEstimativeReportReducer,
  project: ProjectReducer,
  batching: BatchingReducer,
  integrationLog: IntegrationLogReducer,
  driverUserCategory: DriverUserCategoryReducer,
  userConfiguration: UserConfigurationReducer,
  expenseType: ExpenseTypeReducer,
  unreconciledReport: UnreconciledReport,
  provider: Provider,
  completedRacesReport: CompletedRacesReport,
  projectedRacesReport: ProjectedRacesReport,
  sourceActiveUserLog: SourceActiveUserLogReducer,
  economyRideReport: EconomyRideReport,
  dashboardVolume: dashboardVolumeReducer,
  rideFeeEconomyReport: RideFeeEconomyReport,
  nonConformityReport: NonConformityReport,
  warningType: WarningType,
  advanced: AdvancedReducer,
  expensesReport: ExpensesReportReducer,
  expenses: ExpensesReducer,
  paymentType: PaymentTypeReducer,
  companyConfiguration: CompanyConfigurationReducer,
  approval: ApprovalReducer,
  financialAdm: FinancialAdmReducer,
  savingsMemoryCalculationReport: SavingsMemoryCalculationReport,
  pendingAccountabilityReport: PendingAccountabilityReport,
  receipt: ReceiptReducer,
  expenseEstimative: ExpenseEstimativeReducer,
  listExpenses: ListExpensesReducer,
  approvalFlow: ApprovalFlowReducer,
  expenConfiguration: ExpenConfigurationReducer,
});
