import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Button,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import HeaderSelect from '../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetCostCenters } from '../../../store/_Entities/CostCenter';
import { ReportProblem } from '@mui/icons-material';
import { ModalWrapper } from '../../../components/Modal';
import CustomDataGrid from '../../../components/grids/components/CustomDataGrid';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';
import { GetActiveUsers } from '../../../store/_Entities/User';
import {
  GetApprovalFlowFile,
  GetApprovalFlows,
  APPROVAL_FLOW_CLEAR_DOWNLOAD_FILE,
  ApprovalFlowAddSingleConfiguration,
  GetApprovalFlowUsers,
  PostApprovalFlowUsers,
  DeleteApprovalFlowUsers,
  DeleteApprovalFlow,
} from '../../../store/_Entities/ApprovalFlow';

import ModalErrorSuccess from '../../../components/ModalErrorSuccess';
import { GetCompanyConfiguration } from '../../../store/_Entities/CompanyConfiguration';
import ModalConfirm from '../../../components/ModalConfirm';

const ApprovalFlow = () => {
  const { user, company, costCenter, approvalFlow, companyConfiguration } =
    useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [blockButton, setBlockButton] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [costCenters, setCostCenters] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [selectedType, setSelectedType] = useState(0);
  const [usersModal, setUsersModal] = useState({
    show: false,
    showDelete: false,
    deleteUser: null,
    submitted: false,
    fetch: false,
  });
  const [selectedApprovalFlow, setSelectedApprovalFlow] = useState(null);
  const [showBlockModal, setShowBlockModal] = useState({
    show: false,
    submitted: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    value: null,
  });

  // Modal Usuários vinculados
  const [usersUser, setUsersUser] = useState([]);
  const [usersSelectedCostCenter, setUsersSelectedCostCenter] = useState(null);
  const [usersSelectedUser, setUsersSelectedUser] = useState(null);

  const [showNoFileModal, setShowNoFileModal] = useState(false);
  const [hasFetchedFile, setHasFetchedFile] = useState(false);

  const handleFileDownload = () => {
    if (data.length === 0) {
      setShowNoFileModal(true);
    } else {
      dispatch(GetApprovalFlowFile(selectedCompany));
      setHasFetchedFile(true);
    }
  };

  const handleBlockSendReport = () => {
    const req = {
      typeID: 48,
      companyID: selectedCompany,
      sourceID: 7,
    };
    if (blockButton === 'Bloquear') {
      req.description = 'true';
    } else {
      req.description = 'false';
    }
    dispatch(ApprovalFlowAddSingleConfiguration(req));
    setShowBlockModal((prev) => ({ ...prev, submitted: true }));
  };

  const handleDelete = () => {
    dispatch(DeleteApprovalFlow(showDeleteModal.value));
    setShowDeleteModal({ show: false, value: null });
  };

  const handleAddUser = () => {
    if (usersSelectedUser && usersSelectedCostCenter) {
      const req = {
        approvalFlowID: selectedApprovalFlow,
        companyID: selectedCompany,
        costCenterID: usersSelectedCostCenter,
        userID: usersSelectedUser,
      };
      dispatch(PostApprovalFlowUsers(req));
      setUsersModal((prev) => ({ ...prev, submitted: true }));
    }
  };

  const handleDeleteUser = () => {
    dispatch(DeleteApprovalFlowUsers(usersModal.deleteUser));
    setUsersModal((prev) => ({
      ...prev,
      submitted: true,
      showDelete: false,
      deleteUser: null,
    }));
  };

  useEffect(() => {
    if (approvalFlow.file && hasFetchedFile) {
      const file = approvalFlow.file;

      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'FluxoAprovacao');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      dispatch(APPROVAL_FLOW_CLEAR_DOWNLOAD_FILE());
    }
  }, [approvalFlow.file, hasFetchedFile]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(GetCostCenters(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
      dispatch(GetActiveUsers(selectedCompany));
    } else {
      setCostCenters([]);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany && costCenter.costCenters) {
      setCostCenters(costCenter.costCenters);
    }
  }, [costCenter.costCenters]);

  useEffect(() => {
    if (selectedCompany && selectedCostCenter && user.users) {
      setUsers(
        user.users.filter((user) => user.costCenterID === selectedCostCenter) ||
          []
      );
    }
  }, [selectedCostCenter]);

  useEffect(() => {
    if (usersSelectedCostCenter) {
      setUsersUser(
        user.users.filter(
          (user) => user.costCenterID === usersSelectedCostCenter
        ) || []
      );
    }
  }, [usersSelectedCostCenter]);

  useEffect(() => {
    if (selectedCompany) {
      const approvers = {
        approver: selectedType === 2 ? true : false,
        approvalFlowUser: selectedType === 1 ? true : false,
      };
      dispatch(
        GetApprovalFlows(
          selectedCompany,
          selectedUser,
          approvers.approver,
          approvers.approvalFlowUser,
          selectedCostCenter
        )
      );
    }
  }, [selectedCompany, selectedCostCenter, selectedUser, selectedType]);

  useEffect(() => {
    if (approvalFlow.approvalFlows && approvalFlow.success) {
      setData(approvalFlow.approvalFlows);
    }
  }, [approvalFlow.approvalFlows]);

  useEffect(() => {
    if (selectedCompany) dispatch(GetCompanyConfiguration(selectedCompany));
  }, [selectedCompany]);
  useEffect(() => {
    if (
      companyConfiguration.companyConfigurations &&
      companyConfiguration.success
    ) {
      const checkConfig = companyConfiguration?.companyConfigurations.findLast(
        (config) => config.companyConfigurationTypeID === 48
      );
      if (checkConfig && checkConfig.value === 'true') {
        setBlockButton('Desbloquear');
      } else {
        setBlockButton('Bloquear');
      }
    }
  }, [companyConfiguration.companyConfigurations]);

  useEffect(() => {
    if (!approvalFlow.loading && showBlockModal.submitted) {
      dispatch(GetCompanyConfiguration(selectedCompany));
      setShowBlockModal({
        show: false,
        submitted: false,
      });
    }
  }, [showBlockModal, approvalFlow]);

  useEffect(() => {
    if (usersModal.show && selectedApprovalFlow) {
      if (
        usersModal.submitted &&
        approvalFlow.success &&
        !approvalFlow.loading
      ) {
        dispatch(GetApprovalFlowUsers(selectedApprovalFlow));
        setUsersModal((prev) => ({ ...prev, submitted: false }));
      } else if (usersModal.fetch) {
        dispatch(GetApprovalFlowUsers(selectedApprovalFlow));
        setUsersModal((prev) => ({ ...prev, fetch: false }));
      }
    }
  }, [usersModal, approvalFlow]);

  const columns = [
    {
      field: 'stepApproverRemoved',
      headerName: 'Alerta',
      headerAlign: 'center',
      align: 'center',
      minWidth: 75,
      flex: 1,
      renderCell: (params) => {
        return params.row.stepApproverRemoved && <ReportProblem />;
      },
    },
    {
      field: 'approvalFlowID',
      headerName: 'Código',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'usuariosVinculados',
      headerName: 'Usuários Vinculados',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => {
            setSelectedApprovalFlow(params.row.approvalFlowID);
            setUsersModal({
              show: true,
              fetch: true,
            });
          }}
        >
          <GroupIcon />
        </IconButton>
      ),
    },
    {
      field: 'editar',
      headerName: 'Editar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() =>
            navigate(`${selectedCompany}/edit/${params.row.approvalFlowID}`)
          }
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'deletar',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          onClick={() =>
            setShowDeleteModal({ show: true, value: params.row.approvalFlowID })
          }
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const columnsUsers = [
    {
      field: 'approvalFlowUsersID',
      headerName: 'ID',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
    },
    {
      field: 'actions',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <IconButton
          color="secondary"
          onClick={() =>
            setUsersModal((prev) => ({
              ...prev,
              showDelete: true,
              deleteUser: params.row.approvalFlowUsersID,
            }))
          }
        >
          <DeleteIcon />
        </IconButton>
      ),
      minWidth: 100,
    },
  ];

  const exportHeaders = {
    codigo: 'Código',
    descricao: 'Descrição',
    usuariosVinculados: 'Usuários Vinculados',
    editar: 'Editar',
    deletar: 'Deletar',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                marginBottom: '10px',
                borderBottom: '1px solid black',
                paddingBottom: 2,
              }}
            >
              <h2>Fluxo de Aprovação</h2>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  flexWrap: 'wrap',
                }}
              >
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  <Box display={'flex'}>
                    <HeaderSearchSelectCompany
                      label="Empresas"
                      onChange={setSelectedCompany}
                      defaultValue={selectedCompany}
                    />
                    <HeaderSelect
                      label="Centro de Custo"
                      onChange={(event) => {
                        setSelectedCostCenter(event.target.value);
                      }}
                      menuItems={costCenters.map((costCenter) => {
                        return {
                          label: costCenter.description,
                          value: costCenter.costCenterID,
                        };
                      })}
                      defaultValue={undefined}
                    />
                    <HeaderSelect
                      label="Usuários"
                      onChange={(event) => {
                        setSelectedUser(event.target.value);
                      }}
                      menuItems={[
                        ...users.map((user) => {
                          return { label: user.name, value: user.userID };
                        }),
                      ]}
                      defaultValue={undefined}
                    />
                    <HeaderSelect
                      label="Tipo"
                      onChange={(event) => {
                        setSelectedType(event.target.value);
                      }}
                      menuItems={[
                        { label: '', value: 0 },
                        { label: 'Usuário vinculado', value: 1 },
                        { label: 'Aprovador', value: 2 },
                      ]}
                      defaultValue={undefined}
                    />
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection="column"
                  gap={2}
                  sx={{ mt: { xs: 2, md: 0 } }}
                >
                  <Button
                    variant="contained"
                    onClick={handleFileDownload}
                    disabled={!selectedCompany}
                  >
                    <DownloadIcon />
                    Download Arquivo
                  </Button>
                  <Button
                    variant="contained"
                    href={
                      selectedCompany
                        ? `/km/fluxo-de-aprovacao/${selectedCompany}/add`
                        : undefined
                    }
                    disabled={!selectedCompany}
                  >
                    <AddIcon />
                    Adicionar
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      setShowBlockModal((prev) => ({ ...prev, show: true }))
                    }
                    disabled={!selectedCompany}
                  >
                    <BlockIcon />
                    {`${blockButton} Envio de Relatório`}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.approvalFlowID}
          exportHeaders={exportHeaders}
          loading={
            approvalFlow.loading &&
            !showBlockModal.show &&
            !usersModal.show &&
            !showDeleteModal.show
          }
        />
        <ModalWrapper
          title={'Usuários Vinculados'}
          showModal={usersModal.show}
          closeModalAction={() => setUsersModal({ show: false })}
          maxWidth={800}
        >
          {approvalFlow.loading && !usersModal.showDelete ? (
            <CircularProgress />
          ) : (
            <>
              <Box
                display={'flex'}
                justifyContent={{ xs: 'center', md: 'space-evenly' }}
                flexDirection={{ xs: 'column', sm: 'row' }}
                gap={4}
              >
                <HeaderSelect
                  label="Centro de custo"
                  onChange={(event) => {
                    setUsersSelectedCostCenter(event.target.value);
                  }}
                  menuItems={costCenters.map((costCenter) => {
                    return {
                      label: costCenter.description,
                      value: costCenter.costCenterID,
                    };
                  })}
                  defaultValue={undefined}
                />
                <HeaderSelect
                  label="Usuários"
                  onChange={(event) => {
                    setUsersSelectedUser(event.target.value);
                  }}
                  menuItems={[
                    ...(usersUser
                      ? usersUser.map((user) => ({
                          label: user.name,
                          value: user.userID,
                        }))
                      : []),
                    { label: 'Padrão para o Centro de Custo', value: '0' },
                  ]}
                  defaultValue={undefined}
                />
                <Button variant="contained" onClick={handleAddUser}>
                  <AddIcon />
                  Adicionar
                </Button>
              </Box>
              <CustomDataGrid
                rows={approvalFlow.users}
                columns={columnsUsers}
                props={{
                  getRowId: (row) => row.approvalFlowUsersID,
                  disableSelectionOnClick: true,
                }}
                width={600}
                noRowsText
              />
            </>
          )}
        </ModalWrapper>
        <ModalErrorSuccess
          show={showNoFileModal}
          handleClose={() => setShowNoFileModal(false)}
          title="Download"
          error={true}
        >
          <p>Empresa Não Possui Fluxo de Aprovação</p>
        </ModalErrorSuccess>
        <ModalConfirm
          show={
            showBlockModal.show || usersModal.showDelete || showDeleteModal.show
          }
          handleClose={() =>
            showBlockModal.show
              ? setShowBlockModal((prev) => ({ ...prev, show: false }))
              : showDeleteModal.show
              ? setShowDeleteModal((prev) => ({ ...prev, show: false }))
              : setUsersModal((prev) => ({ ...prev, show: false }))
          }
          title={
            showBlockModal.show
              ? `Deseja ${blockButton} Envio de Relatório?`
              : showDeleteModal.show
              ? 'Deseja Excluir o Fluxo de Aprovação?'
              : 'Deseja Desvincular Usuário do Fluxo de Aprovação?'
          }
          submit={
            showBlockModal.show
              ? handleBlockSendReport
              : showDeleteModal.show
              ? handleDelete
              : handleDeleteUser
          }
          loading={approvalFlow.loading}
        >
          <Typography variant="body1">
            {showBlockModal.show
              ? blockButton === 'Bloquear'
                ? 'Nenhum usuário poderá enviar relatório para aprovação'
                : 'Os usuários conseguirão enviar relatório para aprovação'
              : showDeleteModal.show
              ? 'O Fluxo de Aprovação será excluído permanentemente.'
              : 'O usuário será excluído do fluxo.'}
          </Typography>
        </ModalConfirm>
      </Container>
    </>
  );
};

export default ApprovalFlow;
