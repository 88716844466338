import { createSlice } from '@reduxjs/toolkit';
import {
  IState,
  IDriverUserCategoryRequest,
} from './interfaces/DriverUserCategory';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  categories: [],
};

export const slice = createSlice({
  name: 'driverUserCategory',
  initialState,
  reducers: {
    DRIVER_CATEGORY_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    DRIVER_CATEGORY_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    DRIVER_CATEGORY_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.categories = action.payload.result;
    },
    ADD_DRIVER_CATEGORY_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.categories = [...state.categories, action.payload];
    },
  },
});

export const {
  DRIVER_CATEGORY_REQUESTED,
  DRIVER_CATEGORY_FAILED,
  DRIVER_CATEGORY_SUCCESS,
  ADD_DRIVER_CATEGORY_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetDriverUserCategories = (userID: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.DriverUserCategory.GetDriverUserCategories(userID),
    },
    onAction: {
      onStart: DRIVER_CATEGORY_REQUESTED.type,
      onError: DRIVER_CATEGORY_FAILED.type,
      onSuccess: DRIVER_CATEGORY_SUCCESS.type,
    },
  });
};

export const AddDriverUserCategory = (data: IDriverUserCategoryRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.DriverUserCategory.AddDriverUserCategory,
      data,
    },
    onAction: {
      onSuccess: ADD_DRIVER_CATEGORY_SUCCESS.type,
    },
  });
};
