import { useEffect, useState } from 'react';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import Header from '../../../components/Header';
import HeaderSelect from '../../../components/HeaderSelect';
import { Container, IconButton, Grid, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import MapIcon from '@mui/icons-material/Map';
import HeaderSearchSelectMultiple from '../../../components/HeaderSearchSelectMultiple';
import DatePickerRange from '../../../components/DatePickerRange';
import { useDispatch, useSelector } from 'react-redux';
import { GetCostCenters } from '../../../store/_Entities/CostCenter';
import { GetListExpenses } from '../../../store/_Entities/ListExpenses';
import validateDate from '../../../utils/validateDate';
import { numberToCurrency } from '../../../utils/format';

const KmReport = () => {
  const { user, company, costCenter, listExpenses } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const [companiesInputValue, setCompaniesInputValue] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState('0');
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const [data, setData] = useState([]);

  const handleMapa = (row) => {
    console.log('Mostrar mapa');
    console.log(row);
  };

  useEffect(() => {
    if (companiesInputValue.length > 0) {
      dispatch(GetCostCenters(companiesInputValue[0].companyID));
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (
      companiesInputValue.length > 0 &&
      costCenter.costCenters &&
      costCenter.success
    ) {
      setCostCenters(costCenter.costCenters);
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (
      companiesInputValue.length > 0 &&
      validateDate(startDate) &&
      validateDate(endDate)
    ) {
      const startDateFormatted = startDate.toLocaleDateString('pt-BR');
      const endDateFormatted = endDate.toLocaleDateString('pt-BR');
      const request = {
        CompanyID: user.user.companyID,
        Companies: companiesInputValue.map((company) => company.companyID),
        CostCenterID: selectedCostCenter,
        StartDate: startDateFormatted,
        EndDate: endDateFormatted,
      };
      dispatch(GetListExpenses(true, request));
    }
  }, [companiesInputValue, selectedCostCenter, startDate, endDate]);

  useEffect(() => {
    if (
      listExpenses.success &&
      !listExpenses.loading &&
      companiesInputValue.length > 0
    ) {
      setData(listExpenses.kmReports);
    }
  }, [listExpenses.kmReports]);

  const columns = [
    {
      field: 'company',
      headerName: 'Empresa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'project',
      headerName: 'Projeto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'userID',
      headerName: 'User ID',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'user',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'registration',
      headerName: 'Matrícula',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'expenseType',
      headerName: 'Tipo Despesa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'reason',
      headerName: 'Motivo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'justification',
      headerName: 'Justificativa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) =>
        params.row.justification && (
          <Tooltip placement="top" title={params.row.justification}>
            <InfoIcon />
          </Tooltip>
        ),
    },
    {
      field: 'report',
      headerName: 'Relatório',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Data',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'price',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) => numberToCurrency(params.value),
    },
    {
      field: 'currency',
      headerName: 'Moeda',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'addressStart',
      headerName: 'Origem',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'addressEnd',
      headerName: 'Destino',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'distance',
      headerName: 'Distância',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'userConfig',
      headerName: 'Método de Cálculo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'mapa',
      headerName: 'Mapa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleMapa(params.row)} disabled>
          <MapIcon />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    company: 'Empresa',
    costCenter: 'Centro de Custo',
    project: 'Projeto',
    userID: 'User ID',
    user: 'Nome',
    registration: 'Matrícula',
    expenseType: 'Tipo Despesa',
    description: 'Descrição',
    reason: 'Motivo',
    justification: 'Justificativa',
    report: 'Relatório',
    created: 'Data',
    price: 'Valor',
    currency: 'Moeda',
    addressStart: 'Origem',
    addressEnd: 'Destino',
    distance: 'Distância',
    userConfig: 'Método de Cálculo',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header title="Relatório de Despesas KM">
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} md={3}>
              <HeaderSearchSelectMultiple
                label="Empresas"
                onChange={(e) => {
                  setCompaniesInputValue(e.target.value);
                  console.log(companiesInputValue);
                }}
                inputValue={companiesInputValue}
                menuItems={company?.companies
                  ?.filter((company) => company.active == 1)
                  .sort((companyA, companyB) =>
                    companyA.giveName.localeCompare(companyB.giveName)
                  )
                  .map((company) => {
                    return { label: company.giveName, value: company };
                  })}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <HeaderSelect
                label="Centro de Custo"
                onChange={(event) => {
                  setSelectedCostCenter(event.target.value);
                }}
                menuItems={[
                  { label: 'TODOS', value: '0' },
                  ...(costCenters.map((costCenter) => ({
                    label: costCenter.description,
                    value: costCenter.costCenterID,
                  })) || []),
                ]}
                defaultValue={undefined}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePickerRange
                startDate={startDate}
                endDate={endDate}
                onChange={{ startDate: setStartDate, endDate: setEndDate }}
              />
            </Grid>
          </Grid>
        </Header>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.expenseID}
          exportHeaders={exportHeaders}
          loading={listExpenses.loading}
        />
      </Container>
    </>
  );
};

export default KmReport;
