import { useFormContext, Controller } from 'react-hook-form';
import FormInput from '../../../FormInput';
import {
  Box,
  InputLabel,
  Checkbox,
  MenuItem,
  CircularProgress,
  TextField,
} from '@mui/material';
import FormSelect from '../../../FormSelect';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetExpenseTypes } from '../../../../store/_Entities/ExpenseType';
import { useParams } from 'react-router-dom';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { states } from '../../../../constants/states';

const days = [
  { label: 'dom', value: 0 },
  { label: 'seg', value: 1 },
  { label: 'ter', value: 2 },
  { label: 'qua', value: 3 },
  { label: 'qui', value: 4 },
  { label: 'sex', value: 5 },
  { label: 'sab', value: 6 },
];

const PoliciesOptionsForm = ({ selectedPolicyType }) => {
  const { companyID } = useParams();
  const { expenseType } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const blocker = () => (
    <Controller
      name="blocker"
      control={control}
      render={({ field }) => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={Boolean(field.value)}
            onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
            id="blocker-checkbox"
          />
          <InputLabel htmlFor="blocker-checkbox">Bloquear</InputLabel>
        </Box>
      )}
    />
  );

  const formTitle = () => {
    switch (selectedPolicyType) {
      case 1:
        return 'Selecionar Percentual';
      case 2:
        return 'Selecionar Valor';
      case 3:
        return 'Selecionar Dias';
      case 4:
        return 'Selecionar Horário';
      case 5:
        return 'Selecionar Valor da Despesa';
      case 6:
        return 'Selecionar Limite de Lançamentos por Dia';
      case 7:
        return 'Selecionar Pecentual de Desvio da Rota no Km';
      case 8:
        return 'Selecionar Data Retroativa';
      case 9:
        return 'Selecionar Distância Mínima';
      case 10:
        return 'Selecionar Distância Máxima';
      case 12:
        return 'Selecionar Estados';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (
      selectedPolicyType === 5 ||
      selectedPolicyType === 6 ||
      selectedPolicyType === 8
    ) {
      dispatch(GetExpenseTypes(companyID));
    }
  }, [selectedPolicyType]);

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <h3>{formTitle()}</h3>
      </Box>

      {(selectedPolicyType === 1 ||
        selectedPolicyType === 2 ||
        selectedPolicyType === 7 ||
        selectedPolicyType === 9 ||
        selectedPolicyType === 10) && (
        <>
          <Box display={'flex'} alignItems={'center'} gap={4}>
            <Controller
              name={
                selectedPolicyType === 9 || selectedPolicyType === 10
                  ? 'distance'
                  : 'value'
              }
              control={control}
              rules={{ required: 'Campo obrigatório' }}
              render={({ field }) => (
                <Box display={'flex'} alignItems={'center'} gap={2}>
                  <FormInput
                    field={field}
                    number={true}
                    label={
                      selectedPolicyType === 1 || selectedPolicyType === 7
                        ? 'Percentual'
                        : selectedPolicyType === 2
                        ? 'Valor'
                        : selectedPolicyType === 9 || selectedPolicyType === 10
                        ? 'Distância em KM'
                        : ''
                    }
                    errors={errors.value}
                  />
                  {(selectedPolicyType === 1 || selectedPolicyType === 7) && (
                    <h5 variant="subtitle">% de Desvio da Rota (Km)</h5>
                  )}
                </Box>
              )}
            />
            {selectedPolicyType === 1 && (
              <Controller
                name="distance"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <FormInput
                    field={field}
                    number={true}
                    label={'Distância em KM'}
                    errors={errors.distance}
                  />
                )}
              />
            )}
          </Box>
        </>
      )}
      {selectedPolicyType === 3 && (
        <>
          <Controller
            name="value"
            control={control}
            render={({ field: { onChange, value } }) => {
              const selectedDays = value ? value.split(',').map(Number) : [];

              const handleCheckboxChange = (dayValue) => {
                const updatedDays = selectedDays.includes(dayValue)
                  ? selectedDays.filter((day) => day !== dayValue) // Remove o dia se já estiver selecionado
                  : [...selectedDays, dayValue]; // Adiciona o dia se não estiver selecionado

                onChange(updatedDays.sort((a, b) => a - b).join(',')); // Atualiza o result e separa por vírgulas
              };

              return (
                <Box display={'flex'} flexDirection={'column'}>
                  <Box display={'flex'} gap={4}>
                    {days.map(({ label, value: dayValue }) => (
                      <Box key={label}>
                        <Checkbox
                          checked={selectedDays.includes(dayValue)}
                          onChange={() => handleCheckboxChange(dayValue)}
                        />
                        <InputLabel>{label}</InputLabel>
                      </Box>
                    ))}
                  </Box>
                </Box>
              );
            }}
          />
        </>
      )}
      {selectedPolicyType === 4 && (
        <Box display={'flex'} alignItems={'center'} gap={4}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBR}
          >
            <Controller
              name="value_Start"
              control={control}
              rules={{ required: 'Campo obrigatório' }}
              render={({ field }) => (
                <TimePicker
                  views={['hours', 'minutes']}
                  label="Hora Início"
                  value={field.value}
                  onChange={(date) => {
                    field.onChange(date || null);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
            <Controller
              name="value_End"
              control={control}
              rules={{ required: 'Campo obrigatório' }}
              render={({ field }) => (
                <TimePicker
                  views={['hours', 'minutes']}
                  label="Hora Fim"
                  value={field.value}
                  onChange={(date) => {
                    field.onChange(date || null);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      )}
      {(selectedPolicyType === 5 ||
        selectedPolicyType === 6 ||
        selectedPolicyType === 8) && (
        <Box display={'flex'} alignItems={'center'} gap={4}>
          {expenseType.loading ? (
            <CircularProgress />
          ) : (
            <>
              <Controller
                name="expenseTypeID"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    label={'Tipo de despesa'}
                    errors={errors.value}
                  >
                    {expenseType.expenses.map((item) => (
                      <MenuItem
                        key={item.expenseTypeID}
                        value={item.expenseTypeID}
                      >
                        {item.description}
                      </MenuItem>
                    ))}
                  </FormSelect>
                )}
              />
              <Controller
                name="value"
                control={control}
                rules={{ required: 'Campo obrigatório' }}
                render={({ field }) => (
                  <FormInput
                    field={field}
                    label={selectedPolicyType === 8 ? 'Dias' : 'Valor'}
                    errors={errors.value}
                  />
                )}
              />
            </>
          )}
        </Box>
      )}
      {selectedPolicyType === 12 && (
        <Controller
          name="value"
          control={control}
          render={({ field }) => {
            // Função para lidar com a mudança dos checkboxes
            const handleCheckboxChange = (initials) => {
              let selectedStates = field.value ? field.value.split(',') : [];

              if (selectedStates.includes(initials)) {
                // Remove o estado caso já esteja selecionado
                selectedStates = selectedStates.filter(
                  (state) => state !== initials
                );
              } else {
                // Adiciona o estado caso não esteja selecionado
                selectedStates.push(initials);
              }

              // Atualiza o value com as iniciais separadas por vírgula
              field.onChange(selectedStates.join(','));
            };

            return (
              <Box display={'flex'} gap={3} flexWrap={'wrap'}>
                {states.map((state) => (
                  <Box key={state.initials}>
                    <Checkbox
                      checked={field.value?.includes(state.initials)}
                      onChange={() => handleCheckboxChange(state.initials)}
                    />
                    <InputLabel>{state.initials}</InputLabel>
                  </Box>
                ))}
              </Box>
            );
          }}
        />
      )}
      <Box mt={3}>
        {(selectedPolicyType === 3 ||
          selectedPolicyType === 4 ||
          selectedPolicyType === 5 ||
          selectedPolicyType === 9 ||
          selectedPolicyType === 10 ||
          selectedPolicyType === 12) &&
          blocker()}
      </Box>
    </>
  );
};

export default PoliciesOptionsForm;
