// Projetos / O.S.
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Checkbox,
  Container,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { useState, useEffect } from 'react';
import Header from '../../../components/Header';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteProject, GetProjects } from '../../../store/_Entities/Project';
import ModalConfirm from '../../../components/ModalConfirm';
import { useNavigate } from 'react-router-dom';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company'

const ProjectsOs = () => {
  const { company, project } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });

  const [showError, setShowError] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleDelete = () => {
    dispatch(DeleteProject(selectedProject));
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (selectedCompany) {
      dispatch(GetProjects(selectedCompany));
      dispatch(setLastCompanyFiltered(selectedCompany));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setData(project.projects);
    }
  }, [project.projects]);

  const columns = [
    {
      field: 'projectID',
      headerName: 'Código',
      headerAlign: 'center',
      align: 'center',
      minWidth: 75,
      flex: 1,
    },
    {
      field: 'number',
      headerName: 'Número de controle',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'productive',
      headerName: 'Produtivo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Checkbox checked={params.value === 1 ? true : false} />
      ),
    },
    {
      field: 'refundable',
      headerName: 'Reembolsável',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Checkbox checked={params.value === 1 ? true : false} />
      ),
    },
    {
      field: 'Editar',
      headerName: 'Editar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() =>
            navigate(
              `/adm/projetos-os/edit/${selectedCompany}/${params.row.projectID}`
            )
          }
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'Deletar',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setSelectedProject(params.row.projectID);
            setShowConfirmModal(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    codeID: 'Código',
    'Número de controle': 'Número de controle',
    Descrição: 'Descrição',
    Produtivo: 'Produtivo',
    Reembolsável: 'Reembolsável',
    Editar: 'Editar',
    Deletar: 'Deletar',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Header
          title="Projetos / O.S."
          addHref={
            selectedCompany ? `/adm/projetos-os/add/${selectedCompany}` : null
          }
          onClick={!selectedCompany ? () => setShowError(true) : null}
        >
          <HeaderSearchSelectCompany
            label="Empresas"
            onChange={setSelectedCompany}
            error={showError}
            defaultValue={selectedCompany}
          />
        </Header>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.projectID}
          exportHeaders={exportHeaders}
          loading={project.loading}
        />

        <ModalConfirm
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          submit={handleDelete}
          title="Deseja Excluir o Projeto?"
        >
          <Box>
            <Typography variant="subtitle1">
              O Projeto será excluido permanentemente.
            </Typography>
          </Box>
        </ModalConfirm>
      </Container>
    </>
  );
};

export default ProjectsOs;
