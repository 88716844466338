import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';

const InfoCard = ({ title, value, percentual }) => {
  const parsedPercentual = percentual
    ? parseFloat(percentual.replace('%', '').replace(',', '.'))
    : 0;

  const percentualColor = parsedPercentual > 0 ? 'green' : 'red';

  return (
    <Card
      sx={{
        textAlign: 'center',
        m: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      }}
    >
      <CardContent>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ marginBottom: 2 }}
        >
          {title}
        </Typography>
        <Typography variant="h6" component="div" sx={{ fontWeight: 500 }}>
          {value}
        </Typography>
        <Typography
          textAlign={'end'}
          variant="body2"
          component="div"
          sx={{
            color: percentualColor,
          }}
        >
          {percentual && (
            <>
              {`${percentual} `}
              {parsedPercentual > 0 ? '↑' : '↓'}
            </>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};

const InfoCards = ({ cardsData }) => {
  return (
    <Box sx={{ marginTop: 3, marginBottom: 6, padding: 0 }}>
      <Grid container spacing={2}>
        {cardsData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box sx={{ height: '100%' }}>
              <InfoCard
                title={card.title}
                value={card.value}
                percentual={card.percentual}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default InfoCards;