import { ModalWrapper } from './Modal';
import { Box, CircularProgress, Typography } from '@mui/material';

const ImageModal = ({ showModal, closeModalAction, loading, images }) => {
  return (
    <ModalWrapper
      title="Imagens"
      showModal={showModal}
      closeModalAction={closeModalAction}
    >
      <Box
        display={'flex'}
        gap={4}
        maxWidth={400}
        sx={{
          overflowX: 'auto',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : images && images.length > 0 ? (
          images.map((image, index) => (
            <a
              key={index}
              href={image}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={image}
                style={{
                  maxWidth: '400px',
                  maxHeight: '400px',
                }}
                alt="Anexo"
              />
            </a>
          ))
        ) : (
          <Typography>A despesa não possui um recibo.</Typography>
        )}
      </Box>
    </ModalWrapper>
  );
};

export default ImageModal;
