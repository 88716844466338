import React, { useState, useEffect, useMemo } from "react";
import { Container, Grid, Box, Typography, CircularProgress } from "@mui/material";
import ResponsiveAppBar from '../../../components/NavBar';
import InfoCards from '../../../components/InfoCards';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Colors
} from 'chart.js';
import DatePickerRange from "../../../components/DatePickerRange";
import HeaderSelect from "../../../components/HeaderSelect";
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { GetVolumeDashboard } from "../../../store/_Entities/VolumeDashboard";
import { GetActiveUsers } from "../../../store/_Entities/User";
import validateDate from "../../../utils/validateDate";
import { GetAllProviders } from '../../../store/_Entities/Provider';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

const Gastos = () => {
  const { company, provider, dashboardVolume, user } = useSelector((state) => state.entities);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectProviderID, setSelectProviderID] = useState("null");
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedRides, setSelectedRides] = useState("null");
  const dispatch = useDispatch();

  const adjustedStartDate = useMemo(() => {
    const date = new Date(startDate);
    date.setHours(0, 0, 0, 0); // 00:00:00
    return date;
  }, [startDate]);

  const adjustedEndDate = useMemo(() => {
    const date = new Date(endDate);
    date.setHours(23, 59, 59, 999); // 23:59:59
    return date;
  }, [endDate]);

  useEffect(() => {
    if (validateDate(adjustedStartDate) && validateDate(adjustedEndDate) && selectedCompany) {
      const Provider = selectProviderID === 'null' ? null : selectProviderID ? +selectProviderID : null;
      const Rides = selectedRides === 'null' ? '' : selectedRides || '';
      dispatch(GetVolumeDashboard(
        selectedCompany,
        adjustedStartDate.toISOString(),
        adjustedEndDate.toISOString(),
        Rides,
        Provider
      ));
    }
  }, [selectedCompany, selectProviderID, adjustedStartDate, adjustedEndDate, selectedRides, dispatch]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(GetActiveUsers(selectedCompany));
    }
  }, [selectedCompany, dispatch]);

  useEffect(() => {
    dispatch(GetAllProviders());
  }, []);

  // Opções personalizadas para os gráficos com formatação de 'R$'
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: function(value) {
            return 'R$ ' + value.toLocaleString('pt-BR');
          }
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += 'R$ ' + context.parsed.y.toLocaleString('pt-BR');
            }
            return label;
          }
        }
      }
    }
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: function(value) {
            return 'R$ ' + value.toLocaleString('pt-BR');
          }
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += 'R$ ' + context.parsed.y.toLocaleString('pt-BR');
            }
            return label;
          }
        }
      }
    }
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.label || '';
            let value = context.parsed || 0;
            if (label) {
              label += ': ';
            }
            label += 'R$ ' + value.toLocaleString('pt-BR');
            return label;
          }
        }
      }
    }
  };

  const lineData = useMemo(() => {
    if (!selectedCompany) return { labels: [], datasets: [] };

    const groupedByMonth = dashboardVolume?.volumes?.groupedByMonth || [];
    const labels = groupedByMonth.map(item => item.month);
    const data = groupedByMonth.map(item => {
      const totalPrice = item.receipts.reduce((sum, receipt) => sum + (receipt.price || 0), 0);
      return totalPrice;
    });

    return {
      labels,
      datasets: [
        {
          label: 'Gastos Totais',
          data,
          borderColor: '#004984',
          backgroundColor: 'rgba(0,73,132,255)',
          fill: false,
          tension: 0.1,
        },
      ],
    };
  }, [dashboardVolume, selectedCompany]);

  const barData = useMemo(() => {
    if (!selectedCompany) return { labels: [], datasets: [] };

    const receipts = (dashboardVolume?.volumes?.groupedByMonth || []).flatMap(item => item.receipts || []);
    const userExpenses = receipts.reduce((acc, receipt) => {
      acc[receipt.userID] = (acc[receipt.userID] || 0) + receipt.price;
      return acc;
    }, {});

    const topUsers = Object.entries(userExpenses)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10);

    const labels = topUsers.map(([userID]) => {
      const receipt = receipts.find(receipt => receipt.userID === userID);
      return receipt ? receipt.name : '';
    });

    const data = topUsers.map(([, totalPrice]) => totalPrice);

    return {
      labels,
      datasets: [
        {
          label: 'Gastos Totais',
          data,
          backgroundColor: '#ee7330',
        },
      ],
    };
  }, [dashboardVolume, selectedCompany]);

  const pieData = useMemo(() => {
    if (!selectedCompany) return { labels: [], datasets: [] };

    const receipts = (dashboardVolume?.volumes?.groupedByMonth || []).flatMap(item => item.receipts || []);

    const providers = [...new Set(receipts.map(receipt => receipt.provider))];

    const data = providers.map(provider =>
      receipts
        .filter(receipt => receipt.provider === provider)
        .reduce((sum, receipt) => sum + receipt.price, 0)
    );
    return {
      labels: providers,
      datasets: [
        {
          data,
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#ee7330', '#004984'],
        },
      ],
    };
  }, [dashboardVolume, selectedCompany]);

  const cardsData = [
    {
      title: 'Valor Total Gasto',
      value: selectedCompany
        ? 'R$ ' + (dashboardVolume?.volumes?.calculations?.totalPrice || 0).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 'R$ 0,00',
    },
    {
      title: 'Total de Corridas',
      value: selectedCompany ? dashboardVolume?.volumes?.calculations?.totalReceipts || 0 : 0,
    },
    {
      title: 'Preço Médio por Corrida',
      value: selectedCompany
        ? 'R$ ' + (dashboardVolume?.volumes?.calculations?.avgPrice || 0).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 'R$ 0,00',
    },
  ];
  

  return (
    <>
      <ResponsiveAppBar />

      <Container>
        <Typography variant="h4" gutterBottom sx={{ marginTop: '20px' }}>
          Gastos
        </Typography>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={12} sm={4} md={2.5}>
            <HeaderSearchSelectCompany
              label={'Empresas'}
              onChange={setSelectedCompany}
              defaultValue={selectedCompany}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2.5}>
            <HeaderSelect
              label="Fornecedor"
              onChange={(event) => {
                setSelectProviderID(event.target.value);
              }}
              menuItems={[
                {
                  label: 'TODOS',
                  value: 'null',
                },
                ...(provider?.providers?.map((prov) => ({
                  label: prov.description,
                  value: prov.providerID,
                })) || []),
              ]}
              defaultValue={undefined}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <HeaderSelect
              label="Corridas realizadas"
              onChange={(event) => {
                setSelectedRides(event.target.value);
              }}
              menuItems={[
                { label: 'Todas', value: 'null' },
                { label: 'Por Dentro', value: '1' },
                { label: 'Por Fora', value: '0' },
              ]}
              defaultValue={'null'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} sx={{ mt: 2 }}>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              onChange={{ startDate: setStartDate, endDate: setEndDate }}
            />
          </Grid>
        </Grid>
      </Container>

      {dashboardVolume?.loading && (
        <Box width='100%' display='flex' justifyContent='center' mt={10}>
          <CircularProgress />
        </Box>
      )}

      {!dashboardVolume?.loading && (
        <>
          <Container>
            <InfoCards cardsData={cardsData} />
          </Container>

          <div style={{ padding: '10px' }}>
            <div style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', maxWidth: '1200px', margin: '0 auto' }}>
              <h3>Gastos por Ano e Mês</h3>
              <div style={{ width: '100%', height: '250px' }}>
                <Line data={lineData} options={lineChartOptions} />
              </div>
            </div>
            <div style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', maxWidth: '1200px', margin: '0 auto' }}>
              <h3>TOP 10 Usuários (Maiores Gastos)</h3>
              <div style={{ width: '100%', height: '250px' }}>
                <Bar data={barData} options={barChartOptions} />
              </div>
            </div>
            <div style={{ backgroundColor: '#fff', padding: '10px', marginBottom: '20px', maxWidth: '1200px', margin: '0 auto' }}>
              <h3>Gastos por Fornecedor</h3>
              <div style={{ width: '100%', height: '250px' }}>
                <Pie data={pieData} options={pieChartOptions} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Gastos;
