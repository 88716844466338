import React from 'react';
import { Box, Typography, Card, CardContent, Container } from '@mui/material';

const DataDisplay = ({ data }) => {
  return (
    <Container
      sx={{ width: '100%', height: '100%', padding: { xs: 2, md: 3 } }}
    >
      <Box display="flex" alignItems="center" gap={2} mt={2} flexWrap="wrap">
        <Box>
          {data?.valorKm && (
            <Typography variant="body2" component="div">
              Valor KM: {data.valorKm}
            </Typography>
          )}
          {data?.dataUltimoValorKm && (
            <Typography variant="body2" color="textSecondary">
              Data último valor KM: {data.dataUltimoValorKm}
            </Typography>
          )}
        </Box>

        {data?.despesas?.map((item, index) => (
          <Card
            key={index}
            sx={{
              width: '150px',
              padding: '5px',
              height: '80px',
              display: 'flex',
              alignItems: 'center',
              boxShadow: '0px 0px 4px 0px #00000040',
            }}
          >
            <CardContent
              sx={{ padding: '8px', '&:last-child': { paddingBottom: '8px' } }}
            >
              {item.label && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                >
                  {item.label}:&nbsp;
                </Typography>
              )}
              {item.valor && (
                <Typography variant="body2" component="span">
                  {item.valor}
                </Typography>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default DataDisplay;
