import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';

import ResponsiveAppBar from '../components/NavBar';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { GetUser } from '../store/_Entities/User';
import { Box } from '@mui/material';
import logo from '../assets/images/logow.png';
import {
  GetCompaniesByUserID,
  GetCompaniesCostCenterAdmin,
  SAVE_USER_COMPANY_SUCCESS,
} from '../store/_Entities/Company';

const Home = () => {
  const { user } = useSelector((state) => state.entities);
  const [userInfo, setUserInfo] = useState({
    fetch: false,
    value: null,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      user.roles.includes('companyadministrator') ||
      user.roles.includes('systemadministrator')
    ) {
      dispatch(GetCompaniesByUserID(user.authData.sub));
    } else {
      setUserInfo((prev) => ({ ...prev, fetch: true }));
    }
    dispatch(GetUser(user.authData.sub));
  }, []);

  useEffect(() => {
    if (userInfo.fetch && user.success && user.user && !user.loading) {
      dispatch(
        SAVE_USER_COMPANY_SUCCESS({
          giveName: user.user.company,
          companyID: user.user.companyID,
          active: 1,
        })
      );
    }
  }, [user.user]);

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth="md">
        <Box
          container
          spacing={2}
          className="home-comp"
          sx={{ marginTop: '45px', textAlign: 'center' }}
        >
          <Typography
            variant="h2"
            component="div"
            sx={{
              color: '#686868',
              textAlign: 'center',
              fontWeight: 'regular',
            }}
          >
            Bem vindo a
          </Typography>
          <img src={logo} alt="" style={{ width: '424px' }} />
        </Box>
      </Container>
    </>
  );
};

export default Home;
