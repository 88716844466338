import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';
import { IState } from './interfaces/EconomyRideReport';

const initialState : IState = {
    loading: false,
    error: false,
    success: false,
    economyRideReports: []
};

export const slice = createSlice({
    name: 'economyRideReport',
    initialState,
    reducers: {
        REPORT_REQUESTED: (state,action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        REPORT_FAILED: (state,action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
            console.log('REPORT_FAILED');
            console.log(action);
        },
        REPORT_ESTIMATIVE_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            console.log('REPORT_ESTIMATIVE_SUCCESS');
            console.log(action);
            state.economyRideReports = action.payload.result;
        },
    }
})

export const { REPORT_REQUESTED, REPORT_FAILED, REPORT_ESTIMATIVE_SUCCESS } = slice.actions;

export default slice.reducer;

export const GetEconomyRideReport = (request: any) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'POST',
            url: Endpoints.Report.GetEconomyRideReport,
            data: request
        },
        onAction: {
            onStart: REPORT_REQUESTED.type,
            onError: REPORT_FAILED.type,
            onSuccess: REPORT_ESTIMATIVE_SUCCESS.type,
        }
    })
}

