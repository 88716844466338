import { createSlice } from '@reduxjs/toolkit';
import { IState, IProjectRequest } from './interfaces/Project';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  projects: [],
  selectedProject: {
    projectID: 0,
    companyID: 0,
    number: '',
    description: '',
    active: 0,
    productive: 0,
    refundable: 0,
  },
};

export const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    PROJECT_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    PROJECT_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    PROJECT_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.projects = action.payload.result;
    },
    PROJECT_SELECT_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.selectedProject = action.payload.result;
    },
    PROJECT_CREATE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.projects = [...state.projects, action.payload.result];
    },
    PROJECT_DELETE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.projects = state.projects.filter(
        (project) => project.projectID !== action.payload.result.projectID
      );
    },
  },
});

export const {
  PROJECT_REQUESTED,
  PROJECT_FAILED,
  PROJECT_SUCCESS,
  PROJECT_SELECT_SUCCESS,
  PROJECT_CREATE_SUCCESS,
  PROJECT_DELETE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetProjects = (CompanyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Project.GetProjects(CompanyID),
    },
    onAction: {
      onStart: PROJECT_REQUESTED.type,
      onError: PROJECT_FAILED.type,
      onSuccess: PROJECT_SUCCESS.type,
    },
  });
};

export const GetProject = (ProjectID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Project.Project(ProjectID),
    },
    onAction: {
      onStart: PROJECT_REQUESTED.type,
      onError: PROJECT_FAILED.type,
      onSuccess: PROJECT_SELECT_SUCCESS.type,
    },
  });
};

export const CreateProject = (data: IProjectRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Project.CreateProject,
      data,
    },
    onAction: {
      onStart: PROJECT_REQUESTED.type,
      onError: PROJECT_FAILED.type,
      onSuccess: PROJECT_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const UpdateProject = (projectID: number, data: IProjectRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.Project.Project(projectID),
      data,
    },
    onAction: {
      onStart: PROJECT_REQUESTED.type,
      onError: PROJECT_FAILED.type,
      onSuccess: PROJECT_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteProject = (ProjectID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.Project.Project(ProjectID),
    },
    onAction: {
      onStart: PROJECT_REQUESTED.type,
      onError: PROJECT_FAILED.type,
      onSuccess: PROJECT_DELETE_SUCCESS.type,
      showToast: true,
    },
  });
};
