import { useState, useCallback, useMemo, useEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { Box, Snackbar, Alert } from '@mui/material';

const containerStyle = {
  width: '1200px',
  height: '600px',
};

const libraries = ['geometry', 'places'];

const colorBoxStyle = (color) => ({
  width: '20px',
  height: '10px',
  backgroundColor: color,
  marginRight: '10px',
});

const legendContainerStyle = {
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  backgroundColor: 'white',
  padding: '5px',
  borderRadius: '5px',
  boxShadow: '0 0 5px rgba(0,0,0,0.3)',
  zIndex: 1000,
};

const GoogleMapComponent = ({ origin, destination, estimative }) => {
  const [center, setCenter] = useState({
    lat: parseFloat(origin.lat),
    lng: parseFloat(origin.lng),
  });
  const [zoom] = useState(13);
  const [map, setMap] = useState(null);
  const [error, setError] = useState({
    show: false,
    message: '',
  });
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const loaderOptions = useMemo(
    () => ({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
      libraries,
    }),
    []
  );

  const { isLoaded } = useJsApiLoader(loaderOptions);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const decodePolyline = useCallback((polyline) => {
    if (!polyline) return [];
    if (window.google?.maps?.geometry) {
      return window.google.maps.geometry.encoding.decodePath(polyline);
    }
    console.error('Google Maps geometry library is not loaded.');
    setError({
      show: true,
      message: 'Erro ao carregar o mapa. Tente novamente mais tarde.',
    });
    return [];
  }, []);

  // Fetch and display directions if no estimative is provided
  useEffect(() => {
    if (isLoaded && estimative) {
      const firstPlottable = estimative.find((item) => item.isPlottable);
      if (firstPlottable && firstPlottable.overview_polyline) {
        const path = decodePolyline(firstPlottable.overview_polyline);
        if (path.length > 0) {
          const startPoint = path[0];
          const endPoint = path[path.length - 1];
          const centerLat = (startPoint.lat() + endPoint.lat()) / 2;
          const centerLng = (startPoint.lng() + endPoint.lng()) / 2;
          setCenter({ lat: centerLat, lng: centerLng });
        }
      }
    } else if (isLoaded && origin && destination) {
      const service = new window.google.maps.DirectionsService();
      service.route(
        {
          origin: { lat: parseFloat(origin.lat), lng: parseFloat(origin.lng) },
          destination: {
            lat: parseFloat(destination.lat),
            lng: parseFloat(destination.lng),
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result);
            // Set center between origin and destination
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(
              new window.google.maps.LatLng(origin.lat, origin.lng)
            );
            bounds.extend(
              new window.google.maps.LatLng(destination.lat, destination.lng)
            );
            setCenter(bounds.getCenter().toJSON());
          } else {
            setError({
              show: true,
              message: 'Erro ao traçar a rota entre a origem e o destino.',
            });
          }
        }
      );
    }
  }, [isLoaded, estimative, decodePolyline, origin, destination]);

  const renderRoutesAndMarkers = useCallback(() => {
    if (estimative) {
      return estimative
        ?.filter((item) => item.isPlottable)
        ?.map((item) => {
          const path = decodePolyline(item.overview_polyline);
          if (path.length === 0) return null;

          const startPoint = path[0];
          const endPoint = path[path.length - 1];

          return (
            <div key={item.expenseEstimativeID}>
              <Marker
                position={{
                  lat: startPoint.lat(),
                  lng: startPoint.lng(),
                }}
                label="A"
              />
              <Marker
                position={{
                  lat: endPoint.lat(),
                  lng: endPoint.lng(),
                }}
                label="B"
              />
              <Polyline
                path={path}
                options={{
                  strokeColor: '#' + item.routeColor,
                  strokeOpacity: 0.5,
                  strokeWeight: 5,
                }}
              />
            </div>
          );
        });
    } else if (directionsResponse) {
      return (
        <DirectionsRenderer
          directions={directionsResponse}
          options={{
            polylineOptions: {
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 6,
            },
          }}
        />
      );
    }
  }, [estimative, decodePolyline, directionsResponse]);

  const Legend = useMemo(() => {
    return ({ estimative }) => (
      <div style={legendContainerStyle}>
        <h4>Legenda: Método de Cálculo - Distância (km)</h4>
        {estimative.map((item) => (
          <div
            key={item.expenseEstimativeID}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={colorBoxStyle('#' + item.routeColor)}></div>
            <span>
              {item.estimativeType} - {item.distance}
            </span>
          </div>
        ))}
      </div>
    );
  }, [estimative]);

  return (
    <Box sx={{ width: '100%', height: '600px' }}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {renderRoutesAndMarkers()}
          {estimative !== null && <Legend estimative={estimative} />}
        </GoogleMap>
      ) : null}
      <Snackbar
        open={error.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setError({ show: false })}>
          {error.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default GoogleMapComponent;
