import { Controller, useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import FormCheckbox from '../../../FormCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  AddDriverUserCategory,
  GetDriverUserCategories,
} from '../../../../store/_Entities/DriverUserCategory';

const CategoriesFrom = ({ navigateBack, setShowSuccessModal }) => {
  const { userID } = useParams();
  const { driverUserCategory } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    dispatch(GetDriverUserCategories(userID));
  }, []);

  useEffect(() => {
    if (driverUserCategory.categories) {
      setData(driverUserCategory.categories);
    }
  }, [driverUserCategory.categories]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      basic: false,
      basicTaxi: false,
      basicBag: false,
      comfort: false,
      premiumTaxi: false,
      premiumBag: false,
      premium: false,
      delivery: false,
    },
  });

  useEffect(() => {
    if (!hasSubmitted) {
      reset();
    }
    if (data.length > 0) {
      data.forEach((category) => {
        switch (category.categoryID) {
          case 2:
            setValue('basic', true);
            break;
          case 5:
            setValue('basicTaxi', true);
            break;
          case 7:
            setValue('basicBag', true);
            break;
          case 9:
            setValue('comfort', true);
            break;
          case 6:
            setValue('premiumTaxi', true);
            break;
          case 8:
            setValue('premiumBag', true);
            break;
          case 4:
            setValue('premium', true);
            break;
          case 10:
            setValue('delivery', true);
            break;
          default:
            break;
        }
      });
    }
    setHasSubmitted(false);
  }, [data]);

  const onSubmit = async (formData) => {
    const categoriesToSubmit = [
      { name: 'basic', id: 2 },
      { name: 'basicTaxi', id: 5 },
      { name: 'basicBag', id: 7 },
      { name: 'comfort', id: 9 },
      { name: 'premiumTaxi', id: 6 },
      { name: 'premiumBag', id: 8 },
      { name: 'premium', id: 4 },
      { name: 'delivery', id: 10 },
    ];

    const selectedCategories = categoriesToSubmit.filter(
      (category) => formData[category.name]
    );

    const categoriesArray = selectedCategories.map((category) => ({
      userID,
      categoryID: category.id,
    }));

    dispatch(AddDriverUserCategory(categoriesArray));
    setHasSubmitted(true);
  };

  useEffect(() => {
    if (hasSubmitted && driverUserCategory.success) {
      setShowSuccessModal({ show: true, type: 'Categorias' });
    }
  }, [hasSubmitted, driverUserCategory.success]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          sx={{
            boxShadow: '0px 0px 4px 0px #00000040',
            borderRadius: '10px',
            padding: 2,
          }}
        >
          {driverUserCategory.loading ? (
            <Box width={'100%'} textAlign={'center'}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Permissões de categorias</Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={3}>
                <Controller
                  name="basic"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox label="Basic" field={field} />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="basicTaxi"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox label="Táxi Basic" field={field} />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="basicBag"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox label="Bag Basic" field={field} />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="comfort"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox label="Conforto" field={field} />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="premiumTaxi"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox label="Táxi Premium" field={field} />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="premiumBag"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox label="Bag Premium" field={field} />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="premium"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox label="Premium" field={field} />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="delivery"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox label="Entrega" field={field} />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
          <Button variant="contained" type="submit">
            Salvar
          </Button>
          <Button variant="outlined" onClick={navigateBack}>
            Voltar
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default CategoriesFrom;
