import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid, Modal } from '@mui/material';
import NavbarDrawer from './NavBarDrawer';
import logo from '../assets/images/logow.png';
import userIcon from '../assets/images/user (1).png';
import WexpIconOrangeCancel from '../assets/images/cancel.png';
import { useDispatch, useSelector } from 'react-redux';
import { USER_LOGOUT } from '../store/_Entities/User';
import { Link, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

const pages = [
  { key: 'Início', href: '/home' },
  {
    key: 'Driver',
    href: '',
    menuKeys: [
      {
        key: 'Corridas',
        options: [
          { key: 'Corridas Realizadas', href: '/driver/corridas-realizadas' },
          {
            key: 'Corridas por Projetos',
            href: '/driver/corridas-por-projetos',
          },
          {
            key: 'Corridas não Reconciliadas',
            href: '/driver/corridas-nao-reconciliadas',
          },
        ],
      },
      {
        key: 'Economia',
        options: [
          { key: 'Cotações', href: '/economy/corridas-estimadas' },
          {
            key: 'Economia por Corrida',
            href: '/economy/economias-por-corrida',
          },
          {
            key: 'Economias (memória de cálculo)',
            href: '/economy/economias-calculo',
          },
          {
            key: 'Economias com Comissionamentos',
            href: '/economy/economias-comissionadas',
          },
        ],
      },
      {
        key: 'Políticas',
        options: [
          { key: 'Não Conformidade', href: '/policies/nao-conformidade' },
          { key: 'Alertas Gerenciais', href: '/policies/alertas-gerenciais' },
        ],
      },
    ],
  },
  {
    key: 'Expen',
    href: '',
    menuKeys: [
      {
        key: 'Expen',
        options: [
          { key: 'Adiantamentos', href: '/expen/adiantamentos' },
          { key: 'Relatórios', href: '/expen/relatorios' },
          { key: 'Despesas', href: '/expen/despesas' },
          { key: 'Relatório de Despesas', href: '/expen/relatorios-despesas' },
          { key: 'Relatório de KM', href: '/expen/relatorios-km' },
        ],
      },
      {
        key: 'Aprovação Expen',
        options: [
          { key: 'Aprovação', href: '/expen/aprovacao' },
          { key: 'Administração Financeira', href: '/expen/adm-financeira' },
        ],
      },
    ],
  },
  {
    key: 'Adm',
    href: '',
    menuKeys: [
      {
        key: 'Configurações Gerais',
        options: [
          { key: 'Usuários Convidados', href: '/adm/usuarios-convidados' },
          { key: 'Usuários Ativos', href: '/adm/usuarios-ativos' },
          { key: 'Usuários Inativos', href: '/adm/usuarios-inativos' },
          { key: 'Projetos / O.S.', href: '/adm/projetos-os' },
          { key: 'Motivo', href: '/adm/motivo' },
          { key: 'Cadastro em Lote', href: '/adm/cadastro-em-lote' },
          { key: 'Log de Integrações', href: '/adm/log-de-integracao' },
          { key: 'Política e Preferências', href: '/adm/politicas' },
        ],
      },
      {
        key: 'Configurações Expen / Km',
        options: [
          { key: 'Moeda', href: '/km/moeda' },
          { key: 'Tipo de Despesa', href: '/km/tipo-de-despesa' },
          { key: 'Fluxo de Aprovação', href: '/km/fluxo-de-aprovacao' },
          { key: 'Usuários Ativos', href: '/km/usuatios-ativos' },
          { key: 'Parâmetros Empresa', href: '/km/parametros-empresa' },
        ],
      },
      {
        key: 'RH',
        options: [
          {
            key: 'Pendente de Prestações de contas',
            href: '/rh/pendente-de-prestacoes-de-contas',
          },
        ],
      },
    ],
  },
  {
    key: 'Dashboard',
    href: '',
    menuKeys: [
      {
        key: 'Driver',
        options: [
          { key: 'Volume(#)', href: '/Dashboard/Driver/volume' },
          { key: 'Gastos($)', href: '/Dashboard/Driver/gastos' },
          {
            key: 'Economia Transacional',
            href: '/Dashboard/Driver/economia-transacional',
          },
        ],
      },
    ],
  },
];

const modalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ResponsiveAppBar = ({ onlyExit }) => {
  const { user } = useSelector((state) => state.entities);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [showLogoutModal, setShowLogoutModal] = React.useState(false);
  const [showDrawerSubMenu, setShowDrawerSubMenu] = React.useState(false);
  const [drawerContent, setDrawerContent] = React.useState([]);

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const settings = [
    {
      key: 'Configurar conta',
      onClick: () => {
        navigate('/account');
      },
    },
    {
      key: 'Sair',
      onClick: () => {
        setShowLogoutModal(true);
      },
    },
  ];

  const confirmLogout = () => {
    dispatch(USER_LOGOUT());
    navigate('/login');
  };

  const confirmSsoLogout = () => {
    if (activeAccount) {
      instance
        .logoutRedirect({
          postLogoutRedirectUri: window.location.origin + '/login',
        })
        .catch((error) => console.log(error));
      instance.setActiveAccount(null);
    }
    dispatch(USER_LOGOUT());
  };

  const hasRole = (roles, role) => roles.includes(role);

  const filterMenuKeys = (page, allowedKeys) => {
    if (page.menuKeys) {
      page.menuKeys = page.menuKeys.filter((menu) =>
        allowedKeys.includes(menu.key)
      );
    }
  };

  const filterAddMenuOptions = (page, allowedKeys) => {
    if (page.menuKeys) {
      page.menuKeys.forEach((menu) => {
        menu.options = menu.options.filter((option) =>
          allowedKeys.includes(option.key)
        );
      });
    }
  };

  const filterRemoveMenuOptions = (page, notAllowedKeys) => {
    if (page.menuKeys) {
      page.menuKeys.forEach((menu) => {
        menu.options = menu.options.filter(
          (option) => !notAllowedKeys.includes(option.key)
        );
      });
    }
  };

  const filterMenuChildrenOption = (page, targetPage) => {
    if (page.menuKeys) {
      page.menuKeys[1].options = page.menuKeys[1].options.filter(
        (option) => option.key === targetPage
      );
    }
  };

  const hasAccessTo = (page) => {
    const { roles } = user;

    // Acesso completo para SystemAdmin
    if (page.key == 'Início' || hasRole(roles, 'systemadministrator'))
      return true;

    // Perfil Expen, Financeiro e Aprovação
    if (
      hasRole(roles, 'Expen') &&
      hasRole(roles, 'financialadm') &&
      hasRole(roles, 'ApprovalFlow') &&
      page.key === 'Expen'
    ) {
      if (!hasRole(roles, 'KM')) {
        filterRemoveMenuOptions(page, ['Relatório de KM']);
      }
      return true;
    }

    // Perfil Expen e Financeiro
    if (
      hasRole(roles, 'Expen') &&
      hasRole(roles, 'financialadm') &&
      page.key === 'Expen'
    ) {
      filterMenuChildrenOption(page, 'Administração Financeira');
      if (!hasRole(roles, 'KM')) {
        filterRemoveMenuOptions(page, ['Relatório de KM']);
      }
      return true;
    }

    // Perfil Expen e Aprovação
    if (
      hasRole(roles, 'Expen') &&
      hasRole(roles, 'ApprovalFlow') &&
      page.key === 'Expen'
    ) {
      filterMenuChildrenOption(page, 'Aprovação');

      if (!hasRole(roles, 'KM')) {
        filterRemoveMenuOptions(page, ['Relatório de KM']);
      }
      return true;
    }

    // Perfil Gestor Empresa
    if (hasRole(roles, 'companyadministrator')) {
      if (hasRole(roles, 'Driver')) {
        if (page.key === 'Driver') {
          return true;
        }
        if (page.key === 'Dashboard') {
          filterMenuKeys(page, ['Driver']);
          return true;
        }
      }
      if (page.key === 'Adm') {
        if (hasRole(roles, 'Expen') || hasRole(roles, 'KM')) {
          if (hasRole(roles, 'rhadministrator')) {
            filterMenuKeys(page, [
              'Configurações Gerais',
              'Configurações Expen / Km',
              'RH',
            ]);
            return true;
          } else {
            filterMenuKeys(page, [
              'Configurações Gerais',
              'Configurações Expen / Km',
            ]);
            return true;
          }
        } else {
          if (hasRole(roles, 'rhadministrator')) {
            filterMenuKeys(page, ['Configurações Gerais', 'RH']);
            return true;
          } else {
            filterMenuKeys(page, ['Configurações Gerais']);
            return true;
          }
        }
      }
      if (hasRole(roles, 'Expen') && page.key === 'Expen') {
        filterMenuKeys(page, ['Expen']);
        if (!hasRole(roles, 'KM'))
          filterRemoveMenuOptions(page, ['Relatório de KM']);
        return true;
      }
    }

    // Perfil Gestor Centro de Custo
    if (hasRole(roles, 'costcenteradministrator')) {
      if (hasRole(roles, 'Driver')) {
        if (page.key === 'Driver') {
          return true;
        }
        if (page.key === 'Dashboard') {
          filterMenuKeys(page, ['Driver']);
          return true;
        }
      }
      if (hasRole(roles, 'Expen') && page.key === 'Expen') {
        filterMenuKeys(page, ['Expen']);
        if (!hasRole(roles, 'KM')) {
          filterRemoveMenuOptions(page, ['Relatório de KM']);
        }

        return true;
      }
    }

    // Perfil Expen
    if (hasRole(roles, 'Expen') && page.key === 'Expen') {
      filterMenuKeys(page, ['Expen']);

      if (!hasRole(roles, 'KM')) {
        filterRemoveMenuOptions(page, ['Relatório de KM']);
      }

      return true;
    }

    // Perfil Driver
    if (hasRole(roles, 'Driver')) {
      if (page.key === 'Driver') {
        filterMenuKeys(page, ['Corridas']);
        filterAddMenuOptions(page, [
          'Corridas Realizadas',
          'Corridas não Reconciliadas',
        ]);
        return true;
      }
    }

    // Perfil Gestor RH
    if (hasRole(roles, 'rhadministrator')) {
      if (page.key === 'Adm') {
        filterMenuKeys(page, ['RH']);
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <AppBar position="static" sx={{ background: '#235391', color: 'white' }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              justifyContent: 'end',
              gap: '20px',
              padding: '5px',
            }}
          >
            <Typography>{user.user?.name.split(' ')[0]}</Typography>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton
                  onClick={(event) => setAnchorElUser(event.currentTarget)}
                  sx={{ p: 0 }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={userIcon}
                    sx={{ width: 36, height: 36 }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}
              >
                {settings
                  .filter((setting) => !(onlyExit && !(setting.key === 'Sair')))
                  .map((setting) => (
                    <MenuItem key={setting.key} onClick={setting.onClick}>
                      <Typography textAlign="center">{setting.key}</Typography>
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
          </Box>
        </Container>
      </AppBar>

      <AppBar position="static" sx={{ background: '#fff', color: 'black' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <img src={logo} alt="" style={{ width: '75px' }} />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => setAnchorElNav(event.currentTarget)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={() => setAnchorElNav(null)}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages
                  .filter((page) => hasAccessTo(page))
                  .map((page, index) => {
                    const { menuKeys, ...pageProps } = page;

                    return (
                      <Link
                        key={index}
                        to={page.href}
                        style={{ textDecoration: 'none', color: '#000' }}
                      >
                        <MenuItem
                          {...pageProps}
                          onClick={() => {
                            setAnchorElNav(null);
                            if (menuKeys) {
                              setDrawerContent(page);
                              setShowDrawerSubMenu((prev) => !prev);
                            }
                          }}
                        >
                          <Typography textAlign="center">{page.key}</Typography>
                        </MenuItem>
                      </Link>
                    );
                  })}
              </Menu>
            </Box>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              <img style={{ height: '2rem' }} src={logo} alt="logo" />
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {pages
                .filter((page) => hasAccessTo(page))
                .map((page, index) => {
                  const { menuKeys, ...pageProps } = page;

                  return (
                    <Button
                      key={index}
                      size="small"
                      style={
                        drawerContent === page && showDrawerSubMenu
                          ? {
                              textDecoration: 'underline #EE7330',
                              textDecorationThickness: '3px',
                            }
                          : {}
                      }
                      {...pageProps}
                      onClick={() => {
                        setAnchorElNav(null);
                        if (menuKeys) {
                          setDrawerContent(page);
                          setShowDrawerSubMenu((prev) => !prev);
                        }
                      }}
                      sx={{
                        my: 2,
                        mx: 0.5,
                        minWidth: 'unset',
                        color: 'black',
                        textTransform: 'capitalize',
                        fontSize: '15px',
                      }}
                    >
                      {page.key}
                    </Button>
                  );
                })}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Modal
        open={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-login" sx={modalContentStyle}>
          <img
            className="cancel"
            src={WexpIconOrangeCancel}
            alt="Wexp Icon orange cancel"
            onClick={() => setShowLogoutModal(false)}
          />
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '35px',
              padding: '20px',
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Tem certeza que deseja sair?
            </Typography>
            <Grid>
              <Button
                onClick={activeAccount ? confirmSsoLogout : confirmLogout}
                sx={{ marginRight: '40px' }}
                variant="contained"
              >
                Confirmar
              </Button>
              <Button onClick={() => setShowLogoutModal(false)}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <NavbarDrawer
        isOpen={showDrawerSubMenu}
        onClose={() => setShowDrawerSubMenu(false)}
        drawerContent={drawerContent.menuKeys}
      />
    </>
  );
};

export default ResponsiveAppBar;
