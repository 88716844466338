import { Box, Checkbox, InputLabel } from '@mui/material';

const FormCheckbox = ({ label, field }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
      <InputLabel htmlFor={field.name}>{label}</InputLabel>
      <Box display={'flex'} alignItems={'center'}>
        <Checkbox
          id={field.name}
          checked={field.value}
          onChange={(e) => field.onChange(e.target.checked)}
        />
        {field.value ? 'Ativo' : 'Inativo'}
      </Box>
    </Box>
  );
};

export default FormCheckbox;
