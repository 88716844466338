import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerRange from '../../../../components/DatePickerRange';
import ResponsiveAppBar from '../../../../components/NavBar';
import CustomTable from '../../../../components/Table/CustomTable';
import InfoCards from '../../../../components/InfoCards';
import {
  GetDetailEconomySavings,
  GetListEconomySavings,
} from '../../../../store/_Entities/SavingsMemoryCalculationReport';
import CustomDataGrid from '../../../../components/grids/components/CustomDataGrid';
import Footer from '../../../../components/Footer';
import HeaderSearchSelect from '../../../../components/HeaderSearchSelect';
import validateDate from '../../../../utils/validateDate';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import {numberToCurrency, stringNumberToCurrency} from '../../../../utils/format';

const SavingsMemoryCalculation = () => {
  const { user, company, savingsMemoryCalculationReport } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();

  const userID = user.authData.sub;

  const [selectedRideID, setSelectedRideID] = useState(0);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyInputValue, setCompanyInputValue] = useState('');

  const [data, setData] = useState([]);
  const [dataTicketRide, setdataTicketRide] = useState([]);
  const [dataDiagnosticProducts, setDataDiagnosticProducts] = useState([]);

  const [dataEstimateBID, setDataEstimateBID] = useState([]);
  const [dataDiagAjustado, setDataDiagAjustado] = useState([]);
  // const [dataDesvio, setDataDesvio] = useState([]);
  const [dataWarning, setDataWarning] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const fetchReports = () => {
    const startDateFormatted = startDate.toLocaleDateString('pt-BR');
    const endDateFormatted = endDate.toLocaleDateString('pt-BR');

    const request = {
      userID,
      companyID: selectedCompany,
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    };

    console.log(request);

    dispatch(GetListEconomySavings(request));
  };

  useEffect(() => {
    if (
      selectedCompany > 0 &&
      validateDate(startDate) &&
      validateDate(endDate)
    ) {
      fetchReports();
    }
  }, [selectedCompany, startDate, endDate]);

  useEffect(() => {
    if (selectedRideID > 0) dispatch(GetDetailEconomySavings(selectedRideID));
  }, [selectedRideID]);

  useEffect(() => {
    if (
      savingsMemoryCalculationReport?.savingsMemoryCalculationReports &&
      selectedCompany > 0
    ) {
      setData(savingsMemoryCalculationReport.savingsMemoryCalculationReports);
    }
  }, [
    selectedCompany,
    savingsMemoryCalculationReport.savingsMemoryCalculationReports,
  ]);

  useEffect(() => {
    if (
      selectedRideID > 0 &&
      savingsMemoryCalculationReport?.detailSavingsResultReport
    ) {
      console.log('effect details');
      console.log(savingsMemoryCalculationReport?.detailSavingsResultReport);

      setRidePriceDiagnosticAdjust(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .ridePriceDiagnosticAdjust
      );

      setAdjustPercentual(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .adjustPercentual
      );
      setReceiptPrice(
        savingsMemoryCalculationReport?.detailSavingsResultReport.receipt.price
      ); // Receipt.price
      setTotalSavingAdjust(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .totalSavingAdjust
      );
      setSavingPercentAdjust(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .savingPercentAdjust
      );
      setFeePriceDiagnostic(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .feePriceDiagnostic
      );
      setSelectedEstimativePercentAdjust(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .selectedEstimativePercentAdjust
      ); //SelectedEstimative.Fee
      setFeeSavingPercentalAdjust(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .feeSavingPercentalAdjust
      );
      setFeePriceAdjust(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .feePriceAdjust
      );

      setdataTicketRide([
        savingsMemoryCalculationReport?.detailSavingsResultReport.receipt,
      ]);

      setDataDiagnosticProducts(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .diagnosticProductsSumarized
      );
      setDataEstimateBID(
        savingsMemoryCalculationReport?.detailSavingsResultReport.estimatives
      );
      setDataDiagAjustado(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .avaliableProductsSumarized
      );

      setDataWarning(
        savingsMemoryCalculationReport?.detailSavingsResultReport.warning
      );

      setDesvioCategory(
        savingsMemoryCalculationReport?.detailSavingsResultReport?.selectedEstimative?.product?.category 
      );
      setDesvioProduct(
        savingsMemoryCalculationReport?.detailSavingsResultReport?.selectedEstimative?.product?.description
      );
      setDesvioBID(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .selectedEstimative.priceAdjust
      );
      setDesvioCC(
        savingsMemoryCalculationReport?.detailSavingsResultReport.receipt
          .priceAdjust
      );
      setDesvioDesvio(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .adjustPercentual
      );
      setDesvioCD(
        savingsMemoryCalculationReport?.detailSavingsResultReport
          .ridePriceDiagnosticAdjust
      );
    }
  }, [savingsMemoryCalculationReport?.detailSavingsResultReport]);

  const columns = [
    {
      field: 'rideID',
      headerName: 'ID da Corrida',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'currency',
      headerName: 'Moeda',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'company',
      headerName: 'Empresa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'startAddress',
      headerName: 'Endereço de Partida',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: 'Data de Partida',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'endAddress',
      headerName: 'Endereço de Chegada',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'viewReportSaving',
      headerName: 'Ver Relatório Saving',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => setSelectedRideID(params.row.rideID)}>
          <FileOpenIcon />
        </IconButton>
      ),
    },
  ];

  const exportHeaders = {
    rideID: 'ID da Corrida',
    name: 'Nome',
    currency: 'Moeda',
    company: 'Empresa',
    startAddress: 'Endereço de Partida',
    startDate: 'Data de Partida',
    endAddress: 'Endereço de Chegada',
    viewReportSaving: 'Ver Relatório Saving',
  };

  const columnsTicketRide = [
    {
      field: 'rideID',
      headerName: 'ID da Corrida',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'transactionID',
      headerName: 'Id da Transação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'productID',
      headerName: 'Id do Produto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Preço',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      valueFormatter: (params) => numberToCurrency(params?.value),
      flex: 1,
    },
    {
      field: 'currency',
      headerName: 'Moeda',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'distancy',
      headerName: 'Distância(KM)',
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => numberToCurrency(params?.value),
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'duration',
      headerName: 'Duração',
      headerAlign: 'center',
      align: 'center',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'startAddress',
      headerName: 'Endereço de Partida',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: 'Data de Partida',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'endAddress',
      headerName: 'Endereço de Chegada',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
  ];

  const columnsDiag = [
    {
      field: 'providerDescription',
      headerName: 'Provedor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
      valueGetter: (params) =>
        params.row.total ? 'Total' : params.row.product.providerDescription,
    },
    {
      field: 'productID',
      headerName: 'ID Produto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) =>
        params.row.total ? '' : params.row.product.description,
    },
    {
      field: 'percentageAdjust',
      headerName: 'Porcentagem',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      valueFormatter: (params) => stringNumberToCurrency(params?.value),
      flex: 1,
      // valueGetter: (params) => params.row.percentageAdjust
    },
    {
      field: 'feeAdjust',
      headerName: 'Comissão',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      valueFormatter: (params) => stringNumberToCurrency(params?.value),
      flex: 1,
      // valueGetter: (params) => params.row.feeAdjust
    },
  ];

  const columnsAdjustedDiag = [
    {
      field: 'providerDescription',
      headerName: 'Provedor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
      valueGetter: (params) =>
        params.row.total ? 'Total' : params.row.product.providerDescription,
    },
    {
      field: 'productID',
      headerName: 'ID Produto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) =>
        params.row.total ? '' : params.row.product.description,
    },
    {
      field: 'percentageRound',
      headerName: 'Porcentagem',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      valueFormatter: (params) => stringNumberToCurrency(params?.value),
      flex: 1,
    },
    {
      field: 'priceAdjust',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      valueFormatter: (params) => numberToCurrency(params?.value),
      flex: 1,
    },
    {
      field: 'feeRound',
      headerName: 'Comissão',
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => stringNumberToCurrency(params?.value),
      minWidth: 100,
      flex: 1,
    },
  ];

  const columnsBid = [
    {
      field: 'providerDescription',
      headerName: 'Provedor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row.product.providerDescription,
    },
    {
      field: 'productID',
      headerName: 'ID Produto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row.product.description,
    },
    {
      field: 'waitingTime',
      headerName: 'Tempo de Espera',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'priceAdjust',
      headerName: 'Estimativa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      valueFormatter: (params) => numberToCurrency(params?.value),
      flex: 1,
    },
    {
      field: 'feeAdjust',
      headerName: 'Comissão',
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => stringNumberToCurrency(params?.value),
      minWidth: 100,
      flex: 1,
    },
  ];

  const [desvioCategory, setDesvioCategory] = useState('');
  const [desvioProduct, setDesvioProduct] = useState('');
  const [desvioBID, setDesvioBID] = useState('');
  const [desvioCC, setDesvioCC] = useState('');
  const [desvioDesvio, setDesvioDesvio] = useState('');
  const [desvioCD, setDesvioCD] = useState('');

  const dataDesvio = [
    {
      desvioID: 1,
      description: 'Categoria Ultilizada',
      value: desvioCategory == undefined ? 'null' : `${desvioCategory}`, //SelectedEstimative.Product.Category
    },
    {
      desvioID: 2,
      description: 'Produto Utilizado',
      value: desvioProduct == undefined ? 'null' : `${desvioProduct}`, //SelectedEstimative.Product.Description
    },
    {
      desvioID: 3,
      description: 'BID - cotação capturada do produto',
      value: desvioBID == undefined ? 'null' : `${numberToCurrency(desvioBID)}`, //Model.SelectedEstimative.Price.ToString("F2")
    },
    {
      desvioID: 4,
      description: 'CC - Corrida Cobrada',
      value: desvioCC == undefined ? 'null' : `${numberToCurrency(desvioCC)}`, //Receipt.price.ToString("F2")
    },
    {
      desvioID: 5,
      description: 'Desvio',
      value: desvioDesvio == undefined ? 'null' : `${desvioDesvio}%`, //(Math.Round((Model.Adjust * 100), 2)) %
    },
    {
      desvioID: 6,
      description:
        'Valor da Corrida Diagnóstico (CD) (Total do DIAG AJUSTADO + DESVIO)',
      value: desvioCD == undefined ? 'null' : `${numberToCurrency(desvioCD)}`, //RidePriceDiagnostic.ToString("F2")
    },
  ];

  const columnsDesvio = [
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'left',
      align: 'left',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 5,
      flex: 1,
    },
  ];

  const columnsWarning = [
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'left',
      align: 'left',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Valor',
      headerAlign: 'left',
      align: 'left',
      minWidth: 50,
      valueFormatter: (params) => numberToCurrency(params?.value),
      flex: 1,
    },
  ];

  const exportHeadersTicketRide = {
    rideID: 'ID da Corrida',
    transactionID: 'Id da Transação',
    productID: 'Id do Produto',
    name: 'Nome',
    price: 'Preço',
    currency: 'Moeda',
    distancy: 'Distância(KM)',
    duration: 'Duração',
    startAddress: 'Endereço de Partida',
    startDate: 'Data de Partida',
    endAddress: 'Endereço de Chegada',
  };

  const [ridePriceDiagnosticAdjust, setRidePriceDiagnosticAdjust] =
    useState('0');
  const [adjustPercentual, setAdjustPercentual] = useState(0.0);
  const [receiptPrice, setReceiptPrice] = useState(0.0); // Receipt.price
  const [totalSavingAdjust, setTotalSavingAdjust] = useState(0.0); //Economia percentual
  const [savingPercentAdjust, setSavingPercentAdjust] = useState(''); //Economia percentual
  const [feePriceDiagnostic, setFeePriceDiagnostic] = useState(0.0);
  const [selectedEstimativePercentAdjust, setSelectedEstimativePercentAdjust] =
    useState(0.0); //SelectedEstimative.Fee
  const [feeSavingPercentalAdjust, setFeeSavingPercentalAdjust] = useState(0.0);
  const [feePriceAdjust, setFeePriceAdjust] = useState(0.0);

  const cardsData = [
    {
      title: 'Valor da Corrida Diagnóstico (CD)',
      value: `${numberToCurrency(ridePriceDiagnosticAdjust) || 0}%`,
      percentual: `${numberToCurrency(adjustPercentual) || 0}%`,
    }, // RidePriceDiagnostic
    {
      title: 'Valor da Corrida Cobrada (CC)',
      value: `R$${numberToCurrency(receiptPrice) || 0}`,
    },
    {
      title: 'Economia',
      value: `R$${numberToCurrency(totalSavingAdjust) || 0}`,
      percentual: `${numberToCurrency(savingPercentAdjust) || '0'}%`,
    }, //TotalSaving
    {
      title: 'Valor da Comissão Diagnóstico',
      value: `${numberToCurrency(feePriceDiagnostic) || 0}%`,
    },
    {
      title: 'Valor da Comissão Cobrada',
      value: `R$${numberToCurrency(selectedEstimativePercentAdjust) || 0}`,
      percentual: `${numberToCurrency(feePriceAdjust) || '0'}%`,
    }, //FeePrice
    {
      title: 'Economia Comissão',
      value: `${numberToCurrency(feeSavingPercentalAdjust) || 0}%`,
    }, //FeePriceDiagnostic -> Model.FeeSaving / Model.FeePriceDiagnostic
  ];

  const GridTableHeader = ({ title, description }) => (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2">{description}</Typography>
    </Box>
  );

  const mockDashData = [
    {
      rideID: 1,
      provider: 'Uber',
      productID: 'UberX',
      percentage: '15%',
      comission: '10%',
      waitingTime: 5,
      estimate: '15,50',
      value: '25,50',
      description: 'ECONOMIA DRIVER',
    },
    {
      rideID: 2,
      provider: '99',
      productID: '99POP',
      percentage: '10%',
      comission: '5%',
      waitingTime: 10,
      estimate: '20,50',
      value: '30,50',
      description: 'ECONOMIA DRIVER',
    },
    {
      rideID: 3,
      provider: 'Uber',
      productID: 'UberX',
      percentage: '15%',
      comission: '10%',
      waitingTime: 5,
      estimate: '15,50',
      value: '25,50',
      description: 'ECONOMIA DRIVER',
    },
    {
      rideID: 4,
      provider: '99',
      productID: '99POP',
      percentage: '10%',
      comission: '5%',
      waitingTime: 10,
      estimate: '20,50',
      value: '30,50',
      description: 'ECONOMIA DRIVER',
    },
  ];

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Relatório de Economias por Transação</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <Box sx={{ display: 'flex', gap: 5 }}>
                <HeaderSearchSelect
                  label={'Empresas'}
                  onChange={setSelectedCompany}
                  inputValue={companyInputValue}
                  inputChange={setCompanyInputValue}
                  menuItems={company.companies.map((company) => {
                    return {
                      value: company.companyID,
                      label: company.giveName,
                    };
                  })}
                />
                <DatePickerRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={{ startDate: setStartDate, endDate: setEndDate }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.rideID}
          exportHeaders={exportHeaders}
          hideFooter={true}
        />
        
        {savingsMemoryCalculationReport?.loading && selectedRideID > 0 && (
          <Box width='100%' display='flex' justifyContent='center'>
            <CircularProgress />
          </Box>
        )}
        {selectedRideID > 0 && !savingsMemoryCalculationReport.loading ?  (
          <>
            <InfoCards cardsData={cardsData} />
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <GridTableHeader
                title="Ticket da corrida:"
                description="comprovante gerado automaticamente pelo fornecedor"
              />
            </Box>
            <CustomDataGrid
              rows={dataTicketRide}
              columns={columnsTicketRide}
              props={{
                getRowId: (row) => row.receiptID
              }}
            />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <GridTableHeader
                  title="DIAG -"
                  description="tabela MIX DE PRODUTOS REFERÊNCIA para o cálculo de economia"
                />
                <CustomDataGrid
                  rows={dataDiagnosticProducts}
                  columns={columnsDiag}
                  props={{
                    getRowId: (row) => row.rowID,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GridTableHeader
                  title="BID -"
                  description="cotações capturadas"
                />
                <CustomDataGrid
                  rows={dataEstimateBID}
                  columns={columnsBid}
                  props={{
                    getRowId: (row) => row.rideEstimativeID
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GridTableHeader
                  title="DIAG AJUSTADO -"
                  description="pela disponibilidade de produtos (DIAG x BID)"
                />
                <CustomDataGrid
                  rows={dataDiagAjustado}
                  columns={columnsAdjustedDiag}
                  props={{
                    getRowId: (row) => row.rowID,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GridTableHeader
                  title="DESVIO -"
                  description="variação entre cotação (BID) x corrida cobrada (CC)"
                />
                <CustomDataGrid
                  rows={dataDesvio}
                  columns={columnsDesvio}
                  props={{
                    getRowId: (row) => row.desvioID,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <GridTableHeader title="Alertas" description="" />
                <CustomDataGrid
                  rows={dataWarning}
                  columns={columnsWarning}
                  props={{
                    getRowId: (row) => row.warningTypeID,
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Footer />
      </Container>
    </>
  );
};

export default SavingsMemoryCalculation;