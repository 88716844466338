import { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Box, Button, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GetCostCenters } from '../../../../../store/_Entities/CostCenter';
import { GetUsersByCostCenter } from '../../../../../store/_Entities/User';
import { useNavigate, useParams } from 'react-router-dom';
import ResponsiveAppBar from '../../../../NavBar';
import Footer from '../../../../Footer';
import CustomDataGrid from '../../../../grids/components/CustomDataGrid';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {
  CreatePolicyGroupUser,
  DeletePolicyGroupUser,
  GetPolicyGroupUsers,
} from '../../../../../store/_Entities/Policy';
import ModalConfirm from '../../../../ModalConfirm';
import UsersForm from './UsersForm';

const PolicyUsers = () => {
  const { companyID, policyGroupID } = useParams();
  const { policy } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({ show: false, type: null });
  const [selectedID, setSelectedID] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyID: companyID,
      policyGroupID: policyGroupID,
      userID: '',
      costCenterID: '',
    },
  });

  const selectedCostCenter = useWatch({
    control,
    name: 'costCenterID',
  });

  const onSubmit = (formData) => {
    const data = {
      ...formData,
      policyGroupID: +policyGroupID,
      userID: formData.userID === 'all' ? null : formData.userID,
    };
    dispatch(CreatePolicyGroupUser(data));
    console.log(data);
    setFormSubmitted(true);
    setShowModal(false);
  };

  const handleDeleteUser = () => {
    dispatch(DeletePolicyGroupUser(selectedID));
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(GetPolicyGroupUsers(policyGroupID));
  }, [formSubmitted]);

  const userColumns = [
    {
      field: 'costCenter_Description',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'user_Name',
      headerName: 'Usuário',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      valueGetter: (params) => params.row.user_Name || 'TODOS',
    },
    {
      field: 'actions',
      headerName: 'Deletar',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setShowModal({ show: true, type: 'deleteUsers' });
            setSelectedID(params.row.policyGroupUsersID);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Box xs={12} py={1} mb={'10px'}>
          <h2>Usuários Políticas</h2>
        </Box>

        <Box>
          <Box>
            <Box
              mt={5}
              minHeight={200}
              boxShadow={'0px 0px 4px 0px #00000040'}
              p={5}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate('/adm/politicas');
                  }}
                >
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setShowModal({ show: true, type: 'addUsers' })}
                >
                  Adicionar
                </Button>
              </Box>

              <UsersForm
                policyGroupID={policyGroupID}
                companyID={companyID}
                showModal={showModal.show && showModal.type === 'addUsers'}
                closeModalAction={setShowModal}
                onSubmit={onSubmit}
              />

              <CustomDataGrid
                columns={userColumns}
                rows={policy.policyGroupUsers || []}
                props={{
                  getRowId: (row) => row.policyGroupUsersID,
                  disableSelectionOnClick: true,
                  loading: policy.loading && !showModal.show,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Footer />

        <ModalConfirm
          show={showModal.show && showModal.type === 'deleteUsers'}
          handleClose={() => setShowModal(false)}
          title={'Deseja Excluir o Centro de Custo/Usuário?'}
          submit={handleDeleteUser}
          loading={policy.loading}
        >
          <Box textAlign={'center'}>
            <Typography variant="subtitle">
              O Centro de Custo/Usuário será excluido permanentemente.
            </Typography>
          </Box>
        </ModalConfirm>
      </Container>
    </>
  );
};

export default PolicyUsers;
