import ResponsiveAppBar from '../components/NavBar';
import {
  Grid,
  Box,
  Container,
  Typography,
  Checkbox,
  Button,
  InputLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../components/FormInput';
import { UpdateUser } from '../store/_Entities/User';
import Footer from '../components/Footer';

const Account = () => {
  const { user } = useSelector((state) => state.entities);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const transformedData = {
      companyID: user.user.companyID,
      costCenterID: user.user.costCenterID,
      name: data.name,
      registration: user.authData.sub,
      email: user.user.email,
      phone: user.user.phone,
      origin: 'Portal 2.0',
      originID: user.authData.sub,
    };
    dispatch(UpdateUser(user.authData.sub, transformedData));
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: user.user.name,
    },
  });

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth="md" sx={{ paddingBottom: '20px' }}>
        <Box mt={4}>
          <Typography variant="h4">Dados do Usuário</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={1}
              mt={2}
              boxShadow={'0px 0px 4px 0px #00000040'}
              p={5}
              borderRadius={2}
              alignItems={'center'}
            >
              <Grid item xs={12} md={2}>
                <Typography variant="h6">Nome:</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      id="name-checkbox"
                      field={field}
                      errors={errors.name}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} />

              <Grid item xs={12} md={2}>
                <Typography variant="h6">Matrícula:</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2">{user.authData.sub}</Typography>
              </Grid>
              <Grid item xs={4} />

              <Grid item xs={12} md={2}>
                <Typography variant="h6">Centro de Custo:</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2">{user.user.costCenter}</Typography>
              </Grid>
              <Grid item xs={4} />

              <Grid item xs={12} md={2}>
                <Typography variant="h6">E-mail:</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2">{user.user.email}</Typography>
              </Grid>
              <Grid item xs={4} />

              <Grid item xs={12} md={2}>
                <Typography variant="h6">Telefone:</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2">{user.user.phone}</Typography>
              </Grid>
              <Grid item xs={4} />
            </Grid>
            <Box display={'flex'} justifyContent={'flex-end'} gap={2} mt={2}>
              <Button type="submit" variant="contained" color="primary">
                Salvar
              </Button>
            </Box>
          </form>
          <Box mt={2} width={'110%'}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Alertas E-mail</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Geral</Typography>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={1} md={0.5}>
                  <Checkbox id="general-alert-checkbox" />
                </Grid>
                <Grid item xs={11} md={11.5}>
                  <InputLabel
                    htmlFor="general-alert-checkbox"
                    sx={{
                      color: 'black !important',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    <Typography variant="body2" component="div">
                      <Box display={'inline'} fontWeight={'bold'}>
                        Conformidade:{' '}
                      </Box>
                      E-mail enviado automaticamente toda vez que uma despesa
                      gera um alerta de não conformidade.
                    </Typography>
                  </InputLabel>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Driver</Typography>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={1} md={0.5}>
                  <Checkbox id="budget-alert-checkbox" />
                </Grid>
                <Grid item xs={11} md={11.5}>
                  <InputLabel
                    htmlFor="budget-alert-checkbox"
                    sx={{
                      color: 'black !important',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    <Typography variant="body2" component="div">
                      <Box display={'inline'} fontWeight={'bold'}>
                        % Orçamento:{' '}
                      </Box>
                      E-mail enviado automaticamente quando o orçamento do
                      centro de custo está próximo do limite.
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={1} md={0.5}>
                  <Checkbox id="exceeded-budget-checkbox" />
                </Grid>
                <Grid item xs={11} md={11.5}>
                  <InputLabel
                    htmlFor="exceeded-budget-checkbox"
                    sx={{
                      color: 'black !important',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    <Typography variant="body2" component="div">
                      <Box display={'inline'} fontWeight={'bold'}>
                        Orçamento Excedido:{' '}
                      </Box>
                      E-mail enviado automaticamente a cada cadastro de uma nova
                      despesa após o orçamento excedido.
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={1} md={0.5}>
                  <Checkbox id="outside-app-checkbox" />
                </Grid>
                <Grid item xs={11} md={11.5}>
                  <InputLabel
                    htmlFor="outside-app-checkbox"
                    sx={{
                      color: 'black !important',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    <Typography variant="body2" component="div">
                      <Box display={'inline'} fontWeight={'bold'}>
                        Corrida por Fora:{' '}
                      </Box>
                      E-mail enviado automaticamente toda vez que uma corrida é
                      solicitada sem utilizar o aplicativo da WEXP.
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={1} md={0.5}>
                  <Checkbox id="ride-finished-checkbox" />
                </Grid>
                <Grid item xs={11} md={11.5}>
                  <InputLabel
                    htmlFor="ride-finished-checkbox"
                    sx={{
                      color: 'black !important',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    <Typography variant="body2" component="div">
                      <Box display={'inline'} fontWeight={'bold'}>
                        Alerta de corrida:{' '}
                      </Box>
                      E-mail enviado automaticamente toda vez que uma corrida é
                      finalizada por um colaborador.
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={1} md={0.5}>
                  <Checkbox id="driver-acceptance-checkbox" />
                </Grid>
                <Grid item xs={11} md={11.5}>
                  <InputLabel
                    htmlFor="driver-acceptance-checkbox"
                    sx={{
                      color: 'black !important',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    <Typography variant="body2" component="div">
                      <Box display={'inline'} fontWeight={'bold'}>
                        Alerta de aceite de viagem pelo motorista:{' '}
                      </Box>
                      E-mail enviado automaticamente toda vez que o motorista
                      aceita a viagem.
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={1} md={0.5}>
                  <Checkbox id="arrival-alert-checkbox" />
                </Grid>
                <Grid item xs={11} md={11.5}>
                  <InputLabel
                    htmlFor="arrival-alert-checkbox"
                    sx={{
                      color: 'black !important',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    <Typography variant="body2" component="div">
                      <Box display={'inline'} fontWeight={'bold'}>
                        Alerta de chegada do veículo no local de origem:{' '}
                      </Box>
                      E-mail enviado automaticamente toda vez que o motorista
                      chega no local de origem.
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={1} md={0.5}>
                  <Checkbox id="cancellation-alert-checkbox" />
                </Grid>
                <Grid item xs={11} md={11.5}>
                  <InputLabel
                    htmlFor="cancellation-alert-checkbox"
                    sx={{
                      color: 'black !important',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  >
                    <Typography variant="body2" component="div">
                      <Box display={'inline'} fontWeight={'bold'}>
                        Alerta de cancelamento da viagem pelo motorista:{' '}
                      </Box>
                      E-mail enviado automaticamente toda vez que o motorista
                      cancela a viagem.
                    </Typography>
                  </InputLabel>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography variant="h6">Expen e KM</Typography>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={1} md={0.5}>
                  <Checkbox id="report-reminder-checkbox" />
                </Grid>
                <Grid item xs={11} md={11.5}>
                  <InputLabel
                    htmlFor="report-reminder-checkbox"
                    sx={{
                      color: 'black !important',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                    }}
                    color="secondary"
                  >
                    <Typography variant="body2" component="div">
                      <Box display={'inline'} fontWeight={'bold'}>
                        Lembrete de relatórios aguardando aprovação:{' '}
                      </Box>
                      E-mail enviado automaticamente toda vez que um relatório
                      está pendente para sua aprovação.
                    </Typography>
                  </InputLabel>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer />
      </Container>
    </>
  );
};

export default Account;
