import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/ExpenseType';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  expenses: [],
};

export const slice = createSlice({
  name: 'expenseType',
  initialState,
  reducers: {
    EXPENSE_TYPE_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    EXPENSE_TYPE_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    EXPENSE_TYPE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.expenses = action.payload.result;
    },
    EXPENSE_TYPE_CREATE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.expenses = [...state.expenses, action.payload.result];
    },
    EXPENSE_TYPE_INDEX_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.expenses = state.expenses.map((expense) =>
        expense.expenseTypeID === action.payload.result.expenseTypeID
          ? { ...expense, index: action.payload.result.index }
          : expense
      );
    },
    EXPENSE_TYPE_DELETE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.expenses = state.expenses.filter(
        (expense) =>
          expense.expenseTypeID !== action.payload.result.expenseTypeID
      );
    },
  },
});

export const {
  EXPENSE_TYPE_REQUESTED,
  EXPENSE_TYPE_FAILED,
  EXPENSE_TYPE_SUCCESS,
  EXPENSE_TYPE_CREATE_SUCCESS,
  EXPENSE_TYPE_INDEX_SUCCESS,
  EXPENSE_TYPE_DELETE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetExpenseTypes = (companyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.ExpenseType.GetExpenseTypes(companyID),
    },
    onAction: {
      onStart: EXPENSE_TYPE_REQUESTED.type,
      onError: EXPENSE_TYPE_FAILED.type,
      onSuccess: EXPENSE_TYPE_SUCCESS.type,
    },
  });
};

export const CreateExpenseType = (data: any) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.ExpenseType.ExpenseType,
      data,
    },
    onAction: {
      onError: EXPENSE_TYPE_FAILED.type,
      onSuccess: EXPENSE_TYPE_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const UpdateExpenseTypeIndex = (
  expenseTypeID: number,
  index: number
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url:
        Endpoints.ExpenseType.ExpenseType +
        `?id=${expenseTypeID}&index=${index}`,
    },
    onAction: {
      onError: EXPENSE_TYPE_FAILED.type,
      onSuccess: EXPENSE_TYPE_INDEX_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteExpenseType = (expenseTypeID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.ExpenseType.DeleteExpenseType(expenseTypeID),
    },
    onAction: {
      onError: EXPENSE_TYPE_FAILED.type,
      onSuccess: EXPENSE_TYPE_DELETE_SUCCESS.type,
      showToast: true,
    },
  });
};
