import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IState,
  ICreateExpenseRequest,
  ICreateKmRequest,
} from './interfaces/Expenses';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  expenses: [],
  selectedExpense: {
    expenseID: '',
    expenseTypeID: 0,
    expenseType: '',
    expenseTypeActive: 0,
    userID: '',
    reportID: 0,
    costCenterID: 0,
    date: '',
    price: '',
    currency: '',
    paymentTypeID: 0,
    description: '',
    file: [],
    fileName: [],
    project: '',
    projectDescription: '',
    reason: '',
    reasonDescription: '',
    justification: '',
    refund: '',
    expenseAddressResults: [],
    distance: 0,
    projectType: 0,
    reasonType: 0,
    justificationType: 0,
    refundType: 0,
    providerID: 0,
  },
  fields: [],
  file: '',
};

export const slice = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    EXPENSES_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    EXPENSES_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
      state.file = null;
    },
    EXPENSES_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.expenses = action.payload.result;
    },
    EXPENSES_DELETE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.expenses = state.expenses.filter(
        (expense) => expense.expenseID !== action.payload.result.expenseID
      );
    },
    EXPENSES_CREATE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    EXPENSES_GET_EDIT: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.selectedExpense = action.payload.result;
    },
    EXPENSES_EDIT_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    EXPENSES_FIELDS_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.fields = action.payload.result;
    },
    EXPENSES_FILE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.file = action.payload.result;
    },
  },
});

export const {
  EXPENSES_REQUESTED,
  EXPENSES_FAILED,
  EXPENSES_SUCCESS,
  EXPENSES_DELETE_SUCCESS,
  EXPENSES_CREATE_SUCCESS,
  EXPENSES_GET_EDIT,
  EXPENSES_EDIT_SUCCESS,
  EXPENSES_FIELDS_SUCCESS,
  EXPENSES_FILE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetExpenses = () => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Expenses.GetExpenses,
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_SUCCESS.type,
    },
  });
};

export const GetPendingExpenses = (userID: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Expenses.GetPending(userID),
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_SUCCESS.type,
    },
  });
};

export const RemoveExpense = (expenseID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.Expenses.RemoveExpense(expenseID),
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_DELETE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const CreateExpense = (data: ICreateExpenseRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Expenses.Expense,
      data: data,
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const CreateKmExpense = (data: ICreateKmRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.Expenses.KmExpense,
      data: data,
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const EditExpense = (expenseID: string, data: ICreateExpenseRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.Expenses.EditExpense(expenseID),
      data: data,
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_CREATE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteExpense = (expenseID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'DELETE',
      url: Endpoints.Expenses.DeleteExpense(expenseID),
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_DELETE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const GetEditExpense = (expenseID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Expenses.GetEdit(expenseID),
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_GET_EDIT.type,
    },
  });
};

export const EditKmExpense = (expenseID: string, data: ICreateKmRequest) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.Expenses.EditKm(expenseID),
      data: data,
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_EDIT_SUCCESS.type,
      showToast: true,
    },
  });
};

export const GetFields = () => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Expenses.GetField,
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_FIELDS_SUCCESS.type,
    },
  });
};

export const GetFileByExpenseID = (expenseID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.Expenses.GetFileByExpenseID(expenseID),
    },
    onAction: {
      onStart: EXPENSES_REQUESTED.type,
      onError: EXPENSES_FAILED.type,
      onSuccess: EXPENSES_FILE_SUCCESS.type,
    },
  });
};
