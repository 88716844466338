import { Box, InputLabel, TextField, Typography } from '@material-ui/core';

const FormInput = ({ label, number, disabled, placeholder, field, errors }) => {
  return (
    <Box>
      <InputLabel htmlFor={field.name}>{label}</InputLabel>
      <TextField
        type={number ? 'number' : 'text'}
        disabled={disabled}
        id={field.name}
        variant="outlined"
        {...field}
        placeholder={placeholder || 'Digite...'}
        fullWidth
        error={Boolean(errors)}
        InputProps={{
          style: {
            backgroundColor: disabled ? '#f0f0f0' : 'inherit', // Custom background color for disabled state
          },
        }}
      />
      {errors && (
        <Typography variant="caption" color="error">
          {errors.message}
        </Typography>
      )}
    </Box>
  );
};

export default FormInput;
