import { useState, useEffect } from 'react';
import { useForm, Controller, useWatch, FormProvider } from 'react-hook-form';
import {
  Box,
  Container,
  Stepper,
  Step,
  StepLabel,
  Button,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ResponsiveAppBar from '../../../NavBar';
import Footer from '../../../Footer';
import FormSelect from '../../../FormSelect';
import PoliciesOptionsForm from './PoliciesOptionsForm';
import ModalErrorSuccess from '../../../ModalErrorSuccess';
import { CreatePolicy } from '../../../../store/_Entities/Policy';

const PoliciesForm = () => {
  const { companyID, policyGroupID } = useParams();
  const { policy } = useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState(0);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const form = useForm({
    defaultValues: {
      companyID: companyID,
      policyTypeID: '',
      sourceID: '',
      value: '',
      value_Start: null,
      value_End: null,
      policyGroupID: policyGroupID,
      blocker: 0,
      expenseTypeID: '',
      expenseType: '',
      distance: '',
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
  } = form;

  const selectedVertical = useWatch({
    control,
    name: 'sourceID',
  });

  const selectedPolicyType = useWatch({
    control,
    name: 'policyTypeID',
  });

  const onSubmit = (formData) => {
    const req = {
      ...formData,
      companyID: +companyID,
      policyGroupID: +policyGroupID,
      distance: +formData.distance,
      expenseTypeID: formData.expenseTypeID ? +formData.expenseTypeID : 0,
      value_Start: formData.value_Start
        ? `${new Date(formData.value_Start)
            .getHours()
            .toString()
            .padStart(2, '0')}:${new Date(formData.value_Start)
            .getMinutes()
            .toString()
            .padStart(2, '0')}`
        : null,
      value_End: formData.value_End
        ? `${new Date(formData.value_End)
            .getHours()
            .toString()
            .padStart(2, '0')}:${new Date(formData.value_End)
            .getMinutes()
            .toString()
            .padStart(2, '0')}`
        : null,
    };
    dispatch(CreatePolicy(req));
    setHasSubmitted(true);
  };

  const handleNextStep = async () => {
    let result;
    if (formStep === 0) {
      result = await trigger(['sourceID']);
    }
    if (formStep === 1) {
      result = await trigger(['policyTypeID']);
    }
    if (result) {
      setFormStep((prev) => prev + 1);
    }
  };

  const policyTypeMaping = (verticalID) => {
    if (verticalID === 1) {
      return [
        { id: 1, name: 'Percentual de Desvio da Rota Original' },
        { id: 2, name: 'Valor' },
        { id: 3, name: 'Dia' },
        { id: 4, name: 'Horários' },
        { id: 9, name: 'Distância mínima' },
        { id: 10, name: 'Distância máxima' },
        { id: 12, name: 'Bloqueio de Estados' },
      ];
    }
    if (verticalID === 2) {
      return [
        { id: 5, name: 'Valor da despesa' },
        { id: 6, name: 'Limite de lançamentos por Dia' },
        { id: 8, name: 'Data Retroativa' },
      ];
    }
    if (verticalID === 3) {
      return [{ id: 7, name: 'Pecentual de Desvio da Rota no Km' }];
    }
  };

  useEffect(() => {
    if (hasSubmitted && !policy.loading && policy.success) {
      setShowModal(true);
    }
  }, [policy]);

  useEffect(() => {
    setValue('policyTypeID', '');
  }, [selectedVertical]);

  return (
    <>
      <ResponsiveAppBar />
      <Container>
        <Box xs={12} py={1} mb={'10px'}>
          <h2>Adicionar Política</h2>
        </Box>

        <Box>
          <Stepper
            activeStep={formStep}
            alternativeLabel
            sx={{
              '& .MuiStepIcon-root': {
                fontSize: '2.5rem',
                zIndex: 1,
              },
              '& .MuiStepConnector-root': {
                top: '1.25rem',
                width: 'calc(100% - 100px)',
                marginLeft: 'calc(50% - 160px)',
              },
            }}
          >
            <Step>
              <StepLabel>Vertical</StepLabel>
            </Step>
            <Step>
              <StepLabel>Tipo</StepLabel>
            </Step>
            <Step>
              <StepLabel>Configurações</StepLabel>
            </Step>
          </Stepper>
        </Box>

        <Box>
          <Box display={'flex'} justifyContent={'center'}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ width: '100%', maxWidth: '600px' }}
            >
              <Box
                mt={5}
                minHeight={200}
                boxShadow={'0px 0px 4px 0px #00000040'}
                p={5}
                borderRadius={2}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <FormProvider {...form}>
                  {formStep === 0 && (
                    <>
                      <Box>
                        <h3>Selecionar Vertical</h3>
                      </Box>

                      <Box>
                        <Controller
                          name="sourceID"
                          control={control}
                          rules={{ required: 'Campo obrigatório' }}
                          render={({ field }) => (
                            <Box
                              display={'flex'}
                              justifyContent={'center'}
                              gap={4}
                            >
                              <Button
                                fullWidth
                                onClick={() => field.onChange(1)}
                                variant={
                                  field.value === 1 ? 'contained' : 'outlined'
                                }
                              >
                                Driver
                              </Button>
                              <Button
                                fullWidth
                                onClick={() => field.onChange(2)}
                                variant={
                                  field.value === 2 ? 'contained' : 'outlined'
                                }
                              >
                                Expen
                              </Button>
                              <Button
                                fullWidth
                                onClick={() => field.onChange(3)}
                                variant={
                                  field.value === 3 ? 'contained' : 'outlined'
                                }
                              >
                                Km
                              </Button>
                            </Box>
                          )}
                        />
                      </Box>
                    </>
                  )}
                  {formStep === 1 && (
                    <>
                      <Box>
                        <h3>Selecionar Tipo</h3>
                      </Box>

                      <Box display={'flex'} alignItems={'center'} gap={4}>
                        <Controller
                          name="policyTypeID"
                          control={control}
                          rules={{ required: 'Campo obrigatório' }}
                          render={({ field }) => (
                            <FormSelect
                              field={field}
                              label={'Tipo'}
                              errors={errors.policyTypeID}
                            >
                              {policyTypeMaping(selectedVertical).map(
                                (item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                )
                              )}
                            </FormSelect>
                          )}
                        />
                      </Box>
                    </>
                  )}
                  {formStep === 2 && (
                    <PoliciesOptionsForm
                      selectedPolicyType={selectedPolicyType}
                    />
                  )}
                </FormProvider>
              </Box>
              <Box display={'flex'} gap={2} mt={2} justifyContent={'end'}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    formStep === 0
                      ? navigate('/adm/politicas')
                      : setFormStep((prev) => prev - 1)
                  }
                >
                  {formStep === 0 ? 'Cancelar' : 'Voltar'}
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    formStep === 2 ? handleSubmit(onSubmit)() : handleNextStep()
                  }
                >
                  {formStep === 2 ? (
                    policy.loading ? (
                      <CircularProgress
                        color="inherit"
                        size={25}
                        thickness={5}
                      />
                    ) : (
                      'Salvar'
                    )
                  ) : (
                    'Próximo'
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
        <Footer />
        <ModalErrorSuccess
          show={showModal}
          title={'Política Adicionada com Sucesso!'}
          handleClose={() => {
            setShowModal(false);
            navigate(
              `/adm/politicas/add/${companyID}/${policyGroupID}/policies`
            );
          }}
        />
      </Container>
    </>
  );
};

export default PoliciesForm;
