import ImageIcon from '@mui/icons-material/Image';
import { Checkbox, Tooltip } from '@mui/material';
import { useState } from 'react';
import DataDisplay from '../../components/DataDisplay';
import CustomTable from '../../components/Table/CustomTable';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { numberToCurrency } from '../../utils/format';
import { useSelector } from 'react-redux';
import ImageModal from '../../components/ModalImages';

const ExpenseTable = ({
  data,
  dataDisplayData,
  selectedRows,
  setSelectedRows,
}) => {
  const { approval } = useSelector((state) => state.entities);
  const [selectAll, setSelectAll] = useState(false);
  const [showImageModal, setShowImageModal] = useState({
    show: false,
    image: [],
  });

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    setSelectedRows(
      event.target.checked ? data.map((row) => row.expenseID) : []
    );
  };

  const handleRowCheckboxChange = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleExpenseFile = (row) => {
    const images = row.file.split(',');
    setShowImageModal({ show: true, image: images });
  };

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAllChange}
          inputProps={{ 'aria-label': 'select all expenses' }}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          checked={selectedRows.includes(params.row.expenseID)}
          onChange={() => handleRowCheckboxChange(params.row.expenseID)}
        />
      ),
    },
    {
      field: 'warnings',
      headerName: 'Alerta',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const warnings = params.row?.warnings;

        if (Array.isArray(warnings) && warnings.length > 0) {
          const warningText = warnings
            .map((warning) => warning.expenseWarningType)
            .join(' - ');

          return (
            <Tooltip title={warningText} placement="top">
              <InfoIcon />
            </Tooltip>
          );
        }

        return null;
      },
    },
    {
      field: 'file',
      headerName: 'Arquivo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        return params.row.file !== null ? (
          <IconButton onClick={() => handleExpenseFile(params.row)}>
            <ImageIcon />
          </IconButton>
        ) : null;
      },
    },
    {
      field: 'expenseType',
      headerName: 'Tipo de Despesa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => {
        return numberToCurrency(params.value);
      },
    },
    {
      field: 'currency',
      headerName: 'Moeda',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'dateStr',
      headerName: 'Data',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'paymentType',
      headerName: 'Meio de Pagamento',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'project',
      headerName: 'Projeto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'reason',
      headerName: 'Motivo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'justification',
      headerName: 'Justificativa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'expenseID',
      headerName: 'ID da Despesa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
  ];

  return (
    <>
      <DataDisplay data={dataDisplayData} />
      <CustomTable
        columns={columns}
        data={data}
        getRowId={(row) => row.expenseID}
        loading={approval.loading}
        exportHeaders={{
          warningString: 'Alerta',
          file: 'Arquivo',
          expenseType: 'Tipo de Despesa',
          price: 'Valor',
          currency: 'Moeda',
          dateStr: 'Data',
          description: 'Descrição',
          costCenter: 'Centro de Custo',
          paymentType: 'Meio de Pagamento',
          project: 'Projeto',
          reason: 'Motivo',
          justification: 'Justificativa',
          expenseID: 'ID da Despesa',
        }}
      />
      <ImageModal
        closeModalAction={() => setShowImageModal({ show: false, image: [] })}
        images={showImageModal.image}
        showModal={showImageModal.show}
      />
    </>
  );
};

export default ExpenseTable;
