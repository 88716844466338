import { Box } from '@mui/material';

const FormMenu = ({ height, menuItems, activeItem, setActiveForm }) => {
  return (
    <Box
      sx={{
        ul: {
          listStyle: 'none',
          width: '220px',
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: menuItems.length === 1 ? 'start' : 'space-around',
          padding: '10px',
          boxShadow: '0px 0px 4px 0px #00000040',
          borderRadius: '10px',
        },
        li: {
          fontWeight: 500,
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }}
    >
      <ul className="formMenu-add-ul" style={{ height: height || 'auto' }}>
        {menuItems.map((item, index) => (
          <li
            key={index}
            className="formMenu-add-li"
            style={activeItem === item ? { textDecoration: 'underline' } : {}}
            onClick={() => setActiveForm(item)}
          >
            {item}
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default FormMenu;
