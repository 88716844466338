import { createSlice } from '@reduxjs/toolkit';
import {IState} from './interfaces/WarningType'
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
    loading: false,
    error: false,
    success: false,
    warningTypes:[],
};

export const slice = createSlice({
    name: 'warningType',
    initialState,
    reducers: {
        WARNINGTYPE_REQUESTED: (state,action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        WARNINGTYPE_FAILED: (state,action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        },
        WARNINGTYPE_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.warningTypes = action.payload.result;
        },
}
})

export const { WARNINGTYPE_REQUESTED, WARNINGTYPE_FAILED, WARNINGTYPE_SUCCESS} = slice.actions;

export default slice.reducer;

export const GetAllWarningTypes = () => {
    return apiCallBegan(
        {
            axiosConfig: {
                method: 'GET',
                url: Endpoints.WarningType.GetAllWarningTypes,
            },
            onAction: {
                onStart: WARNINGTYPE_REQUESTED.type,
                onError: WARNINGTYPE_FAILED.type,
                onSuccess: WARNINGTYPE_SUCCESS.type,
            }
        }
    )
}

