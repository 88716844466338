import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import check from '../assets/images/check.png';
import cancel from '../assets/images/cancel.png';
import WexpIconOrangeCancel from '../assets/images/cancel.png';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';

const modalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  maxHeight: '90vh',
  minWidth: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: 'none',
  p: 4,
};

export const ModalAlertSimple = ({
  show,
  text,
  handleCloseSuccess,
  openSuccess,
}) => {
  return (
    <Modal
      open={openSuccess}
      onClose={handleCloseSuccess}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-login" maxWidth={400} sx={modalContentStyle}>
        <img src={cancel} alt="" onClick={handleCloseSuccess} />
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {text}
        </Typography>
        <img src={check} alt="" />
      </Box>
    </Modal>
  );
};

export const ModalWrapper = ({
  closeModalAction,
  showModal,
  title,
  img,
  minWidth = 400,
  width = 'max-content',
  maxWidth = '85vw',
  children,
}) => {
  return (
    <Modal
      open={showModal}
      onClose={closeModalAction}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-login" width={width} maxWidth={maxWidth} minWidth={minWidth} sx={modalContentStyle}>
        <img
          className="cancel"
          src={WexpIconOrangeCancel}
          alt="Wexp Icon orange cancel"
          onClick={closeModalAction}
        />
        {img && (
          <img src={img} width={'60px'} height={'60px'} alt="Modal Image" />
        )}
        <h3 style={{ fontWeight: 'normal', marginBottom: '40px' }}>{title}</h3>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '35px',
          }}
        >
          {children}
        </Grid>
      </Box>
    </Modal>
  );
};
