import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/SourceActiveUserLog';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  activeUserLog: [],
};

export const slice = createSlice({
  name: 'sourceActiveUserLog',
  initialState,
  reducers: {
    USER_EXPEN_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    USER_EXPEN_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    USER_EXPEN_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.activeUserLog = action.payload.result;
    },
  },
});

export const { USER_EXPEN_REQUESTED, USER_EXPEN_FAILED, USER_EXPEN_SUCCESS } =
  slice.actions;

export default slice.reducer;

export const GetUserExpens = (
  companyID: number,
  startDate: string,
  endDate: string,
  sourceID: number
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.SourceActiveUserLog(
        companyID,
        startDate,
        endDate,
        sourceID
      ),
    },
    onAction: {
      onStart: USER_EXPEN_REQUESTED.type,
      onError: USER_EXPEN_FAILED.type,
      onSuccess: USER_EXPEN_SUCCESS.type,
    },
  });
};
