import {
  Container,
  Grid,
  MenuItem,
  Typography,
  TextField,
  InputLabel,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import ResponsiveAppBar from '../../NavBar';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import FormSelect from '../../FormSelect';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import FormInput from '../../FormInput';
import { useEffect, useState } from 'react';
import Footer from '../../Footer';
import { useDispatch, useSelector } from 'react-redux';
import { GetReportsAvailable } from '../../../store/_Entities/ExpensesReport';
import { GetCostCenters } from '../../../store/_Entities/CostCenter';
import { GetReasonsByCompanyId } from '../../../store/_Entities/Reason';
import { GetProjects } from '../../../store/_Entities/Project';
import { inputformatNumber } from '../../../utils/format';
import {
  CreateKmExpense,
  EditKmExpense,
  GetEditExpense,
  GetFields,
} from '../../../store/_Entities/Expenses';
import { useLocation, useParams } from 'react-router-dom';
import { GetExpenseTypes } from '../../../store/_Entities/ExpenseType';
import { GetPaymentTypes } from '../../../store/_Entities/PaymentType';
import { GetCurrency } from '../../../store/_Entities/Currency';
import { useNavigate } from 'react-router-dom';
import HereMap from '../../HereMap';
import ModalErrorSuccess from '../../ModalErrorSuccess';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const ExpensesKmForm = () => {
  const location = useLocation();
  const formType = location.pathname.includes('km-interno')
    ? 'km-interno'
    : 'km-manual';
  const { expenseID } = useParams();
  const { user, expenses, expensesReport, costCenter, reason, project } =
    useSelector((state) => state.entities);
  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const isLoading = [
    user.loading,
    expenses.loading,
    expensesReport.loading,
    costCenter.loading,
    reason.loading,
    project.loading,
    hasFetched,
  ].some(Boolean);

  const [filesPreview, setFilesPreview] = useState([]);

  const methods = useForm({
    defaultValues: {
      userID: '',
      reportID: '',
      costCenterID: '',
      date: '',
      price: '',
      currency: '',
      paymentTypeID: '',
      description: '',
      distance: '',
      projectID: '',
      projectDescription: '',
      reasonID: '',
      reasonDescription: '',
      justification: '',
      file: '',
      origin: '',
      destination: '',
      stops: '',
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFilesPreview(files);
    setValue('file', files);
  };

  const handleRemoveFile = (index) => {
    setFilesPreview((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setValue(
      'file',
      filesPreview.filter((_, i) => i !== index)
    );
  };

  const onSubmit = (data) => {
    const formattedOrigin = {
      address: data.origin.label,
      street: data.origin.street,
      number: data.origin.houseNumber,
      neighborhood: data.origin.district,
      city: data.origin.city,
      state: data.origin.state,
      lat: expenseID ? data.origin.lat : data.origin.coords.lat,
      lng: expenseID ? data.origin.lng : data.origin.coords.lng,
      expenseAddressTypeID: 1,
    };

    let formattedDestination = {};
    if (formType === 'km-manual') {
      formattedDestination = {
        address: data.destination.label,
        street: data.destination.street,
        number: data.destination.houseNumber,
        neighborhood: data.destination.district,
        city: data.destination.city,
        state: data.destination.state,
        lat: expenseID ? data.destination.lat : data.destination.coords.lat,
        lng: expenseID ? data.destination.lat : data.destination.coords.lng,
        expenseAddressTypeID: 2,
      };
    }

    const formData = new FormData();
    formData.append('file', data.file);
    // formData.append('expenseTypeID', data.expenseTypeID);
    formData.append('reportID', data.reportID);
    formData.append('costCenterID', data.costCenterID);
    formData.append('paymentTypeID', 1);
    formData.append('reasonID', data.reasonID);
    formData.append(
      'reasonDescription',
      reason.reasons.find((reason) => reason.reasonID == data.reasonID)
        .description
    );
    formData.append('justification', data.justification);
    formData.append('projectID', data.projectID);
    formData.append(
      'projectDescription',
      project.projects.find((project) => project.projectID == data.projectID)
        .description
    );
    const time = `${data.time
      .getHours()
      .toString()
      .padStart(2, '0')}:${data.time.getMinutes().toString().padStart(2, '0')}`;
    formData.append('currency', data.currency);
    formData.append('date', data.date.toLocaleDateString('pt-BR') + ' ' + time);
    formData.append('price', data.price);
    formData.append('description', data.description);
    formData.append(
      'distance',
      formType === 'km-interno' ? data.distance * 1000 : data.distance
    );

    for (const [key, value] of Object.entries(formattedOrigin)) {
      formData.append(
        `Origin[${key.charAt(0).toUpperCase() + key.slice(1)}]`,
        value
      );
    }

    if (formType === 'km-manual') {
      for (const [key, value] of Object.entries(formattedDestination)) {
        formData.append(
          `Destination[${key.charAt(0).toUpperCase() + key.slice(1)}]`,
          value
        );
      }
    }

    if (expenseID) {
      dispatch(EditKmExpense(expenseID, formData));
    } else {
      dispatch(CreateKmExpense(formData));
    }

    setHasSubmitted(true);
  };
  useEffect(() => {
    setHasFetched(true);
    dispatch(GetFields());
    const userCompanyID = user.user.companyID;
    dispatch(GetExpenseTypes(userCompanyID));
    dispatch(GetReportsAvailable());
    dispatch(GetCostCenters(userCompanyID));
    dispatch(GetPaymentTypes(userCompanyID));
    dispatch(GetCurrency(userCompanyID));
    if (expenseID) {
      dispatch(GetEditExpense(expenseID));
    }
  }, [expenseID]);

  useEffect(() => {
    if (expenses.fields.length > 0 && hasFetched) {
      setHasFetched(false);
      expenses.fields.forEach((field) => {
        if (field.fieldID === 1) {
          dispatch(GetProjects(user.user.companyID));
          setFields((prev) => [...prev, field.fieldID]);
        }
        if (field.fieldID === 2) {
          dispatch(GetReasonsByCompanyId(user.user.companyID));
          setFields((prev) => [...prev, field.fieldID]);
        }
        if (field.fieldID === 3) {
          setFields((prev) => [...prev, field.fieldID]);
        }
      });
    }
  }, [expenses.fields]);

  useEffect(() => {
    if (expenseID && expenses.selectedExpense) {
      const selectedExpense = expenses.selectedExpense;

      const time = selectedExpense?.date?.substring(10, 16).split(':');
      const [day, month, year] = selectedExpense?.date
        ?.substring(0, 10)
        .split('/') || [null, null, null];
      const dateObject = new Date(year, month - 1, day);

      // setValue('expenseTypeID', selectedExpense.expenseTypeID);
      setValue('costCenterID', selectedExpense.costCenterID);
      setValue('paymentTypeID', selectedExpense.paymentTypeID);
      setValue('reasonID', selectedExpense.reason);
      setValue('justification', selectedExpense.justification);
      setValue('currency', selectedExpense.currency);
      setValue('date', dateObject);
      setValue('time', new Date(0, 0, 0, time[0], time[1]));
      setValue('projectID', selectedExpense.project);
      setValue('reportID', selectedExpense.reportID);
      setValue('price', selectedExpense.price);
      setValue('description', selectedExpense.description);
      setValue('distance', selectedExpense.distance);

      setValue(
        'origin',
        selectedExpense?.expenseAddressResults?.find(
          (item) => item.expenseAddressTypeID === 1
        ) || {}
      );

      setValue(
        'destination',
        selectedExpense?.expenseAddressResults?.find(
          (item) => item.expenseAddressTypeID === 2
        ) || {}
      );
    }
  }, [expenses.selectedExpense]);

  useEffect(() => {
    if (hasSubmitted && !expenses.loading && expenses.success) {
      setShowModal(true);
    }
  }, [expenses]);

  return (
    <>
      <ResponsiveAppBar />
      <Container sx={{ mt: 6 }}>
        {isLoading ? (
          <Box textAlign={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                sx={{
                  boxShadow: '0px 0px 4px 0px #00000040',
                  borderRadius: '10px',
                  padding: 2,
                }}
              >
                <Grid item xs={12} textAlign={'center'}>
                  <Typography variant="h5">
                    {formType === 'km-interno'
                      ? 'Cadastro de KM Interno'
                      : 'Cadastro de Despesa de KM'}
                  </Typography>
                </Grid>
                {formType === 'km-interno' && (
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent={'center'}
                      flexWrap={'wrap'}
                      gap={2}
                    >
                      {filesPreview.map((file, index) => (
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                          gap={1}
                          key={index}
                        >
                          <IconButton onClick={() => handleRemoveFile(index)}>
                            <HighlightOffIcon color="primary" />
                          </IconButton>
                          <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            flexDirection={'column'}
                            gap={1}
                          >
                            <Box>
                              {file.type.startsWith('image/') ? (
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={file.name}
                                  style={{
                                    width: 50,
                                    height: 50,
                                    objectFit: 'cover',
                                    borderRadius: 4,
                                  }}
                                />
                              ) : (
                                <InsertDriveFileIcon
                                  sx={{ fontSize: 50 }}
                                  color="primary"
                                />
                              )}
                            </Box>
                            <Tooltip title={file.name}>
                              <span>
                                {file.name.length > 10
                                  ? `${file.name.slice(0, 10)}...`
                                  : file.name}
                              </span>
                            </Tooltip>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      id="file-upload"
                      accept=".xls,.xlsx,application/pdf,image/png,image/jpeg,image/jpg"
                    />
                    <Grid item xs={12} alignItems={'center'}>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <Box>
                          <InputLabel>Recibo:</InputLabel>
                        </Box>
                        <Box
                          display={'flex'}
                          gap={2}
                          width={'100%'}
                          justifyContent={'space-between'}
                        >
                          <label htmlFor="file-upload">
                            <Button variant="contained" component="span">
                              Selecionar arquivos
                            </Button>
                          </label>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <HereMap
                    formType={formType}
                    errors={{
                      origin: errors.origin,
                      destination: errors.destination,
                    }}
                    editMode={expenseID ? true : false}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    name="reportID"
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        errors={errors.reportID}
                        label={'Relatório'}
                      >
                        {expensesReport.reports.map((report) => {
                          if (
                            report.reportStatusID === 1 ||
                            report.reportStatusID === 4 ||
                            report.reportStatusID === 8 ||
                            report.reportStatusID === 9
                          )
                            return (
                              <MenuItem
                                key={report.reportID}
                                value={report.reportID}
                              >
                                {report.description}
                              </MenuItem>
                            );
                        })}
                      </FormSelect>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    name="costCenterID"
                    control={control}
                    rules={{ required: 'Campo obrigatório' }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        errors={errors.costCenterID}
                        label={'Centro de Custo'}
                      >
                        {costCenter.costCenters.map((costCenter) => (
                          <MenuItem
                            key={costCenter.costCenterID}
                            value={costCenter.costCenterID}
                          >
                            {costCenter.number} - {costCenter.description}
                          </MenuItem>
                        ))}
                      </FormSelect>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="calculationType"
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        field={field}
                        errors={errors.calculationType}
                        label={'Método de Cálculo'}
                        disabled={true}
                        placeholder={
                          formType === 'km-interno'
                            ? 'Km com Deslocamento Interno'
                            : 'MaisRapido'
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: 'Campo obrigatório' }}
                    render={({ field }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ptBR}
                      >
                        <InputLabel>Data de Início</InputLabel>
                        <DatePicker
                          value={field.value || ''}
                          empty
                          disabled={
                            formType === 'km-manual' && Boolean(expenseID)
                          }
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              size="small"
                              fullWidth
                              error={Boolean(errors.date)}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '&.Mui-disabled': {
                                    backgroundColor: '#f0f0f0',
                                  },
                                },
                              }}
                            />
                          )}
                          onChange={(value) => field.onChange(value)}
                        />
                        {errors.date && (
                          <Typography variant="caption" color="error">
                            {errors.date.message}
                          </Typography>
                        )}
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="time"
                    control={control}
                    rules={{ required: 'Campo obrigatório' }}
                    render={({ field }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ptBR}
                      >
                        <InputLabel>Hora de Início</InputLabel>
                        <TimePicker
                          value={field.value || ''}
                          empty
                          disabled={
                            formType === 'km-manual' && Boolean(expenseID)
                          }
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              size="small"
                              fullWidth
                              error={Boolean(errors.date)}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '&.Mui-disabled': {
                                    backgroundColor: '#f0f0f0',
                                  },
                                },
                              }}
                            />
                          )}
                          onChange={(value) => field.onChange(value)}
                        />
                        {errors.date && (
                          <Typography variant="caption" color="error">
                            {errors.date.message}
                          </Typography>
                        )}
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="distance"
                    control={control}
                    rules={{ required: 'Campo obrigatório' }}
                    render={({ field }) => (
                      <>
                        <InputLabel htmlFor="distance">Distância</InputLabel>
                        <TextField
                          id="distance"
                          type="text"
                          variant="outlined"
                          placeholder={
                            formType === 'km-interno' ? 'Digite...' : ' '
                          }
                          disabled={formType === 'km-manual'}
                          fullWidth
                          error={Boolean(errors.distance)}
                          value={
                            formType === 'km-manual' && !expenseID
                              ? (field.value / 1000).toFixed(2) || ''
                              : field.value
                          }
                          onChange={field.onChange}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-disabled': {
                                backgroundColor: '#f0f0f0',
                              },
                            },
                          }}
                        />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Controller
                    name="price"
                    control={control}
                    // rules={{ required: 'Campo obrigatório' }}
                    render={({ field }) => {
                      const [inputValue, setInputValue] = useState(field.value);

                      useEffect(() => {
                        setInputValue(inputformatNumber(field.value));
                      }, [field.value]);

                      const handleChange = (e) => {
                        const formattedValue = formatNumber(e.target.value);
                        setInputValue(formattedValue);
                        field.onChange(formattedValue);
                      };
                      return (
                        <Box>
                          <InputLabel htmlFor="price">Valor</InputLabel>
                          <TextField
                            id="price"
                            type="text"
                            variant="outlined"
                            value={inputValue}
                            placeholder={''}
                            fullWidth
                            error={Boolean(errors.price)}
                            onChange={handleChange}
                            disabled={true}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-disabled': {
                                  backgroundColor: '#f0f0f0',
                                },
                              },
                            }}
                          />
                          {errors.price && (
                            <Typography variant="caption" color="error">
                              {errors.price.message}
                            </Typography>
                          )}
                        </Box>
                      );
                    }}
                  />
                </Grid>
                {fields.includes(1) && (
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="projectID"
                      control={control}
                      rules={{ required: 'Campo obrigatório' }}
                      render={({ field }) => (
                        <FormSelect
                          field={field}
                          errors={errors.projectID}
                          label={'Projeto'}
                        >
                          {project.projects.map((project) => (
                            <MenuItem
                              key={project.projectID}
                              value={project.projectID}
                            >
                              {project.number} - {project.description}
                            </MenuItem>
                          ))}
                        </FormSelect>
                      )}
                    />
                  </Grid>
                )}
                {fields.includes(2) && (
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="reasonID"
                      control={control}
                      rules={{ required: 'Campo obrigatório' }}
                      render={({ field }) => (
                        <FormSelect
                          field={field}
                          errors={errors.reasonID}
                          label={'Motivo'}
                        >
                          {reason.reasons.map((reason) => (
                            <MenuItem
                              key={reason.reasonID}
                              value={reason.reasonID}
                            >
                              {reason.description}
                            </MenuItem>
                          ))}
                        </FormSelect>
                      )}
                    />
                  </Grid>
                )}

                {fields.includes(3) && (
                  <Grid item xs={12}>
                    <Controller
                      name="justification"
                      control={control}
                      rules={{ required: 'Campo obrigatório' }}
                      render={({ field }) => (
                        <FormInput
                          field={field}
                          errors={errors.justification}
                          label={'Justificativa'}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Box display={'flex'} justifyContent={'end'} gap={2} mt={2}>
                <Button variant="contained" type="submit">
                  Salvar
                </Button>
                <Button variant="outlined" href="/expen/despesas">
                  Cancelar
                </Button>
              </Box>
            </form>
          </FormProvider>
        )}

        <Footer />
      </Container>
      <ModalErrorSuccess
        show={showModal}
        handleClose={() => navigate('/expen/despesas')}
        title={'Sucesso!'}
      >
        <Box display={'flex'} textAlign={'center'} flexDirection={'column'}>
          <Typography variant="body1">
            Despesa cadastrada com sucesso.
          </Typography>
        </Box>
      </ModalErrorSuccess>
    </>
  );
};

export default ExpensesKmForm;
