import { Box, Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerRange from '../../../../components/DatePickerRange';
import HeaderSearchSelectMultiple from '../../../../components/HeaderSearchSelectMultiple';
import HeaderSelect from '../../../../components/HeaderSelect';
import InfoCards from '../../../../components/InfoCards';
import ResponsiveAppBar from '../../../../components/NavBar';
import CustomTable from '../../../../components/Table/CustomTable';

import { GetRideFeeEconomyReport } from '../../../../store/_Entities/RideFeeEconomyReport';
import validateDate from '../../../../utils/validateDate';

const SavingsCommissions = () => {
  const { user, company, costCenter, rideFeeEconomyReport } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();

  const userID = user.authData.sub;

  const [companiesInputValue, setCompaniesInputValue] = useState([]);

  const [selectedCostCenter, setSelectedCostCenter] = useState('');
  const [costCenters, setCostCenters] = useState([]);

  const [data, setData] = useState([]);
  const [reports, setReports] = useState([]);

  const [feeDiagnosticTotal, setFeeDiagnosticTotal] = useState(0.0);
  const [feeTotal, setFeeTotal] = useState(0.0);
  const [feeSavingTotal, setFeeSavingTotal] = useState(0.0);

  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  const fetchReports = () => {
    const startDateFormatted = startDate.toLocaleDateString('pt-BR');
    const endDateFormatted = endDate.toLocaleDateString('pt-BR');

    const request = {
      userID,
      companyID: 0,
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      costCenterID: selectedCostCenter || '0',
      companies: companiesInputValue.length
        ? companiesInputValue.map((u) => u.companyID)
        : [0],
    };

    dispatch(GetRideFeeEconomyReport(request));
  };

  useEffect(() => {
    if (
      companiesInputValue.length > 0 &&
      costCenter.costCenters &&
      costCenter.success
    ) {
      setCostCenters(costCenter.costCenters);
    }
  }, [companiesInputValue]);

  useEffect(() => {
    if (
      companiesInputValue.length > 0 &&
      validateDate(startDate) &&
      validateDate(endDate)
    ) {
      fetchReports();
    }
  }, [companiesInputValue, selectedCostCenter, startDate, endDate]);

  useEffect(() => {
    if (
      rideFeeEconomyReport?.rideFeeEconomyReports &&
      companiesInputValue.length > 0
    ) {
      setData(rideFeeEconomyReport.rideFeeEconomyReports);
      setReports(
        rideFeeEconomyReport.rideFeeEconomyReports.rideFeeEconomyReportResult
      );
    }
  }, [companiesInputValue, rideFeeEconomyReport.rideFeeEconomyReports]);

  useEffect(() => {
    if (rideFeeEconomyReport?.rideFeeEconomyReports) {
      setData(rideFeeEconomyReport.rideFeeEconomyReports);
      setFeeTotal(rideFeeEconomyReport.rideFeeEconomyReports.feeTotal);
      setFeeDiagnosticTotal(
        rideFeeEconomyReport.rideFeeEconomyReports.feeDiagnosticTotal
      );
      setFeeSavingTotal(
        rideFeeEconomyReport.rideFeeEconomyReports.feeSavingTotal
      );
    }
  }, [rideFeeEconomyReport.rideFeeEconomyReports]);

  const columns = [
    {
      field: 'rideID',
      headerName: 'ID da Corrida',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'company',
      headerName: 'Empresa',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'userID',
      headerName: 'ID do Usuário',
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome do Usuário',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'registration',
      headerName: 'Matrícula',
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: 'Data de Início',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'endDate',
      headerName: 'Data de Fim',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'provider',
      headerName: 'Fornecedor',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'product',
      headerName: 'Produto',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'priceBaseDiagnostic',
      headerName: 'Valor de Base do Diagnóstico',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Valor Pago',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'saving',
      headerName: 'Valor de Economia',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'savingPercent',
      headerName: 'Porcentagem de Economia',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'feePriceDiagnostic',
      headerName: 'Valor da Comissão do Diagnóstico',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'feePrice',
      headerName: 'Valor da Comissão',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'feeSaving',
      headerName: 'Valor de Economia da Comissão',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
  ];

  const exportHeaders = {
    rideID: 'ID da Corrida',
    company: 'Empresa',
    userID: 'ID do Usuário',
    name: 'Nome do Usuário',
    registration: 'Matrícula',
    startDate: 'Data de Início',
    endDate: 'Data de Fim',
    costCenter: 'Centro de Custo',
    provider: 'Fornecedor',
    product: 'Produto',
    priceBaseDiagnostic: 'Valor de Base do Diagnóstico',
    price: 'Valor Pago',
    saving: 'Valor de Economia',
    savingPercent: 'Porcentagem de Economia',
    feePriceDiagnostic: 'Valor da Comissão do Diagnóstico',
    feePrice: 'Valor da Comissão',
    feeSaving: 'Valor de Economia da Comissão',
  };

  const cardsData = [
    {
      title: 'Total Comissão Diagnóstico',
      value: `R$${feeDiagnosticTotal || 0}`,
    },
    { title: 'Total Comissão Cobrada', value: `R$${feeTotal || 0}` },
    {
      title: 'Total de Economia de Comissão',
      value: `R$${feeSavingTotal || 0}`,
    },
  ];

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Economias com Comissionamentos (EC)</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <Box sx={{ display: 'flex', gap: 5 }}>
                <HeaderSearchSelectMultiple
                  label="Empresas"
                  onChange={(e) => {
                    setCompaniesInputValue(e.target.value);
                  }}
                  inputValue={companiesInputValue}
                  menuItems={company?.companies
                    ?.filter((company) => company.active == 1)
                    .sort((companyA, companyB) =>
                      companyA.giveName.localeCompare(companyB.giveName)
                    )
                    .map((company) => {
                      return { label: company.giveName, value: company };
                    })}
                  menuItemsSelectedChips={companiesInputValue?.map(
                    (company) => {
                      return { label: company.giveName, value: company };
                    }
                  )}
                />
                <DatePickerRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={{ startDate: setStartDate, endDate: setEndDate }}
                />
                <HeaderSelect
                  label="Centro de Custo"
                  onChange={(event) => {
                    setSelectedCostCenter(event.target.value);
                  }}
                  menuItems={[
                    { label: 'TODOS', value: '0' },
                    ...(costCenters.map((costCenter) => ({
                      label: costCenter.description,
                      value: costCenter.costCenterID,
                    })) || []),
                  ]}
                  defaultValue={undefined}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <InfoCards cardsData={cardsData} />
        <CustomTable
          columns={columns}
          data={reports}
          getRowId={(row) => row.rideID}
          exportHeaders={exportHeaders}
        />
      </Container>
    </>
  );
};

export default SavingsCommissions;
