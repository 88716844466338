import { useState, useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import HeaderSelect from '../../../components/HeaderSelect';
import ResponsiveAppBar from '../../../components/NavBar';
import CustomTable from '../../../components/Table/CustomTable';
import { useSelector, useDispatch } from 'react-redux';
import HeaderSearchSelect from '../../../components/HeaderSearchSelect';
import DatePickerRange from '../../../components/DatePickerRange';
import validateDate from '../../../utils/validateDate';
import { GetUserExpens } from '../../../store/_Entities/SourceActiveUserLog';
import HeaderSearchSelectCompany from '../../../components/HeaderSearchSelectCompany';
import { setLastCompanyFiltered } from '../../../store/_Entities/Company';

const ActiveUsersKm = () => {
  const { company } = useSelector((state) => state.entities);
  const { sourceActiveUserLog } = useSelector(
    (state) => state.entities
  );
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(() => {
    return company.lastCompanyFiltered || '';
  });
  const [selectedVertical, setSelectedVertical] = useState('');
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ); //Data 7 dias atrás
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (
      selectedCompany &&
      selectedVertical &&
      validateDate(startDate) &&
      validateDate(endDate)
    ) {
      const startDateFormatted = startDate.toLocaleDateString('pt-BR');
      const endDateFormatted = endDate.toLocaleDateString('pt-BR');
      dispatch(setLastCompanyFiltered(selectedCompany));
      dispatch(
        GetUserExpens(
          +selectedCompany,
          startDateFormatted,
          endDateFormatted,
          +selectedVertical
        )
      );
    }
  }, [selectedCompany, selectedVertical, startDate, endDate]);

  useEffect(() => {
    console.log(selectedVertical);
  }, [selectedVertical]);

  useEffect(() => {
    if (selectedCompany) {
      setData(sourceActiveUserLog.activeUserLog);
    }
  }, [sourceActiveUserLog.activeUserLog]);

  const columns = [
    {
      field: 'userID',
      headerName: 'ID do Usuário',
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      flex: 1,
    },
    {
      field: 'user',
      headerName: 'Nome',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'costCenter',
      headerName: 'Centro de Custo',
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Data de Ativação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        return new Date(params.value).toLocaleString('pt-BR');
      },
    },
    {
      field: 'removed',
      headerName: 'Data de Desativação',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        validateDate(params.value)
          ? new Date(params.value).toLocaleString('pt-BR')
          : null;
      },
    },
  ];

  const exportHeaders = {
    userID: 'ID do Usuário',
    user: 'Nome',
    costCenter: 'Centro de Custo',
    created: 'Data de Ativação',
    removed: 'Data de Desativação',
  };

  return (
    <>
      <ResponsiveAppBar onlyExit={undefined} />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            py={1}
            sx={{
              marginBottom: '10px',
              borderBottom: '1px solid black',
            }}
          >
            <h2>Usuários Ativos</h2>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <Box sx={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                <HeaderSearchSelectCompany
                  label="Empresas"
                  onChange={setSelectedCompany}
                  defaultValue={selectedCompany}
                />
                <HeaderSelect
                  label="Vertical"
                  onChange={(event) => {
                    setSelectedVertical(event.target.value);
                  }}
                  menuItems={[
                    { label: 'Expen', value: 2 },
                    { label: 'KM', value: 3 },
                  ]}
                  defaultValue={undefined}
                />
                <DatePickerRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={{ startDate: setStartDate, endDate: setEndDate }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomTable
          columns={columns}
          data={data}
          getRowId={(row) => row.sourceActiveUserLogID}
          exportHeaders={exportHeaders}
          loading={sourceActiveUserLog.loading}
        />
      </Container>
    </>
  );
};

export default ActiveUsersKm;
