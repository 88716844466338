import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/CompanyConfiguration';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  companyConfigurations: [],
};

export const slice = createSlice({
  name: 'companyConfiguration',
  initialState,
  reducers: {
    COMPANY_CONFIGURATION_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    COMPANY_CONFIGURATION_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    COMPANY_CONFIGURATION_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.companyConfigurations = action.payload.result;
    },
  },
});

export const {
  COMPANY_CONFIGURATION_REQUESTED,
  COMPANY_CONFIGURATION_FAILED,
  COMPANY_CONFIGURATION_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetCompanyConfiguration = (CompanyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.CompanyConfiguration.Configuration(CompanyID),
    },
    onAction: {
      onStart: COMPANY_CONFIGURATION_REQUESTED.type,
      onError: COMPANY_CONFIGURATION_FAILED.type,
      onSuccess: COMPANY_CONFIGURATION_SUCCESS.type,
    },
  });
};
