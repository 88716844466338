import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/FinancialAdm';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  reports: [],
  approvalFlow: [],
  expenses: {
    Report: '',
    ReportID: 0,
    Expenses: [],
    Currency: [],
    Balance: '',
    ReportStatus: 0,
    ReportValue: '',
    ReportTotalValue: '',
    AdvancedTotalValue: '',
    ReleaseType: '',
    AdvancedValue: '',
    AdvancedCurrency: '',
  },
};

export const slice = createSlice({
  name: 'financialAdm',
  initialState,
  reducers: {
    FINANCIAL_ADM_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    FINANCIAL_ADM_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    FINANCIAL_ADM_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.reports = action.payload.result;
    },
    FINANCIAL_ADM_APPROVAL_FLOW_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.approvalFlow = action.payload.result;
    },
    FINANCIAL_ADM_EXPENSES_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.expenses = action.payload.result;
    },
    FINANCIAL_ADM_PAYMENT_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      const { reportID, advancedID } = action.payload.result;

      state.reports = state.reports.filter((report) => {
        if (reportID) {
          return report.reportID !== reportID;
        }
        if (advancedID) {
          return report.advancedID !== advancedID;
        }
      });
    },
  },
});

export const {
  FINANCIAL_ADM_REQUESTED,
  FINANCIAL_ADM_FAILED,
  FINANCIAL_ADM_SUCCESS,
  FINANCIAL_ADM_APPROVAL_FLOW_SUCCESS,
  FINANCIAL_ADM_EXPENSES_SUCCESS,
  FINANCIAL_ADM_PAYMENT_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetFinancialAdmReports = (
  companyID: number,
  startDate: string,
  endDate: string,
  statusReport: number,
  ReleaseType: number
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.FinancialAdm.GetFilter(
        companyID,
        startDate,
        endDate,
        statusReport,
        ReleaseType
      ),
    },
    onAction: {
      onStart: FINANCIAL_ADM_REQUESTED.type,
      onError: FINANCIAL_ADM_FAILED.type,
      onSuccess: FINANCIAL_ADM_SUCCESS.type,
    },
  });
};

export const GetFinancialAdmApprovalFlow = (reportID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.FinancialAdm.GetApprovalStatus(reportID),
    },
    onAction: {
      onStart: FINANCIAL_ADM_REQUESTED.type,
      onError: FINANCIAL_ADM_FAILED.type,
      onSuccess: FINANCIAL_ADM_APPROVAL_FLOW_SUCCESS.type,
    },
  });
};

export const GetFinancialAdmExpenses = (
  reportID: number,
  userID: string,
  releaseType: string
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.FinancialAdm.GetEvaluate(reportID, userID, releaseType),
    },
    onAction: {
      onStart: FINANCIAL_ADM_REQUESTED.type,
      onError: FINANCIAL_ADM_FAILED.type,
      onSuccess: FINANCIAL_ADM_EXPENSES_SUCCESS.type,
    },
  });
};

export const ConfirmPayment = (reportID: number, releaseType: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.FinancialAdm.ConfirmPayment(reportID, releaseType),
    },
    onAction: {
      onStart: FINANCIAL_ADM_REQUESTED.type,
      onError: FINANCIAL_ADM_FAILED.type,
      onSuccess: FINANCIAL_ADM_PAYMENT_SUCCESS.type,
      showToast: true,
    },
  });
};

export const RefusePayment = (
  reportID: number,
  releaseType: number,
  justification: string
) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.FinancialAdm.RefusePayment(reportID, releaseType),
      data: { Justification: justification },
    },
    onAction: {
      onStart: FINANCIAL_ADM_REQUESTED.type,
      onError: FINANCIAL_ADM_FAILED.type,
      onSuccess: FINANCIAL_ADM_PAYMENT_SUCCESS.type,
      showToast: true,
    },
  });
};
